import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import Button from '@material-ui/core/Button';
import history from '../../config/history';
import configAxios from '../../config/axios';
import styles from './CheckRestorePass.css';
import { setPageLoadingStatus } from '../../store/actions/actions';
import Preload from '../../constructors/Preload';

class Auth extends Component {
  /**
   *
   * @param props
   */
  constructor(props) {
    super(props);

    this.state = {
      showErrorDialog: false,
      errorResponseText: '',
      successResponseText: '',
      password: '',
      confirmPassword: '',
      passwordValid: true,
      formValid: true,
      formChanged: false,
      tokenIsNotCorrect: false,
      disabledSubmit: true
    };

    this.preload = new Preload();
    this.preload.subscribe(promises => {
      this.props.dispatch(setPageLoadingStatus(promises.length > 0));
    });

    this.checkRestorePassKey();
  }

  /**
   *
   * @param event
   * @param value
   */
  handleChangeLogin = (event, value) => {
    this.setState(
      {
        login: value
      },
      () => {
        return this.validateField('login', value);
      }
    );
  };

  /**
   *
   * @param event
   * @param value
   */
  handleChangePassword = (event, value) => {
    this.setState(
      {
        password: value
      },
      () => {
        return this.validateField();
      }
    );
  };

  /**
   *
   * @param event
   * @param value
   */
  handleChangeConfirmPassword = (event, value) => {
    this.setState(
      {
        confirmPassword: value
      },
      () => {
        return this.validateField();
      }
    );
  };

  /**
   *
   */
  checkRestorePassKey() {
    const parsedSearch = queryString.parse(history.location.search);
    const promise = configAxios({
      method: 'POST',
      url: '/user/check-restore-pass-key',
      data: parsedSearch
    });

    promise.catch(error => {
      if (error.response.status === 422) {
        this.setState({
          errorResponseText: error.response.data[0].message,
          tokenIsNotCorrect: true
        });
      }
    });

    this.preload.add(promise);
  }

  /**
   *
   */
  validateField = () => {
    this.setState({
      errorResponseText: '',
      successResponseText: '',
      disabledSubmit: false
    });

    const { password, confirmPassword } = this.state;
    const { translation } = this.props.language;

    const short = new RegExp('^(?=.{12,})').test(password);
    const noLowercase = new RegExp('^(?=.*[a-z])').test(password);
    const noUppercase = new RegExp('^(?=.*[A-Z])').test(password);
    const noNumber = new RegExp('^(?=.*[0-9])').test(password);

    if (password) {
      if (!short) {
        this.setState({
          errorResponseText: translation.restorePass.noLength,
          disabledSubmit: true
        });
      } else if (!noLowercase) {
        this.setState({
          errorResponseText: translation.restorePass.noLowercase,
          disabledSubmit: true
        });
      } else if (!noUppercase) {
        this.setState({
          errorResponseText: translation.restorePass.noUppercase,
          disabledSubmit: true
        });
      } else if (!noNumber) {
        this.setState({
          errorResponseText: translation.restorePass.noNumber,
          disabledSubmit: true
        });
      } else if (password !== confirmPassword) {
        this.setState({
          errorResponseText: translation.restorePass.noMatch,
          disabledSubmit: true
        });
      }
    }
  };

  /**
   *
   * @param e
   */
  handleConfirmPassSubmit = e => {
    e.preventDefault();

    const parsedSearch = queryString.parse(history.location.search);
    const promise = configAxios({
      method: 'POST',
      url: '/user/reset-pass',
      data: {
        password: this.state.password,
        token: parsedSearch.token
      }
    });

    promise
      .then(response => {
        localStorage.successReset = JSON.stringify(response.data.message);
      })
      .then(() => {
        history.push('/');
      });

    promise.catch(error => {
      if (error.response.status === 422) {
        this.setState({
          errorResponseText: error.response.data[0].message,
          successResponseText: ''
        });
      }
    });

    this.preload.add(promise);
  };

  render() {
    const { translation } = this.props.language;
    const submitButton = (
      <button type="submit" className={styles.btn}>
        {translation.restorePass.ok}
      </button>
    );
    const submitButtonDisabled = (
      <button
        type="submit"
        className={[styles.btn, styles.btnDisabled].join(' ')}
        disabled="disabled"
      >
        {translation.restorePass.ok}
      </button>
    );
    const renderForm = (
      <div className={styles.inputContainer}>
        <label htmlFor="login" className={styles.inputWrap}>
          <Field
            onChange={this.handleChangePassword}
            name="password"
            id="password"
            component="input"
            type="password"
            placeholder={translation.restorePass.password}
            className={styles.input}
          />
        </label>
        <label htmlFor="login" className={styles.inputWrap}>
          <Field
            onChange={this.handleChangeConfirmPassword}
            name="confirmPassword"
            id="confirmPassword"
            component="input"
            type="password"
            placeholder={translation.restorePass.repeatPassword}
            className={styles.input}
          />
        </label>

        <div className={styles.containerButton}>
          {this.state.disabledSubmit ? submitButtonDisabled : submitButton}
          <Button className={styles.restorePass} href="/">
            {translation.restorePass.cancel}
          </Button>
        </div>
      </div>
    );

    return (
      <div>
        <form
          onSubmit={this.handleConfirmPassSubmit}
          className={styles.authBlock}
        >
          <a href="/">
            <div className={styles.logo} />
          </a>
          <div
            className={
              styles.formError +
              ' ' +
              (this.state.errorResponseText !== ''
                ? styles.formErrorVisible
                : '')
            }
          >
            {this.state.errorResponseText}
          </div>
          <div
            className={
              styles.formSuccess +
              ' ' +
              (this.state.successResponseText !== ''
                ? styles.formSuccessVisible
                : '')
            }
          >
            {this.state.successResponseText}
          </div>
          {this.state.tokenIsNotCorrect ? '' : renderForm}
        </form>
      </div>
    );
  }
}

Auth.propTypes = {
  dispatch: PropTypes.func.isRequired,
  language: PropTypes.object.isRequired //eslint-disable-line
};

const AuthExport = connect(state => ({
  language: state.language
}))(Auth);

export default reduxForm({
  form: 'Auth' // a unique identifier for this form
})(AuthExport);
