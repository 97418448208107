import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { changePage } from '../../store/actions/actions';

import styles from './Paginate.css';

class Paginate extends Component {
  state = {
    customPage: '',
    windowWidth: window.innerWidth
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.changePage === 1) {
      this.setState({
        customPage: ''
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({
      windowWidth: window.innerWidth
    });
  };

  handlePageClick = data => {
    const arrowArray = document.querySelectorAll('.reactPaginateArrow');
    const { dispatch } = this.props;
    let selected = data.selected + 1;
    dispatch(changePage(selected));
    if (arrowArray.length > 0) {
      arrowArray.forEach(elem => elem.blur());
    }
    window.scrollTo(0, 0);
  };

  customPageSelect = e => {
    e.preventDefault();
    const { customPage } = this.state;
    const { dispatch } = this.props;
    const { total_pages } = this.props.data;
    if (customPage !== undefined && customPage <= total_pages && customPage > 0) {
      dispatch(changePage(customPage));
      window.scrollTo(0, 0);
    }
  };

  validate = e => {
    const re = /^[0-9\b]+$/;
    if (e.target.value == '' || re.test(e.target.value)) {
      this.setState({ customPage: e.target.value });
    }
  };

  render() {
    const { current, total_pages } = this.props.data;
    const { customPage, windowWidth } = this.state;
    const { translation } = this.props.language;
    const currentPage = current > 0 ? current - 1 : 0;
    const customPageCondition = customPage > 0 && customPage <= total_pages && customPage != current;
    return (
      <div className={`${styles.flex} ${styles.paginationBlock}`}>
        <ReactPaginate
          previousLabel={'<'}
          nextLabel={'>'}
          breakLabel={<span className={[styles.pageLink, styles.breakLabel].join(' ')}>...</span>}
          breakClassName={styles.pageItem}
          pageCount={this.props.data.total_pages}
          marginPagesDisplayed={1}
          pageRangeDisplayed={windowWidth > 480 ? 9 : 4}
          disableInitialCallback
          onPageChange={this.handlePageClick}
          containerClassName={styles.pagination}
          pageClassName={styles.pageItem}
          pageLinkClassName={styles.pageLink}
          previousClassName={styles.pageItem}
          previousLinkClassName={`${styles.pageLink} reactPaginateArrow`}
          initialPage={currentPage}
          forcePage={currentPage}
          nextClassName={styles.pageItem}
          nextLinkClassName={`${styles.pageLink} reactPaginateArrow`}
          disabledClassName={styles.disabled}
          activeClassName={styles.active}
          subContainerClassName={'pages pagination'}
        />
        <form onSubmit={this.customPageSelect} className={styles.customPagination}>
          <input
            type="text"
            value={customPage}
            placeholder={translation.pagination.placeholder}
            onChange={this.validate}
            className={`${styles.pageLink} ${styles.numberInput}`}
          />
          <button
            type="submit"
            // onClick={}
            className={customPageCondition ? styles.pageLink : `${styles.pageLink} ${styles.disabled}`}
          >
            {translation.pagination.apply}
          </button>
        </form>
      </div>
    );
  }
}

Paginate.defaultProps = {
  data: {},
  changePage: 1,
  language: {}
};

Paginate.propTypes = {
  dispatch: PropTypes.func.isRequired,
  changePage: PropTypes.number,
  language: PropTypes.object, //eslint-disable-line react/forbid-prop-types
  data: PropTypes.object // eslint-disable-line react/forbid-prop-types
};

// export default Paginate;
export default connect(store => {
  return store;
})(Paginate);
