export default {
  mobileFirefoxAlert: 'În acest browser, procesarea task-urilor nu este disponibilă.',
  datePicker: {
    from: 'De la',
    to: 'Până la',
    cancel: 'Anulează',
    apply: 'Aplică',
    month: [
      'Ianuarie',
      'Februarie',
      'Martie',
      'Aprilie',
      'Mai',
      'Iunie',
      'Iulie',
      'August',
      'Septembrie',
      'Octombrie',
      'Noiembrie',
      'Decembrie'
    ],
    week: 'săptămână',
    weekShot: 's',
    buttonName: {
      date: 'Dată',
      week: 'Săptămână',
      month: 'Lună'
    },
    modal: {
      header: 'Avertisment',
      text: 'Intervalul de date selectat nu este valid',
      action: 'Acceptă'
    }
  },
  filters: {
    name: 'Nume'
  },
  scenario: {
    noDataReceived: 'Nu s-au primit date',
    filterPart: {
      name: 'Nume',
      clear: 'Șterge',
      notFound: 'Nu a fost găsit',
      search: 'Caută...'
    }
  },
  header: {
    logout: 'Deconectare',
    changePassword: 'Schimbare parolă',
    settings: 'Setări',
    title: 'Interfață vizualizare',
    searchTitle: 'Caută utilizator',
    input: 'Începeți să tastați numele sau numele de utilizator...',
    cancel: 'Anulează',
    view: 'Vizualizare',
    id: 'ID',
    login: 'Autentificare',
    name: 'Nume',
    phone: 'Telefon',
    email: 'E-mail',
    stopSettings: 'Oprește vizualizarea'
  },
  directTask: {
    addCase: 'Adaugă caz',
    commentCase: 'Comentariu caz',
    actions: 'Acțiuni',
    searchProduct: 'PLU/Cod de bare',
    searchProductShort: 'PLU/Cod de bare',
    photo: 'Fotografie',
    back: 'Înapoi',
    treeEmpty: 'ACȚIUNILE NU SUNT DISPONIBILE',
    step: 'Pas',
    sortingButton: 'Sortare',
    sortButton: 'Sortare',
    add: 'Adaugă',
    delete: 'Șterge',
    deleteManualCases: 'Șterge cazurile manuale',
    show: 'Arată',
    hide: 'Ascunde',
    comment: 'Comentariu',
    showDoneCases: 'Afișați sarcinile finalizate',
    hideDoneCases: 'Ascunde sarcinile finalizate'
  },
  gridTable: {
    pluList: 'Listă PLU'
  },
  casesForm: {
    product: 'Produs',
    loadFoto: 'Încarcă Fotografie',
    plu: 'Nume/PLU',
    success: 'Cazul a fost creat',
    delete: 'Șterge',
    add: 'Crea',
    new: 'Nou',
    cancel: 'Anulare',
    comment: 'Comentariu'
  },
  pagination: {
    apply: 'Mergi',
    placeholder: 'Pagina'
  },
  restorePass: {
    noLength: 'Parola trebuie să aibă cel puțin 12 caractere',
    noLowercase: 'Parola trebuie să aibă cel puțin o literă mică',
    noUppercase: 'Parola trebuie să aibă cel puțin o literă mare',
    noNumber: 'Parola trebuie să aibă cel puțin un simbol numeric',
    noMatch: 'Parolele nu se potrivesc',
    password: 'Parolă',
    repeatPassword: 'Repetă parola',
    ok: 'Acceptă',
    cancel: 'Anulează'
  },
  navigation: {
    missions: 'Misiuni',
    scenarios: 'Scenarii'
  },
  changePassword: {
    previousPassword: 'Parola curentă',
    newPassword: 'Parola nouă',
    confirmPassword: 'Repetă parola nouă',
    apply: 'Aplică',
    title: 'Schimbare parolă',
    success: 'Parola a fost schimbată cu succes',
    wrongPassword: 'Parolă greșită',
    weakPassword: 'Parola nu îndeplinește cerințele',
    passwordsNotMatch: 'Parolele nu se potrivesc',
    requirements: 'Parola nouă trebuie să aibă cel puțin 12 caractere, 1 literă majusculă și una minusculă și 1 număr'
  },
  auth: {
    login: 'Autentificare',
    password: 'Parolă',
    restorePass: 'Restaurează parola',
    submit: 'Trimite',
    loginError: 'Numele de utilizator și/sau parola sunt invalide'
  },
  restorePassForm: {
    restore: 'Restaurează',
    cancel: 'Anulează',
    info: 'Vă rugăm să introduceți adresa de e-mail înregistrată în Platforma Hibridă OSA',
    emailInvalid: 'Adresa de e-mail introdusă nu a fost găsită',
    email: 'E-mail',
    success: 'Link-ul de restabilire a fost trimis pe adresa dvs. de e-mail'
  },
  search: {
    notFound: 'Nu a fost găsit'
  },
  noMatchPage: {
    title: 'Pagina solicitată nu a fost găsită',
    body: 'Înapoi la pagina principală'
  },
  warnings: {
    title: 'Avertisment',
    isDesktop: 'Încărcarea fotografiilor este disponibilă doar în versiunea mobilă a sistemului. Vă rugăm să utilizați camera de pe telefonul sau tableta dvs.'
  },
  errors: {
    netErrorText: 'Eroare de rețea. Vă rugăm să reîncărcați pagina',
    netErrorReload: 'Reîncarcă',
    netErrorTitle: 'Eroare',
    netErrorClose: 'Închide',
    noShops: 'Hopa! Se pare că nu ți-au fost atribuite încă magazine. Spune-i managerului tău despre asta.',
    noCases: 'Se pare că serviciul nostru nu a putut găsi un singur caz în acest magazin. Începeți să le creați singur',
    noAutoOrder: 'Nu există sarcini pentru a ajusta ordinea automată',
    causeError: 'Eroare',
    scenariosEmpty: 'Tabloul scenariilor este gol'
  },
  officeUI: {
    resetZoom: 'Resetează zoom-ul'
  },
  gridAutocomplete: {
    placeholder: 'Începeți să tastați numele magazinului',
    notFound: 'Magazinul nu a fost găsit'
  },
  targets: {
    nothing: '',
    shop: 'Magazin',
    category: 'Categorie',
    supplier: 'Furnizor',
    vendor: 'Vânzător',
    brand: 'Brand',
    plu: 'PLU',
    day: 'Zi',
    week: 'Săptămână',
    month: 'Lună',
    quarter: 'Trimestru',
    year: 'An'
  },
  bubbleChart: {
    qty: 'cant',
    currency: 'RON'
  },
  chart: {
    quarter: 'trimestru',
    week: 'săptămână'
  },
  download: {
    CSV: 'Descărcați CSV',
    XLS: 'Descărcați XLS'
  },
  counterTask: {
    task: 'Sarcini în așteptare',
    noTask: 'Nicio sarcină'
  },
  filtersShop: {
    shopName: 'Adresa magazinului',
    shopId: 'Numele magazinului'
  },
  chartCaption: {
    thousand: 'mii',
    million: 'M',
    billion: 'B'
  },
  common: {
    close: 'Închide',
    userNotFound: 'Utilizatorul nu a fost găsit',
    yes: 'Da',
    no: 'Nu'
  },
  virtualStock: {
    headers: {
      case_id: 'ID',
      plu_original: 'PLU',
      plu: 'PLU intern',
      shop_id: 'ID magazin',
      ass_type: 'Tip sortiment',
      category_id3: 'ID secțiune',
      category_id4: 'ID categorie',
      stock: 'Volumul de resturi',
      stock_sum: 'Cantitatea totală a resturilor',
      product_turnover: 'Rotație',
      days_without_sales: 'Zile fără vânzări',
      last_sales_date: 'Data ultimei vânzări',
      last_sales_volume: 'Ultimul volum de vânzare',
      last_shipment_date: 'Ultima data de livrare',
      last_shipment_volume: 'Ultimul volum de livrare',
      promo_start: 'Început promoție',
      promo_end: 'Sfârșit promoție',
      promo_period: 'Perioada promoției',
      is_promo: 'Promoție',
      title: 'Nume',
      category_name3: 'Secțiune',
      category_name4: 'Categorie'
    },
    hideReadyTasks: 'Ascundeți sarcinile gata',
    nothingFound: 'Nimic găsit',
    shopsNotFound: 'Se pare că nu există magazine atribuite. Spuneți supraveghetorului despre aceasta.',
    chooseShop: 'Alegeți un magazin',
    createTasks: 'Creați sarcini',
    taskCreating: 'Se creează sarcini',
    next: 'Continuare',
    columns: 'Coloane',
    from: 'De la',
    ok: 'OK',
    cancel: 'Anulează',
    filter: 'Filtru',
    chooseValue: 'Alegeți o valoare',
    enterValue: 'Introduceți valoarea',
    rangeFrom: 'De la',
    rangeTo: 'Până la',
    selected: 'Selectat',
    foundRows: 'Rânduri găsite',
    createdTasksTotal: 'Total sarcini create'
  }
};
