import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cls from 'classnames';
import Autocomplete from '../../components/GridAutocomplete';
import DatePickerComponent from '../../components/DatePickerGridComponent/DatePickerGridComponent';
import MissionsNavigation from '../../components/MissionsNavigation';
import configAxios from '../../config/axios';
import TableAudit from '../../components/TableAudit/TableAudit';
import Paginate from '../../components/Paginate';
import Preload from '../../constructors/Preload';
import { loadTask, setPageLoadingStatus, changePage, searchProduct } from '../../store/actions/actions';
import ButtonField from '../../components/Buttons/ButtonField';

import styles from './PromoAudit.css';

class Task extends Component {
  /**
   *
   * @param props
   */
  constructor(props) {
    super(props);

    this.urlApi = 'promo';
    this.state = {
      items: [],
      headers: {},
      pagination: {},
      shopItems: [],
      selectedCategories: [],
      selectShop: 0,
      suggest: '',
      emptyPage: true,
      emptyShop: false,
      showCategorySort: false,
      blocking: '',
      removeManualCases: false,
      startDate: this.formatDateToReport(new Date()),
      restrictDateStart: '',
      endDate: this.formatDateToReport(new Date()),
      auditStatus: 0
    };

    this.preload = new Preload();
    this.preload.subscribe(promises => {
      this.props.dispatch(setPageLoadingStatus(promises.length > 0));
    });
  }

  componentDidMount() {
    const _this = this;
    const promise = _this.loadShopsFromServer();

    promise.then(
      () => {
        _this.setState({
          emptyPage: false
        });
      },
      () => {
        _this.setState({
          emptyPage: true
        });
      }
    );

    _this.preload.add(promise);

    const { dispatch } = this.props;
    dispatch(changePage(1));
  }

  /**
   *
   * @param nextProps
   */
  componentWillReceiveProps(nextProps) {
    if (nextProps.loadTask.initPage) {
      const { dispatch } = this.props;
      dispatch(loadTask(false));
      this.loadTaskFromServer(nextProps.changePage, nextProps.searchProduct);
    }

    if (nextProps.changePage !== this.props.changePage && !nextProps.searchProduct) {
      this.loadTaskFromServer(nextProps.changePage);
    }

    if (nextProps.searchProduct !== this.props.searchProduct) {
      this.loadTaskFromServer(1, nextProps.searchProduct);
    }
  }

  componentDidUpdate(prevProps) {
    const { mission } = prevProps;
    if (mission.id !== this.props.mission.id) {
      const _this = this;
      _this.setState({
        removeManualCases: false,
        showCategorySort: false,
        selectedCategories: [],
        startDate: this.formatDateToReport(new Date()),
        endDate: this.formatDateToReport(new Date())
      });
      const promise = _this.loadShopsFromServer();
      promise.then(
        () => {
          _this.setState({
            emptyPage: false
          });
        },
        () => {
          _this.setState({
            emptyPage: true
          });
        }
      );
      _this.preload.add(promise);
    }
  }

  formatDateToReport = date => {
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
    return date.getFullYear() + '-' + month + '-' + day;
  };

  /**
   *
   * @returns {*}
   */
  loadShopsFromServer() {
    const query = new URLSearchParams(location.search);
    const mid = query.has('mid') ? Number(query.get('mid')) : JSON.parse(localStorage.mission).id;

    const promise = configAxios({
      method: 'GET',
      url: `/user/get-shops`,
      params: {
        missionId: mid
      }
    });

    promise.then(response => {
      const shopItem = response.data.all;
      if (!shopItem.length) {
        this.setState({
          emptyShop: true
        });
        return;
      }
      let pushShopID = +response.data.all[0].id;

      if (localStorage.shopID) {
        for (let i = 0, len = shopItem.length; i < len; i++) {
          if (shopItem[i].id === localStorage.shopID) {
            pushShopID = shopItem[i].id;
          }
        }
      }

      this.setState(
        {
          shopItems: shopItem,
          selectShop: pushShopID
        },
        () => {
          const promise = this.loadTaskFromServer();
          this.preload.add(promise);
        }
      );
    });

    return promise;
  }

  /**
   *
   * @param event
   * @param index
   * @param value
   */
  handleChangeShop = (event, index, value) => {
    this.setState(
      {
        headers: {},
        showCategorySort: false,
        selectShop: value
      },
      () => {
        const { dispatch } = this.props;
        dispatch(changePage(1));

        this.loadTaskFromServer();
        this.clearSelectedCategories();
        localStorage.shopID = value;
      }
    );
  };

  /**
   *
   * @param page
   * @returns {*}
   */
  loadTaskFromServer(page = 1, productId = null) {
    const { selectShop, startDate, endDate, auditStatus } = this.state;

    const query = new URLSearchParams(location.search);
    const mid = query.has('mid') ? Number(query.get('mid')) : JSON.parse(localStorage.mission).id;
    const sid = query.has('sid') ? Number(query.get('sid')) : JSON.parse(localStorage.scenario).id;

    if (selectShop) {
      const promise = configAxios({
        method: 'GET',
        url: `/${this.urlApi}/audit?`,
        params: {
          missionId: mid,
          scenarioId: sid,
          shopId: selectShop,
          startDate,
          endDate,
          page,
          plu: productId,
          auditStatus
        }
      });

      promise.then(response => {
        const {
          data: { items, headers, pagination, options, audit }
        } = response;

        this.setState({
          items,
          headers,
          pagination,
          actionsStatus: options.actions_enabled,
          restrictDateStart: audit.historicDate
        });
      });

      this.preload.add(promise);

      return promise;
    }
  }

  selectCategory = id => {
    const index = this.state.selectedCategories.indexOf(id);
    if (index < 0) {
      const arr = this.state.selectedCategories;
      arr.push(id);
      this.setState({
        selectedCategories: arr
      });
    } else {
      const arr = this.state.selectedCategories;
      arr.splice(index, 1);
      this.setState({
        selectedCategories: arr
      });
    }
  };

  clearSelectedCategories = () => {
    this.setState({
      selectedCategories: []
    });
  };

  evalHtml(html) {
    return { __html: html };
  }

  handleUpdateReport = date => {
    const { startDate, endDate } = date;
    this.setState(
      {
        startDate,
        endDate
      },
      () => {
        const { dispatch } = this.props;
        dispatch(changePage(1));

        this.loadTaskFromServer();
      }
    );
  };

  handleAuditStatus = () => {
    this.setState(
      {
        auditStatus: this.state.auditStatus ? 0 : 1
      },
      () => {
        const { dispatch } = this.props;
        dispatch(changePage(1));
        dispatch(searchProduct(null));
        this.loadTaskFromServer();
      }
    );
  };

  render() {
    const { blockingContent, language, mission, pageLoadingStatus } = this.props;
    const {
      items,
      headers,
      suggest,
      endDate,
      startDate,
      pagination,
      shopItems,
      emptyShop,
      emptyPage,
      selectShop,
      auditStatus,
      actionsStatus,
      showCategorySort,
      restrictDateStart
    } = this.state;

    const lang = language.translation ? language.translation.directTask : {};
    const { translation } = language;

    const taskNoFound = !pageLoadingStatus && items && items.length === 0 && !emptyShop;
    const shopsNoFound = !pageLoadingStatus && emptyShop;

    return (
      <div>
        <MissionsNavigation />
        {!blockingContent && (
          <div>
            {!emptyPage && !emptyShop && (
              <div>
                <div className={styles.actionWrap}>
                  <div className={cls(styles.actionWrapSelect,styles.searchWidth)}>
                    <Autocomplete
                      mission={mission}
                      shopsData={shopItems}
                      currentShop={+selectShop}
                      handleChangeShop={this.handleChangeShop}
                      translation={language.translation}
                      loadShopsFromServer={this.loadShopsFromServer}
                    />
                    <DatePickerComponent
                      updateFilters={this.handleUpdateReport}
                      restrictDateStart={restrictDateStart}
                    />
                  </div>
                  {actionsStatus && (
                    <div className={styles.buttonWrap}>
                      <ButtonField className={styles.button} onClick={this.handleAuditStatus}>
                        {auditStatus ? lang.hide : lang.show}
                      </ButtonField>
                    </div>
                  )}
                </div>

                {items.length > 0 && !showCategorySort && (
                  <div className={styles.tableContainer}>
                    <div className={styles.tableWrap}>
                      <TableAudit
                        urlApi={this.urlApi}
                        items={items}
                        headers={headers}
                        suggest={suggest}
                        shopId={selectShop}
                        auditStatus={auditStatus}
                        startDate={startDate}
                        endDate={endDate}
                        actionsStatus={actionsStatus}
                      />
                    </div>
                    <div className={styles.bottomBlock}>
                      <Paginate data={pagination} />
                    </div>
                  </div>
                )}
                {taskNoFound && <div className={styles.tableWrapEmpty}>{translation.errors.noCases}</div>}
              </div>
            )}
            {shopsNoFound && <div className={styles.empty}>{translation.errors.noShops}</div>}
          </div>
        )}
      </div>
    );
  }
}

Task.defaultProps = {
  searchProduct: null
};

Task.propTypes = {
  mission: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  changePage: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  loadTask: PropTypes.object.isRequired,
  searchProduct: PropTypes.number,
  pageLoadingStatus: PropTypes.bool.isRequired,
  language: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  blockingContent: PropTypes.bool.isRequired // eslint-disable-line react/forbid-prop-types
};

export default connect(store => {
  return {
    changePage: store.changePage,
    loadTask: store.loadTask,
    searchProduct: store.searchProduct,
    pageLoadingStatus: store.pageLoadingStatus,
    language: store.language,
    blockingContent: store.blockingContent,
    mission: store.mission
  };
})(Task);
