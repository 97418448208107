import React, { Component } from 'react';
import { Col } from 'react-flexbox-grid';
import PropTypes from 'prop-types';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockClosedIcon from '@material-ui/icons/Lock';

import styles from './UpdatesController.css';

class UpdatesController extends Component {
  state = {
    updatesLocked: false,
    value: 0
  };

  toggleLock = () => {
    this.setState({
      updatesLocked: !this.state.updatesLocked
    });
  };

  render() {
    const { children, width } = this.props;
    const { updatesLocked } = this.state;

    const childrenWithProps = React.Children.map(children, child =>
      React.cloneElement(child, { updatesLocked: updatesLocked })
    );

    return (
      <Col md={+width} className={styles.widgetWrap}>
        <div className={styles.updatesButton} onClick={this.toggleLock} role="presentation">
          <div>
            {updatesLocked ? (
              <LockClosedIcon
                style={{
                  fontSize: 20
                }}
              />
            ) : (
              <LockOpenIcon
                style={{
                  fontSize: 20
                }}
              />
            )}
          </div>
        </div>
        <div>{childrenWithProps}</div>
      </Col>
    );
  }
}

UpdatesController.defaultProps = {
  width: '',
  children: {}
};

UpdatesController.propTypes = {
  width: PropTypes.string, // eslint-disable-line react/forbid-prop-types
  children: PropTypes.object // eslint-disable-line react/forbid-prop-types
};

export default UpdatesController;
