import gtag, { install } from 'ga-gtag';

export const GA_ID = 'UA-119276457-3'; // test UA-167293262-1

export const init = () => {
  if (process.env.REACT_APP_enable_google_analytics) {
    const userData = localStorage.userData ? JSON.parse(localStorage.userData) : null;
    install(GA_ID);
    gtag('js', new Date());
    if (userData && userData.id !== undefined) {
      gtag('config', GA_ID, {
        user_id: userData.id
      });
      gtag('event', 'login');
    }
  }
  return process.env.REACT_APP_enable_google_analytics;
};