import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { DateRangeFilter, NumRangeFilter, SearchFilter, SelectFilter, SwitchFilter } from '../../Header';
import SearchColumnFilter from './SearchColumnFilter/SearchColumnFilter';
import SelectColumnFilter from './SelectColumnFilter/SelectColumnFilter';
import NumRangeColumnFilter from './NumRangeColumnFilter/NumRangeColumnFilter';
import DateRangeColumnFilter from './DateRangeColumnFilter/DateRangeColumnFilter';
import SwitchColumnFilter from './SwitchColumnFilter/SwitchColumnFilter';

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ColumnFilter = props => {
  const { language, header, items, onChange, onClose } = props;

  const [value, setValue] = useState(header.filter.value);

  const uniqueColumnItems = useMemo(() => {
    const uniqueItems = [...(new Set(items.map(item => item[header.property])))].filter(item => item !== null);
    uniqueItems.sort();
    return uniqueItems;
  }, [items]);

  const translation = useMemo(() => {
    return language.translation ? language.translation.virtualStock : {};
  }, [language]);

  const handleChange = value => {
    setValue(value);
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onChange(header, value);
    onClose();
  };

  const handleEnter = () => {
    onChange(header, value);
    onClose();
  };

  return (
    <Dialog
      maxWidth="xs"
      aria-labelledby="column-filter-dialog"
      open
    >
      <DialogTitle id="column-filter-dialog-title">{`${translation.filter} - ${header.name}`}</DialogTitle>
      <DialogContent dividers>
        {(header.filter instanceof SearchFilter) && (
          <SearchColumnFilter value={value} onChange={handleChange} onEnter={handleEnter} />
        )}
        {(header.filter instanceof SwitchFilter) && (
          <SwitchColumnFilter value={value} onChange={handleChange} onEnter={handleEnter} />
        )}
        {(header.filter instanceof SelectFilter) && (
          <SelectColumnFilter items={uniqueColumnItems} value={value} onChange={handleChange} />
        )}
        {(header.filter instanceof NumRangeFilter) && (
          <NumRangeColumnFilter value={value} onChange={handleChange} onEnter={handleEnter} />
        )}
        {(header.filter instanceof DateRangeFilter) && (
          <DateRangeColumnFilter value={value} onChange={handleChange} onEnter={handleEnter} />
        )}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          {translation.cancel}
        </Button>
        <Button onClick={handleOk} color="primary" variant="outlined">
          {translation.ok}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ColumnFilter.propTypes = {
  header: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  language: PropTypes.object.isRequired
};

export default connect(
  store => {
    return {
      language: store.language
    };
  }
)(ColumnFilter);