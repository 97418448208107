import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cls from 'classnames';
import moment from 'moment';

import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';

import MissionsNavigation from '../../components/MissionsNavigation';
import configAxios from '../../config/axios';
import Preload from '../../constructors/Preload';

import { setPageLoadingStatus } from '../../store/actions/actions';
import Autocomplete from '../../components/GridAutocomplete';
import ButtonDefault from '../../components/Buttons/ButtonDefault';
import Info from '../../components/tableComponents/Info/Info';
import ButtonAir from '../../components/Buttons/ButtonAir';
import TaskDropdown from '../../components/tableComponents/TaskDropdown/TaskDropdown';

import styles from './CasesForm.css';

const scenario = { link: 'directTask' };

class CasesForm extends Component {
  constructor(props) {
    super(props);

    this.preload = new Preload();
    this.preload.subscribe(promises => {
      this.props.dispatch(setPageLoadingStatus(promises.length > 0));
    });
  }

  state = {
    productName: '',
    comment: '',
    productId: '',
    currentStatusName: '',
    productPhotos: null,
    itemId: null,
    caseType: null,
    suggestItems: [],
    currentTree: [],
    open: false,
    disabledDelete: true,
    disabledComment: true,
    shopItems: [],
    selectShop: 0
  };

  componentDidMount() {
    this.loadShopsFromServer();
  }

  createTask = e => {
    e.preventDefault();
    const { selectShop, productId } = this.state;
    let _this = this;

    let formData = new FormData();
    var aestTime = new Date().toLocaleString("en-US", { timeZone: 'Atlantic/Reykjavik' });
    const timeForUpdate = new Date(aestTime).toISOString();

    const query = new URLSearchParams(location.search);
    const mid = query.has('mid') ? Number(query.get('mid')) : JSON.parse(localStorage.mission).id;

    formData.append('shopId', selectShop);
    formData.append('plu', productId);
    formData.append('missionId', mid);
    formData.append('actionDateTime', moment(timeForUpdate).format("YYYY-MM-DD HH:mm:ss"));

    const promise = configAxios({
      method: 'POST',
      url: `/task/create`,
      data: formData
    });

    this.preload.add(promise);

    promise.then(function(response) {
      if (response.status === 200) {
        const {
          data: { currentTree, currentStatusName, itemId, caseType }
        } = response;
        _this.setState({
          currentTree,
          currentStatusName,
          itemId,
          caseType
        });
      }
    });

    promise.catch(function(error) {
      console.error(error.response);
    });
  };

  handlePushNewTree = tree => {
    this.setState({
      currentTree: tree.currentTree,
      currentStatusName: tree.currentStatusName
    });
  };

  handleProductChange = e => {
    e.preventDefault();

    const query = new URLSearchParams(location.search);
    const mid = query.has('mid') ? Number(query.get('mid')) : JSON.parse(localStorage.mission).id;

    const { language } = this.props;

    this.setState({
      productName: e.target.value
    });

    if (e.target.value.length > 3) {
      let _this = this;
      let ifResponseEmpty = [
        {
          category_id: 0,
          id: 0,
          name: language.translation.search.notFound
        }
      ];

      configAxios({
        method: 'POST',
        url: `/task/suggest`,
        data: {
          shopId: this.state.selectShop,
          query: e.target.value,
          missionId: mid
        }
      })
        .then(function(response) {
          _this.setState({
            suggestItems: response.data.products.length ? response.data.products : ifResponseEmpty
          });
        })
        .catch(function(error) {
          console.error(error.response);
        });
    } else {
      this.setState({
        suggestItems: [],
        productId: ''
      });
    }
  };

  handleCommentChange = e => {
    e.preventDefault();

    this.setState({
      comment: e.target.value,
      disabledComment: false
    });
  };

  loadShopsFromServer() {

    const query = new URLSearchParams(location.search);
    const mid = query.has('mid') ? Number(query.get('mid')) : JSON.parse(localStorage.mission).id;

    let _this = this;
    configAxios({
      method: 'GET',
      url: `/user/get-shops`,
      params: {
        missionId: mid
      }
    })
      .then(function(response) {
        const shopItem = response.data.all;
        let pushShopID = +response.data.all[0].id;
        if (localStorage.shopID) {
          for (let i = 0, len = shopItem.length; i < len; i++) {
            if (shopItem[i].id === localStorage.shopID) {
              pushShopID = shopItem[i].id;
            }
          }
        }
        _this.setState({
          shopItems: shopItem,
          selectShop: pushShopID
        });
        // _this.loadTaskFromServer();
      })
      .catch(function(error) {
        console.error(error.response);
      });
  }

  handleProductPhotoChange = e => {
    e.preventDefault();

    this.setState({
      productPhotos: e.target.files
    });
  };

  selectProduct = item => {
    if (item.id) {
      this.setState({
        suggestItems: [],
        productName: item.name,
        productId: item.id
      });
    }
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleChangeShop = (event, index, value) => {
    this.setState({
      selectShop: value,
      productName: '',
      productId: '',
      productPhotos: null
    });
  };

  newTask = () => {
    this.setState({
      productName: '',
      productId: '',
      itemId: null,
      currentTree: [],
      open: false,
      disabledDelete: true,
      comment: ''
    });
  };

  deleteCase = () => {
    const { itemId, selectShop, caseType } = this.state;

    const _this = this;

    var aestTime = new Date().toLocaleString("en-US", { timeZone: 'Atlantic/Reykjavik' });
    const timeForUpdate = new Date(aestTime).toISOString();

    const query = new URLSearchParams(location.search);
    const mid = query.has('mid') ? Number(query.get('mid')) : JSON.parse(localStorage.mission).id;

    const promise = configAxios({
      method: 'DELETE',
      url: `/task/delete`,
      params: {
        itemId,
        missionId: mid,
        shopId: selectShop,
        caseType,
        actionDateTime: moment(timeForUpdate).format("YYYY-MM-DD HH:mm:ss")
      }
    });

    promise.then(function() {
      _this.newTask();
    });

    return promise;
  };

  createComment = () => {
    const { itemId, selectShop, comment, caseType } = this.state;

    const query = new URLSearchParams(location.search);
    const mid = query.has('mid') ? Number(query.get('mid')) : JSON.parse(localStorage.mission).id;

    const _this = this;
    const promise = configAxios({
      method: 'POST',
      url: `task/comment`,
      data: {
        shopId: selectShop,
        text: comment,
        itemId,
        caseType,
        missionId: mid
      }
    });

    promise.then(function() {
      _this.setState({
        disabledComment: true,
        disabledDelete: false
      });
    });

    return promise;
  };

  deleteComment = () => {
    const { itemId, caseType } = this.state;
    const query = new URLSearchParams(location.search);
    const mid = query.has('mid') ? Number(query.get('mid')) : JSON.parse(localStorage.mission).id;

    const _this = this;
    const promise = configAxios({
      method: 'DELETE',
      url: `task/comment-delete`,
      params: {
        itemId,
        missionId: mid,
        caseType
      }
    });

    this.preload.add(promise);

    promise.then(function() {
      _this.setState({
        disabledComment: true,
        disabledDelete: true,
        comment: ''
      });
    });

    return promise;
  };
  
  render() {
    const { mission, language } = this.props;
    const {
      comment,
      currentTree,
      selectShop,
      itemId,
      productId,
      productName,
      open,
      disabledComment,
      disabledDelete,
      currentStatusName,
      caseType,
      suggestItems,
      shopItems,
    } = this.state;

    const lang = language.translation ? language.translation.casesForm : {};
    const disabledButton = !productId;

    return (
      <div>
        <MissionsNavigation />
        {selectShop ? <div className={styles.pageContainer}>
          <div className={styles.casesFormWrap}>
            <Autocomplete
              mission={mission}
              autoWidth
              shopsData={shopItems}
              currentShop={+selectShop}
              isDisabled={!!currentTree.length}
              handleChangeShop={this.handleChangeShop}
              translation={language.translation}
              loadShopsFromServer={this.loadShopsFromServer}
            />
            <br />
            <TextField
              className={styles.casesFormInput}
              label={lang.product}
              placeholder={lang.plu}
              onChange={this.handleProductChange}
              value={productName}
              name="plu"
              disabled={!!currentTree.length}
              fullWidth
            />
            <div className={styles.suggestWrap}>
              <List className={cls({ [styles.suggestBorder]: suggestItems.length })}>
                {suggestItems.map(item => (
                  <ListItem button key={item.id} onClick={() => this.selectProduct(item)}>
                    <ListItemText primary={item.name} />
                  </ListItem>
                ))}
              </List>
            </div>
            {!!currentTree.length && (
              <div>
                <div className={styles.infoWrap}>
                  <Chip label={currentStatusName} />
                  <Info urlApi="task" itemId={itemId} />
                </div>
                <div className={styles.taskWrap}>
                  <TaskDropdown
                    urlApi="task"
                    data={currentTree}
                    itemId={itemId}
                    shopId={selectShop}
                    manual={1}
                    newTree={this.handlePushNewTree}
                    caseType={caseType}
                    casesForm
                  />
                </div>
              </div>
            )}
            <div className={styles.actionBtnWrap}>
              {itemId ? (
                <span>
                  <ButtonAir className={styles.newButton} onClick={this.newTask}>
                    {lang.new}
                  </ButtonAir>
                  <Button color="secondary" variant="contained" className={styles.deleteButton} onClick={this.deleteCase}>
                    {lang.delete}
                  </Button>
                </span>
              ) : (
                <ButtonAir disabled={disabledButton} onClick={this.createTask}>
                  {lang.add}
                </ButtonAir>
              )}
              <Link to={scenario.link}>
                <ButtonDefault>{lang.cancel}</ButtonDefault>
              </Link>
            </div>
            <Dialog open={open} maxWidth="sm" fullWidth onClose={this.handleClose}>
              <DialogTitle>{lang.success}</DialogTitle>
              <DialogActions>
                <Button onClick={this.handleClose} autoFocus>
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          {itemId && (
            <div className={cls(styles.casesFormWrap, styles.casesFormML)}>
              <TextField
                className={styles.textField}
                placeholder={lang.comment}
                onChange={this.handleCommentChange}
                value={comment}
                multiline
                rows={1}
                rowsMax={8}
                fullWidth
              />
              <div className={styles.actionButton}>
                <ButtonAir onClick={this.createComment} disabled={disabledComment}>
                  {lang.add}
                </ButtonAir>
                <Button variant="contained" color="secondary" onClick={this.deleteComment} disabled={disabledDelete}>
                  {lang.delete}
                </Button>
              </div>
            </div>
          )}
        </div> :
        <CircularProgress className={styles.preloader} />
        }
      </div>
    );
  }
}

CasesForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  language: PropTypes.object.isRequired,
  mission: PropTypes.object.isRequired
};

export default connect(store => {
  return {
    language: store.language,
    mission: store.mission
  };
})(CasesForm);
