export default {
  mobileFirefoxAlert: 'Bu brauzerda vəzifə emalı mövcud deyil.',
  datePicker: {
    from: 'Dən',
    to: 'Ayaq',
    cancel: 'Ləğv et',
    apply: 'Tətbiq et',
    month: [
      'Yanvar',
      'Fevral',
      'Mart',
      'Aprel',
      'May',
      'İyun',
      'İyul',
      'Avqust',
      'Sentyabr',
      'Oktyabr',
      'Noyabr',
      'Dekabr'
    ],
    week: 'həftə',
    weekShot: 'h',
    buttonName: {
      date: 'Tarix',
      week: 'Həftə',
      month: 'Ay'
    },
    modal: {
      header: 'Diqqət',
      text: 'Yanlış tarix aralığı seçildi',
      action: 'Qəbul et'
    }
  },
  filters: {
    name: 'Ad'
  },
  scenario: {
    noDataReceived: 'Məlumat alınmadı',
    filterPart: {
      name: 'Ad',
      clear: 'Təmizlə',
      notFound: 'Tapılmadı',
      search: 'Axtar...'
    }
  },
  header: {
    logout: 'Çıxış',
    changePassword: 'Şifrəni dəyişdir',
    settings: 'Təsdiq',
    title: 'İstifadəçi interfeysi bax',
    searchTitle: 'İstifadəçi axtar',
    input: 'Adı və ya giriş yazmağa başlayın...',
    cancel: 'Ləğv et',
    view: 'Baxış',
    id: 'ID',
    login: 'Giriş',
    name: 'Ad',
    phone: 'Telefon',
    email: 'E-poçt',
    stopSettings: 'Təfrişləri dayandır'
  },
  directTask: {
    addCase: 'Vaka əlavə et',
    commentCase: 'rəy bildirin',
    actions: 'Əməliyyatlar',
    searchProduct: 'PLU/Barkod',
    searchProductShort: 'PLU/Barkod',
    photo: 'Şəkil',
    back: 'Geri',
    treeEmpty: 'ƏMƏLLƏR MƏHDUDLAŞDIRILMIŞDIR',
    step: 'Addım',
    sortingButton: 'Sıralama',
    sortButton: 'Sırala',
    add: 'Əlavə et',
    delete: 'Sil',
    deleteManualCases: 'Əlavə təyinatları sil',
    show: 'Göstər',
    hide: 'Gizlə',
    comment: 'Rəy',
    showDoneCases: 'Tamamlanmış tapşırıqları göstərin',
    hideDoneCases: 'Tamamlanmış tapşırıqları gizlədin'
  },
  gridTable: {
    pluList: 'PLU siyahısı'
  },
  casesForm: {
    product: 'Məhsul',
    loadFoto: 'Foto yüklə',
    plu: 'Ad/PLU',
    success: 'Vaka yaradıldı',
    delete: 'Sil',
    add: 'Yaradın',
    new: 'Yeni',
    cancel: 'Ləğv et',
    comment: 'Rəy'
  },
  pagination: {
    apply: 'Get',
    placeholder: 'Səhifə'
  },
  restorePass: {
    noLength: 'Şifrə ən azı 12 simvoldan ibarət olmalıdır',
    noLowercase: 'Şifrədə ən az bir kiçik hərf olmalıdır',
    noUppercase: 'Şifrədə ən az bir böyük hərf olmalıdır',
    noNumber: 'Şifrədə ən az bir rəqəm simvolu olmalıdır',
    noMatch: 'Şifrələr uyğun gəlmir',
    password: 'Şifrə',
    repeatPassword: 'Şifrəni təkrarla',
    ok: 'Qəbul et',
    cancel: 'Ləğv et'
  },
  navigation: {
    missions: 'Missiyalar',
    scenarios: 'Senarilər'
  },
  changePassword: {
    previousPassword: 'Cari şifrə',
    newPassword: 'Yeni şifrə',
    confirmPassword: 'Yeni şifrəni təsdiq edin',
    apply: 'Tətbiq et',
    title: 'Şifrəni dəyişdir',
    success: 'Şifrə uğurla dəyişdirildi',
    wrongPassword: 'Yanlış şifrə',
    weakPassword: 'Şifrə tələblərə uyğun deyil',
    passwordsNotMatch: 'Şifrələr uyğun gəlmir',
    requirements: 'Yeni şifrədə ən az 12 simvol, 1 böyük və kiçik hərf və 1 rəqəm olmalıdır'
  },
  auth: {
    login: 'Giriş',
    password: 'Şifrə',
    restorePass: 'Şifrəni bərpa et',
    submit: 'Göndər',
    loginError: 'Giriş və/ya da şifrə yanlışdır'
  },
  restorePassForm: {
    restore: 'Bərpa',
    cancel: 'Ləğv et',
    info: 'Xahiş olunur qeydiyyatda olan e-poçtunuzu daxil edin',
    emailInvalid: 'Göndərilmiş e-poçt tapılmadı',
    email: 'E-poçt',
    success: 'Bərpa keçidi e-poçtunuza göndərildi'
  },
  search: {
    notFound: 'Tapılmadı'
  },
  noMatchPage: {
    title: 'Tələb olunan səhifə tapılmadı',
    body: 'Əsas səhifəyə qayıt'
  },
  warnings: {
    title: 'Diqqət',
    isDesktop: 'Şəkillərin yüklənməsi yalnız sistem mobil versiyasında mümkündür. Zəhmət olmasa, telefon və ya plansetinizdəki kameradan istifadə edin.'
  },
  errors: {
    netErrorText: 'Şəbəkə səhvi. Zəhmət olmasa, səhifəni yenidən yükləyin',
    netErrorReload: 'Yenidən yüklə',
    netErrorTitle: 'Səhv',
    netErrorClose: 'Bağla',
    noShops: 'Əvə bilmisən! Deyəsən, sizə hələ heç bir mağaza təyin edilməyib. Bu barədə menecerinizə deyin',
    noCases: 'Görünür, xidmətimiz bu mağazada heç bir tapşırıq tapa bilmədi. Onları özünüz yaratmağa başlayın',
    noAutoOrder: 'Avtomatik sifarişi tənzimləmək üçün tapşırıq yoxdur',
    causeError: 'Səhv',
    scenariosEmpty: 'Senarilər qüvvədə deyil'
  },
  officeUI: {
    resetZoom: 'Zoom-u sıfırla'
  },
  gridAutocomplete: {
    placeholder: 'Mağaza adını yazmağa başlayın',
    notFound: 'Mağaza tapılmadı'
  },
  targets: {
    nothing: '',
    shop: 'Mağaza',
    category: 'Kateqoriya',
    supplier: 'Təchizatçı',
    vendor: 'Tədarükçü',
    brand: 'Brend',
    plu: 'PLU',
    day: 'Gün',
    week: 'Həftə',
    month: 'Ay',
    quarter: 'Rüb',
    year: 'İl'
  },
  bubbleChart: {
    qty: 'miqdar',
    currency: 'manat'
  },
  chart: {
    quarter: 'rüb',
    week: 'həftə'
  },
  download: {
    CSV: 'CSV yüklə',
    XLS: 'XLS yüklə'
  },
  counterTask: {
    task: 'Gözləyən tapşırıqlar',
    noTask: 'Heç bir tapşırıq yoxdur'
  },
  filtersShop: {
    shopName: 'Mağaza ünvanı',
    shopId: 'Mağaza adı'
  },
  chartCaption: {
    thousand: 'min',
    million: 'M',
    billion: 'Milyard'
  },
  common: {
    close: 'Bağla',
    userNotFound: 'İstifadəçi tapılmadı',
    yes: 'Bəli',
    no: 'Xeyr'
  },
  virtualStock: {
    headers: {
      case_id: "ID",
      plu_original: "PLU",
      plu: "Daxili PLU",
      shop_id: "Mağaza ID",
      ass_type: "Assortiment Növü",
      category_id3: "Seksiya ID",
      category_id4: "Kateqoriya ID",
      stock: "Qalıq həcmi",
      stock_sum: "Balansların miqdarı",
      product_turnover: "Dönüş",
      days_without_sales: "Satışsız gün",
      last_sales_date: "Son satış tarixi",
      last_sales_volume: "Son satış həcmi",
      last_shipment_date: "Son çatdırılma tarixi",
      last_shipment_volume: "Son çatdırılma həcmi",
      promo_start: "Promo Başlanğıc",
      promo_end: "Promo Son",
      promo_period: "Promo Müddəti",
      is_promo: "Promo",
      title: "Ad",
      category_name3: "Seksiya",
      category_name4: "Kateqoriya"
    },
    hideReadyTasks: "Hazır tapşırıqları gizlə",
    nothingFound: "Heç nə tapılmadı",
    shopsNotFound: "Mağazalar tapılmadı",
    chooseShop: "Mağaza seçin",
    createTasks: "Tapşırıqlar yaradın",
    taskCreating: "Tapşırıqlar yaradılır",
    next: "Davam et",
    columns: "Sütunlar",
    from: "Dən",
    ok: "OK",
    cancel: "Ləğv et",
    filter: "Filter",
    chooseValue: "Dəyər seçin",
    enterValue: "Dəyər daxil edin",
    rangeFrom: "Dən",
    rangeTo: "Ayaq",
    selected: "Seçilmiş",
    foundRows: "Tapılan sıralar",
    createdTasksTotal: "Yaradılan tapşırıqların ümumi sayı"
  }
};
