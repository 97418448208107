import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col } from 'react-flexbox-grid';
import Widget from '../../components/Widget/Widget';
import WidgetDownload from '../../components/WidgetDownload/WidgetDownload';
import Chart from '../../components/Chart';
import ComposedChart from '../../components/Chart/ComposedChart';
import PieChart from '../../components/Chart/PieChart';
import Table from '../../components/ScenarioTable';
import UpdatesController from '../ScenarioContent/UpdatesController';
import UiElement from '../UiElement/UiElement';
import { setGroupTabAction } from '../../store/actions/actions';
import styles from './Group.css';

class Group extends UiElement {
  constructor(props) {
    super(props);
    this.state = {
      tabIsActive: 0,
      transition: false
    };
  }

  componentDidMount() {
    this.setState({
      tabIsActive: 0
    });
  }

  tabChange = index => {
    const { dispatch } = this.props;
    this.setState({
      tabIsActive: index
    });
    dispatch(setGroupTabAction(index));
  };

  transitionIsActive = clicked => {
    const { dataGroup, dispatch } = this.props;
    const { tabIsActive } = this.state;
    const transitionIndex = Object.keys(dataGroup.ref).map(item => {
      return item;
    });
    const item = tabIsActive + 1;
    if (transitionIndex.indexOf(item)) {
      this.setState({
        tabIsActive: dataGroup.ref[item] - 1
      });
      dispatch(setGroupTabAction(dataGroup.ref[item] - 1));
    }
    this.setState({
      transition: clicked
    });
  };

  isAbleToTransition = () => {
    const { dataGroup } = this.props;
    let buff;
    if (dataGroup.ref) {
      buff = Object.keys(dataGroup.ref).map(item => item - 1);
    }
    return buff;
  };

  render() {
    const { filters, setDrillFilters, updateFilters, dataGroup, datePickerDate } = this.props;

    const { width: maxWidth, ref: transition = {} } = dataGroup;

    const { tabIsActive } = this.state;
    const ableToTransition = this.isAbleToTransition();

    return (
      <div>
        <div className={styles.containerTabs}>
          <Col md={+maxWidth} className={styles.tabsWrap}>
            {dataGroup.data.map((item, indexItem) => {
              const key = '' + indexItem;
              const ulElemClicked = indexItem === tabIsActive;
              return (
                <button
                  key={key}
                  onClick={() => this.tabChange(indexItem)}
                  value={item.data.name}
                  className={ulElemClicked ? styles.activeTabs : styles.tabs}
                >
                  {item.data.name}
                </button>
              );
            })}
          </Col>
          {dataGroup.data.map((item, indexItem) => {
            let jumpTo = ableToTransition ? ableToTransition.indexOf(indexItem) : -1;
            const transitionChecked = jumpTo >= 0 ? transition : '';
            const key = '' + indexItem;
            const ulElemClicked = indexItem === tabIsActive;
            const openWrap = ulElemClicked ? styles.openWrap : styles.closeWrap;
 
            if (item.type === 'widget') {
              return (
                <div className={openWrap}>
                  <UpdatesController width={maxWidth.toString()} key={key}>
                    <Widget
                      data={item}
                      filters={filters}
                      updateFilters={updateFilters}
                      setDrillFilters={setDrillFilters}
                    />
                  </UpdatesController>
                </div>
              );
            }
            if (item.type === 'report') {
              return (
                <div className={openWrap}>
                  <WidgetDownload data={item} key={key} maxWidth={dataGroup} />
                </div>
              );
            }
            if (item.type === 'chart') {
              if (item.data.type === 'bubble' || item.data.type === 'scatter') {
                return (
                  <div className={openWrap}>
                    <UpdatesController width={maxWidth} key={key}>
                      <ComposedChart
                        data={item}
                        filters={filters}
                        updateFilters={updateFilters}
                        setDrillFilters={setDrillFilters}
                        transition={transitionChecked}
                        transitionIsActive={this.transitionIsActive}
                        datePickerDate={datePickerDate}
                      />
                    </UpdatesController>
                  </div>
                );
              }
              if (item.data.source[0].type === 'pie') {
                return (
                  <div className={openWrap}>
                    <UpdatesController width={maxWidth} key={key}>
                      <PieChart
                        data={item}
                        filters={filters}
                        updateFilters={updateFilters}
                        setDrillFilters={setDrillFilters}
                        transition={transitionChecked}
                        transitionIsActive={this.transitionIsActive}
                        datePickerDate={datePickerDate}
                      />
                    </UpdatesController>
                  </div>
                );
              } else {
                return (
                  <div className={openWrap}>
                    <UpdatesController width={maxWidth} key={key}>
                      <Chart
                        data={item}
                        filters={filters}
                        updateFilters={updateFilters}
                        setDrillFilters={setDrillFilters}
                        transition={transitionChecked}
                        transitionIsActive={this.transitionIsActive}
                        datePickerDate={datePickerDate}
                      />
                    </UpdatesController>
                  </div>
                );
              }
            }
            if (item.type === 'table') {
              return (
                <div className={openWrap}>
                  <UpdatesController width={maxWidth} key={key}>
                    <Table
                      data={item}
                      filters={filters}
                      updateFilters={updateFilters}
                      setDrillFilters={setDrillFilters}
                      transition={transitionChecked}
                      transitionIsActive={this.transitionIsActive}
                      datePickerDate={datePickerDate}
                    />
                  </UpdatesController>
                </div>
              );
            }
          })}
        </div>
      </div>
    );
  }
}

Group.defaultProps = {
  dataGroup: {}
};

Group.propTypes = {
  dispatch: PropTypes.func.isRequired, // eslint-disable-line
  dataGroup: PropTypes.object.isRequired, // eslint-disable-line
  filters: PropTypes.func.isRequired, // eslint-disable-line
  updateFilters: PropTypes.func.isRequired, // eslint-disable-line
  setDrillFilters: PropTypes.func.isRequired // eslint-disable-line
};

export default connect(store => {
  return {
    setGroupTab: store.setGroupTab,
    datePickerDate: store.datePickerDate
  };
})(Group);
