import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Fab from '@material-ui/core/Fab';
import Popover from '@material-ui/core/Popover';
import CommentIcon from '@material-ui/icons/Comment';
import Preload from '../../../constructors/Preload';
import { setPageLoadingStatus } from '../../../store/actions/actions';

import styles from './Comment.css';

class Comment extends Component {
  constructor(props) {
    super(props);
    const { dispatch, text } = this.props;

    this.state = {
      comment: text,
      openComment: false
    };

    this.preload = new Preload();
    this.preload.subscribe(promises => {
      dispatch(setPageLoadingStatus(promises.length > 0));
    });
  }

  componentDidMount() {
    this.handleSetComment();
  }

  /**
   *
   * @param nextProps
   */
  componentWillReceiveProps(nextProps) {
    if (nextProps.text !== this.props.text) {
      this.handleSetComment(nextProps.text);
    }
  }

  handleSetComment = nextText => {
    const { text } = this.props;

    this.setState({
      comment: nextText || nextText === '' ? nextText : text
    });
  };

  handleCommentOpen = e => {
    this.setState({
      anchorEl: e.currentTarget,
      openComment: true
    });
  };

  handleCommentClose = () => {
    this.setState({
      openComment: false
    });
  };

  render() {
    const { openComment, anchorEl, comment } = this.state;

    return (
      <Fragment>
        <Fab
          size="small"
          color="primary"
          onClick={e => {
            this.handleCommentOpen(e);
          }}
        >
          <CommentIcon />
        </Fab>
        <Popover
          open={openComment}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right'
          }}
          onClose={this.handleCommentClose}
        >
          <div className={styles.popover}>
            <div className={styles.commentView}>{comment}</div>
          </div>
        </Popover>
      </Fragment>
    );
  }
}

Comment.propTypes = {
  text: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired
};

export default connect()(Comment);
