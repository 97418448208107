import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cls from 'classnames';
import Button from '@material-ui/core/Button';
import { Table as TableToolBox, TableHead, TableRow, TableCell } from 'react-toolbox/lib/table';
import { loadTask } from '../../store/actions/actions';
import Search from '../tableComponents/Search/Search';
import configAxios from '../../config/axios';
import Comment from '../tableComponents/Comment/Comment';
import CarouselPhoto from '../tableComponents/CarouselPhoto/CarouselPhoto';
import TaskDropdown from '../tableComponents/TaskDropdown/TaskDropdown';

import styles from './TableEquipment.css';

class TableEquipment extends Component {
  deleteCase = (itemId, caseType) => {
    const { dispatch, shopId } = this.props;

    const query = new URLSearchParams(location.search);
    const mid = query.has('mid') ? Number(query.get('mid')) : JSON.parse(localStorage.mission).id;
    const promise = configAxios({
      method: 'DELETE',
      url: `/${this.props.urlApi}/delete`,
      params: {
        itemId,
        missionId: mid,
        shopId,
        caseType
      }
    });

    promise.then(function() {
      dispatch(loadTask(true));
    });

    return promise;
  };

  render() {
    const { shopId, items = [], headers = {}, suggest = '', manualCases, language, urlApi } = this.props;

    const lang = language.translation ? language.translation.directTask : {};
    return (
      <div>
        <TableToolBox selectable={false}>
          <TableHead class={styles.tableHeadRow}>
            {Object.keys(headers).map(key => {
              if (key === 'product_name') {
                return (
                  <TableCell key={key} class={styles.tableCell} style={{ minWidth: 150 }}>
                    <Search suggest={suggest} shopId={this.props.shopId} />
                  </TableCell>
                );
              }
              return (
                <TableCell key={key} class={styles.tableCell}>
                  {headers[key]}
                </TableCell>
              );
            })}
          </TableHead>
          {items.map((item, index) => {
            const keyCode = index;
            return (
              <TableRow
                key={keyCode}
                class={cls(styles.tableRow, {
                  [styles.tableRowStatus]: item.tree_status_id !== 0,
                  [styles.finalCasesStyle]: item.is_final,
                  [styles.manualCasesStyle]: manualCases
                })}
              >
                {Object.keys(headers).map(key => {
                  if (key === 'tree') {
                    if (!item[key].length) {
                      return (
                        <TableCell key={key} class={styles.tableCell}>
                          {lang.treeEmpty}
                        </TableCell>
                      );
                    }
                    if (manualCases) {
                      return (
                        <TableCell key={key} class={styles.tableCell}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => this.deleteCase(item.item_id, item.type_id)}
                          >
                            {lang.delete}
                          </Button>
                        </TableCell>
                      );
                    }
                    return (
                      <TableCell key={key} class={styles.tableCell}>
                        <TaskDropdown
                          urlApi={urlApi}
                          data={item[key]}
                          itemId={item.item_id}
                          shopId={shopId}
                          caseType={item.type_id}
                        />
                      </TableCell>
                    );
                  } else if (key === 'image') {
                    return (
                      <TableCell key={key} class={cls(styles.tableCell, styles.textCenter)}>
                        <CarouselPhoto images={item[key] ? item[key] : []} />
                      </TableCell>
                    );
                  } else if (key === 'comment') {
                    return (
                      <TableCell key={key} class={cls(styles.tableCell, styles.textCenter)}>
                        <Comment
                          urlApi={urlApi}
                          shopId={shopId}
                          itemId={item.item_id}
                          text={item[key] ? item[key] : ''}
                          caseType={item.type_id}
                        />
                      </TableCell>
                    );
                  } else {
                    return (
                      <TableCell key={key} class={styles.tableCell}>
                        {item[key]}
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
            );
          })}
        </TableToolBox>
      </div>
    );
  }
}

TableEquipment.propTypes = {
  urlApi: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  items: PropTypes.array,
  headers: PropTypes.object,
  suggest: PropTypes.string,
  shopId: PropTypes.number.isRequired,
  language: PropTypes.object.isRequired,
  manualCases: PropTypes.bool.isRequired
};

export default connect(store => {
  return {
    language: store.language
  };
})(TableEquipment);
