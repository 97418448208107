import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import Tooltip from '@material-ui/core/Tooltip';

import InfoIcon from '@material-ui/icons/InfoOutlined';
import styles from './Widget.css';
import UiElement from '../UiElement/UiElement';

class Widget extends UiElement {
  static scaleFontsize = (containerWidth = 0, numberText) => {
    if (Number(containerWidth) > 3) return 30;

    const numberLength = numberText === null ? 0 : numberText.toString().length;
    const textLength = numberLength + Math.trunc(numberLength / 3);

    if (Number(containerWidth) === 2) {
      if (textLength < 5) {
        return 30;
      } else if (textLength >= 5 && textLength < 6) {
        return 24;
      } else if (textLength >= 6 && textLength < 8) {
        return 20;
      } else if (textLength >= 8 && textLength <= 10) {
        return 16;
      } else {
        return 12;
      }
    } else {
      if (textLength > 20) {
        return 12;
      } else if (textLength >= 15 && textLength <= 20) {
        return 15;
      } else if (textLength >= 13 && textLength < 15) {
        return 20;
      } else if (textLength > 9 && textLength < 13) {
        return 23;
      } else {
        return 30;
      }
    }
  };

  constructor(props) {
    super(props);

    this.state = {
      filters: {},
      fontSize: Widget.scaleFontsize(this.props.data.width, this.props.data.data.source)
    };
  }

  UNSAFE_componentWillMount() {
    const { filters } = this.props;
    this.setState({
      filters: filters
    });
  }

  shouldComponentUpdate(nextProps) {
    return !this.props.updatesLocked && this.props.updatesLocked === nextProps.updatesLocked;
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.data.data.source !== nextProps.data.data.source) {
      this.setState({
        fontSize: Widget.scaleFontsize(nextProps.data.width, nextProps.data.data.source)
      });
    }
  }
  getNumberColor(data, source) {
    const kpi = data.kpi.name ? data.kpi.name : data.kpi;
    const inc = kpi.indexOf('inc') !== -1;
    //prettier-ignore
    return inc
      ? {
        color: source === 0 ? 'inherit' : source > 0 ? '#18ac04' : '#ff002d',
      }
      : {};
  }

  render() {
    const { filters, fontSize } = this.state;
    const {
      data: {
        data,
        data: { source, dimension, unit, tooltip, name }
      }
    } = this.props;
    const subScenario = data['sub-scenario'] ? data['sub-scenario'] : false;

    return (
      <div
        className={styles.widget}
        onClick={() => (subScenario ? this.getSubScenarioData(subScenario, filters()) : false)}
        role="presentation"
      >
        <div className={subScenario ? styles.nameSub : styles.name}>
          <Tooltip title={tooltip} classes={{ tooltip: styles.tooltipStyle }} placement="top">
            <InfoIcon class={styles.icon} />
          </Tooltip>
          {subScenario ? <span className={styles.underlined}>{name}</span> : name}
        </div>
        <div className={styles.source} style={{ fontSize: fontSize }}>
          {source === null ? (
            'no data'
          ) : (
            <NumberFormat
              style={this.getNumberColor(data, source)}
              value={source}
              displayType={'text'}
              thousandSeparator={' '}
            />
          )}
        </div>
        <div className={styles.unit}>{dimension ? dimension : unit}</div>
      </div>
    );
  }
}

Widget.defaultProps = {
  data: {},
  updatesLocked: false
};

Widget.propTypes = {
  data: PropTypes.object,
  updatesLocked: PropTypes.bool,
  filters: PropTypes.func.isRequired,
  updateFilters: PropTypes.func.isRequired,
  setDrillFilters: PropTypes.func.isRequired
};

export default connect(store => ({
  parentScenario: store.parentScenario
}))(Widget);
