import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'react-toolbox/lib/dialog';
import { connect } from 'react-redux';
import styles from './ErrorDialog.css';
import { showNetworkError } from '../../store/actions/actions';

class ErrorDialog extends Component {
  handleToggle = () => {
    const { dispatch } = this.props;
    dispatch(showNetworkError(false, '', ''));
    location.reload();
  };

  closeToggle = () => {
    const { dispatch } = this.props;
    dispatch(showNetworkError(false, '', ''));
  };

  render() {
    const { errorNetworkFailed, language } = this.props;
    const { translation } = language;
    const errorStatus = errorNetworkFailed && errorNetworkFailed.showError ? errorNetworkFailed.showError : false;

    return (
      <Dialog
        actions={[
          {
            label: translation.errors.netErrorReload,
            onClick: this.handleToggle,
            className: styles.buttonOk
          },
          {
            label: translation.errors.netErrorClose,
            onClick: this.closeToggle,
            className: styles.buttonOk
          }
        ]}
        active={errorStatus}
        className={styles.errorDialog}
        onEscKeyDown={this.closeToggle}
        onOverlayClick={this.closeToggle}
        title={translation.errors.netErrorTitle}
        type="normal"
      >
        <p>{translation.errors.netErrorText}</p>
      </Dialog>
    );
  }
}

ErrorDialog.defaultProps = {
  active: false
};

ErrorDialog.propTypes = {
  dispatch: PropTypes.func.isRequired,
  errorNetworkFailed: PropTypes.object.isRequired, //eslint-disable-line
  language: PropTypes.object.isRequired //eslint-disable-line
};

const mapStateToProps = store => ({
  errorNetworkFailed: store.errorNetworkFailed,
  language: store.language
});

export default connect(mapStateToProps)(ErrorDialog);
