import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-flexbox-grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import configAxios from '../../config/axios';

import styles from './WidgetDownload.css';

class WidgetDownload extends Component {
  state = {
    download: false,
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  getWidget = ({ filename, id }) => {
    const missionId = JSON.parse(localStorage.getItem('mission')).id;

    this.setState({
      download: true,
    });

    configAxios({
      method: 'GET',
      url: `reports/download?id=${id}&missionId=${missionId}`,
      responseType: 'blob',
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        this.setState({
          download: false,
        });
      });
  };

  render() {
    const { download, anchorEl } = this.state;
    const maxSize = this.props.maxWidth ? this.props.maxWidth.width : false;
    const {
      data: {
        name,
        files: { reports },
      },
      width,
    } = this.props.data;
    const maxWidth = maxSize ? +maxSize : +width;
    const open = Boolean(anchorEl);
    

    return (
      <Col md={maxWidth} className={styles.widgetWrap}>
        <div className={styles.widget}>
          <div className={styles.name}>{name}</div>
          {Boolean(reports.length) && (
            <div className={styles.unit}>
              <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={this.handleClick}>
                <SaveRoundedIcon />
              </IconButton>
              <Menu 
                anchorEl={anchorEl} 
                open={open} 
                onClose={this.handleClose} 
                style={{ marginTop: '50px' }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                {reports.map(item => {
                  return (
                    <MenuItem key={item.filename} onClick={() => this.getWidget(item)}>
                      {item.filename}
                    </MenuItem>
                  );
                })}
              </Menu>
              {download && <CircularProgress className={styles.downloadProgress} />}
            </div>
          )}
        </div>
      </Col>
    );
  }
}

WidgetDownload.defaultProps = {
  data: {},
  maxWidth: null,
};

WidgetDownload.propTypes = {
  data: PropTypes.object,
  maxWidth: PropTypes.number,
};

export default WidgetDownload;
