import * as redux from 'redux';
import thunk from 'redux-thunk';
import { reducer as reduxFormReducer } from 'redux-form';

import {
  addTodoReducer,
  blockingContent,
  changePageReducer,
  drillDownScenarioReducer,
  kpiDebugReducer,
  loadTaskReducer,
  local,
  missionReducer,
  pageLoadingStatusReducer,
  scenarioReducer,
  scenariosReducer,
  searchProductReducer,
  setDatePickerReducer,
  setToggleCasesReducer,
  setGridDatePickerReducer,
  showErrorNotAllowedReducer,
  showErrorReducer,
  showNetworkErrorReducer,
  userDataReducer,
  setGroupTabReducer,
  setClearAllFiltersReducer,
  setParentScenarioReducer,
  setOldParentScenarioReducer,
  impersonationReducer
} from './reducers/reducers';

export const configure = (
  initialState = {
    mission: localStorage.hasOwnProperty('mission') ? JSON.parse(localStorage.getItem('mission')) : null,
    scenarios: localStorage.hasOwnProperty('scenarios') ? JSON.parse(localStorage.getItem('scenarios')) : []
  }
) => {
  let reducer = redux.combineReducers({
    impersonation: impersonationReducer,
    form: reduxFormReducer,
    userData: userDataReducer,
    addTodo: addTodoReducer,
    changePage: changePageReducer,
    loadTask: loadTaskReducer,
    scenarios: scenariosReducer,
    errorData: showErrorReducer,
    errorDataNotAllowed: showErrorNotAllowedReducer,
    errorNetworkFailed: showNetworkErrorReducer,
    mission: missionReducer,
    scenario: scenarioReducer,
    drillDownScenario: drillDownScenarioReducer,
    searchProduct: searchProductReducer,
    pageLoadingStatus: pageLoadingStatusReducer,
    language: local,
    blockingContent: blockingContent,
    datePickerDate: setDatePickerReducer,
    gridDatePickerDate: setGridDatePickerReducer,
    kpiDebug: kpiDebugReducer,
    setToggleCases: setToggleCasesReducer,
    setClearAllFilters: setClearAllFiltersReducer,
    setGroupTab: setGroupTabReducer,
    parentScenario: setParentScenarioReducer,
    oldParentScenario: setOldParentScenarioReducer
  });

  return redux.createStore(
    reducer,
    initialState,
    redux.compose(
      redux.applyMiddleware(thunk),
      window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : redux.compose
    )
  );
};
