import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { TextField, IconButton, Grid } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { connect } from 'react-redux';
import styles from './DateRangeColumnFilter.css';

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const DateRangeColumnFilter = props => {
  const { language, value, onChange, onEnter } = props;

  const ref = useRef();

  const translation = useMemo(() => {
    return language.translation ? language.translation.virtualStock : {};
  }, [language]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (ref && ref.current) {
        ref.current.focus();
      }
    }, 100);

    return () => clearTimeout(timeout);
  }, []);

  const handleChange = (event, type) => {
    let [from, to] = value;

    if (type === 'from') {
      from = event.target.value ? event.target.value : null;
    }

    if (type === 'to') {
      to = event.target.value ? event.target.value : null;
    }

    onChange([from, to]);
  };

  const handleClear = type => {
    let [from, to] = value;

    if (type === 'from') {
      from = null;
    }

    if (type === 'to') {
      to = null;
    }

    onChange([from, to]);
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      onEnter();
    }
  };

  const [from, to] = value;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          inputRef={ref}
          id="num-range-from"
          type="date"
          InputProps={{
            classes: {
              root: styles.root,
              input: styles.input
            },
            endAdornment: (from !== null) ? (
              <IconButton size="small" onClick={() => handleClear('from')}>
                <Clear />
              </IconButton>
            ) : ''
          }}
          InputLabelProps={{
            shrink: true,
          }}
          size={'small'}
          className={styles.textField}
          label={translation.rangeFrom}
          variant="outlined"
          value={from !== null ? from : ''}
          onChange={event => handleChange(event, 'from')}
          onKeyDown={handleKeyDown}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          id="num-range-to"
          type="date"
          InputProps={{
            classes: {
              root: styles.root,
              input: styles.input
            },
            endAdornment: (to !== null) ? (
              <IconButton size="small" onClick={() => handleClear('to')}>
                <Clear />
              </IconButton>
            ) : ''
          }}
          InputLabelProps={{
            shrink: true,
          }}
          size={'small'}
          className={styles.textField}
          label={translation.rangeTo}
          variant="outlined"
          value={to !== null ? to : ''}
          onChange={event => handleChange(event, 'to')}
          onKeyDown={handleKeyDown}
        />
      </Grid>
    </Grid>
  );
};

DateRangeColumnFilter.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onEnter: PropTypes.func.isRequired,
  language: PropTypes.object.isRequired
};

export default connect(
  store => {
    return {
      language: store.language
    };
  }
)(DateRangeColumnFilter);