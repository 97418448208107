import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import history from '../config/history';
import GoogleAnalytics from './ga/GoogleAnalytics';
import Guard from './guard/Guard';
import Auth from '../routes/Auth/Auth';
import CheckRestorePass from '../routes/CheckRestorePass/CheckRestorePass';
import AuthWithToken from '../routes/Auth/AuthWithToken/AuthWithToken';
import NetworkErrorDialog from '../components/NetworkErrorDialog';
import Preload from '../components/Preload/Preload';
import { configure } from '../store';
import configAxios from '../config/axios';
import { init } from './ga';
import '../assets/global.css';
import styles from './App.css';

const store = configure();
configAxios.store = store;

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const App = () => <div className={styles.app}>
  <Provider store={store} history={history}>
    <Router>
      {init() && <Route component={GoogleAnalytics} />}
      <Switch>
        <Guard>
          <Route exact path="/" component={Auth} />
          <Route path="/site/reset-password" component={CheckRestorePass} />
          <Route path="/auth" component={AuthWithToken} />
          <Route component={NetworkErrorDialog} />
          <Preload />
        </Guard>
      </Switch>
    </Router>
  </Provider>
</div>;

export default App;
