import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cls from 'classnames';
import { Table as TableToolBox, TableHead, TableRow, TableCell } from 'react-toolbox/lib/table';
import Info from '../../components/tableComponents/Info/Info';
import CarouselPhoto from '../../components/tableComponents/CarouselPhoto/CarouselPhoto';
import TaskDropdown from '../../components/tableComponents/TaskDropdown/TaskDropdown';
import CommentAudit from './Comment/Comment';
import SearchAudit from './Search/Search';

import styles from './TableAudit.css';
import Comment from '../tableComponents/Comment/Comment';

const TableAudit = ({
  shopId,
  items = [],
  headers = {},
  suggest = '',
  language,
  urlApi,
  actionsStatus,
  auditStatus,
  startDate,
  endDate,
  edit = false
}) => {
  const lang = language.translation ? language.translation.directTask : {};

  return (
    <div>
      <TableToolBox selectable={false}>
        <TableHead class={styles.tableHeadRow}>
          {Object.keys(headers).map(key => {
            if (key === 'product_name') {
              return (
                <TableCell key={key} class={styles.tableCell} style={{ minWidth: 150 }}>
                  <SearchAudit
                    urlApi={urlApi}
                    suggest={suggest}
                    shopId={shopId}
                    auditStatus={auditStatus}
                    startDate={startDate}
                    endDate={endDate}
                  />
                </TableCell>
              );
            }
            if (!actionsStatus && (key === 'audit_status_name' || key === 'tree')) {
              return false;
            }
            return (
              <TableCell key={key} class={styles.tableCell}>
                {headers[key]}
              </TableCell>
            );
          })}
        </TableHead>
        {items.map((item, index) => {
          const keyCode = index;
          return (
            <TableRow key={keyCode} class={styles.tableRow}>
              {Object.keys(headers).map(key => {
                if (!actionsStatus && (key === 'audit_status_name' || key === 'tree')) {
                  return false;
                }
                if (key === 'tree') {
                  if (!item[key].length) {
                    return (
                      <TableCell key={key} class={styles.tableCell}>
                        {lang.treeEmpty}
                      </TableCell>
                    );
                  }
                  return (
                    <TableCell key={key} class={styles.tableCell}>
                      <TaskDropdown
                        isAuditTask
                        urlApi={urlApi}
                        data={item[key]}
                        itemId={urlApi === 'assortment-matrix' ? item.grid_item_id : item.item_id}
                        shopId={shopId}
                        caseType={item.type_id}
                      />
                    </TableCell>
                  );
                } else if (key === 'image') {
                  return (
                    <TableCell key={key} class={cls(styles.tableCell, styles.textCenter)}>
                      <CarouselPhoto images={item[key] ? item[key] : []} />
                    </TableCell>
                  );
                } else if (key === 'info') {
                  return (
                    <TableCell key={key} class={cls(styles.tableCell, styles.textCenter)}>
                      <Info urlApi={urlApi} itemId={item.item_id} />
                    </TableCell>
                  );
                } else if (key === 'comment') {
                  return (
                    <TableCell key={key} class={cls(styles.tableCell, styles.textCenter)}>
                      {edit && (
                        <Comment
                          urlApi={urlApi}
                          shopId={shopId}
                          itemId={item.item_id}
                          text={item[key] ? item[key] : ''}
                          caseType={item.type_id}
                        />
                      )}
                      {!edit && item[key] && (
                        <CommentAudit text={item[key]} />
                      )}
                    </TableCell>
                  );
                } else {
                  return (
                    <TableCell key={key} class={styles.tableCell}>
                      {item[key]}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          );
        })}
      </TableToolBox>
    </div>
  );
};

TableAudit.propTypes = {
  urlApi: PropTypes.string.isRequired,
  items: PropTypes.array,
  headers: PropTypes.object,
  suggest: PropTypes.string,
  shopId: PropTypes.number.isRequired,
  auditStatus: PropTypes.number.isRequired,
  language: PropTypes.object.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  actionsStatus: PropTypes.bool,
  edit: PropTypes.bool
};

export default connect(store => {
  return {
    language: store.language
  };
})(TableAudit);
