import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Fab from '@material-ui/core/Fab';
import CircularProgress from '@material-ui/core/CircularProgress';
import Popover from '@material-ui/core/Popover';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import configAxios from '../../../config/axios';
import { updatePriceFormat } from '../../../utils/utils';

import styles from './Info.css';

class Info extends Component {
  state = {
    open: false,
    infoLoading: false,
    openPopover: false,
    infoData: {},
    anchorEl: null
  };

  popoverOpenHandle = (e, itemId) => {
    if (this.state.infoLoading) return;

    this.setState(
      {
        anchorEl: e.currentTarget,
        infoLoading: true
      },
      () => {
        this.loadInfoFromServer(itemId).then(() => {
          this.setState({
            openPopover: true,
            infoLoading: false
          });
        });
      }
    );
  };

  popoverCloseHandle = () => {
    this.setState({
      openPopover: false,
      anchorEl: null
    });
  };

  loadInfoFromServer = itemId => {
    const query = new URLSearchParams(location.search);
    const mid = query.has('mid') ? Number(query.get('mid')) : JSON.parse(localStorage.mission).id;

    const promise = configAxios({
      method: 'POST',
      url: `/${this.props.urlApi}/info`,
      data: {
        missionId: mid,
        itemId
      }
    }).then(response => {
      this.setState({
        infoData: updatePriceFormat(response.data.data)
      });
    });
    return promise;
  };

  handleClick = () => {
    this.setState(state => ({ open: !state.open }));
  };
  renderItems = () => {
    const { infoData } = this.state;

    return Object.keys(infoData).map(key => {
      return typeof infoData[key] !== 'object' ? (
        <ListItem key={key}>
          <ListItemText class={styles.textStyle} primary={key} secondary={infoData[key]} />
        </ListItem>
      ) : (
        <div key={key}>
          <ListItem button onClick={this.handleClick}>
            <ListItemText class={styles.textStyle} primary={key} />
            {this.state.open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={this.state.open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {infoData[key].map(item => (
                <ListItem key={item.id}>
                  <ListItemText
                    class={cls(styles.textStyle, styles.textSubStyle)}
                    primary={item.id}
                    secondary={item.name}
                  />
                </ListItem>
              ))}
            </List>
          </Collapse>
        </div>
      );
    });
  };
  render() {
    const { anchorEl, infoLoading, openPopover } = this.state;
    const { itemId } = this.props;

    return (
      <Fab
        color="primary"
        size="small"
        class={styles.disabledFab}
        disabled={openPopover}
        onClick={event => this.popoverOpenHandle(event, itemId)}
      >
        {infoLoading ? <CircularProgress size={24} thickness={2} class={styles.progressIcon} /> : <InfoOutlinedIcon />}
        <Popover
          open={openPopover}
          anchorEl={anchorEl}
          class={styles.popover}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right'
          }}
          onClose={this.popoverCloseHandle}
        >
          <List component="nav">{this.renderItems()}</List>
        </Popover>
      </Fab>
    );
  }
}

Info.propTypes = {
  urlApi: PropTypes.string.isRequired,
  itemId: PropTypes.number.isRequired
};

export default Info;
