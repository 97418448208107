import React from 'react';
import { Button as MaterialButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import cls from 'classnames';
import PropTypes from 'prop-types';

import styles from './ButtonField.css';

const fieldStyles = {
  outlinedPrimary: {
    color: '#1C71B4',
    whiteSpace: 'nowrap',
    border: '1px solid #d4dff0',
    textTransform: 'none',
    '&:hover': {
      borderColor: `#d4dff0`
    }
  },
  containedPrimary: {
    color: '#ffffff',
    whiteSpace: 'nowrap',
    textTransform: 'none',
    backgroundColor: '#00bcd4',
    '&:hover': {
      backgroundColor: '#71d8e6'
    }
  }
};

const ButtonField = props => {
  const { variantToggle, ...rest } = props;
  const variant = variantToggle ? 'contained' : 'outlined';
  const classNames = cls(styles.button, { [styles.buttonActive]: variantToggle }, rest.className);
  return (
    <MaterialButton {...rest} className={classNames} variant={variant} color="primary">
      {props.children}
    </MaterialButton>
  );
};
ButtonField.propTypes = {
  variantToggle: PropTypes.bool
};
export default withStyles(fieldStyles)(ButtonField);
