import lang from '../../locales';
import { getBrowserPreferredSupportedLanguage } from '../../utils/browser';

export const addTodoReducer = (state = [], action) => {
  switch (action.type) {
    case 'ADD_USER':
      return [...state, action.user];
    default:
      return state;
  }
};

const language = getBrowserPreferredSupportedLanguage();
const defaultLangState = {
  translation: lang[language],
  lang: language
};

export const local = (state = defaultLangState, action) => {
  if (state.lang === action.lang) {
    return state;
  }

  const language = getBrowserPreferredSupportedLanguage(action.lang);
  switch (action.type) {
    case 'INIT_LOCAL':
      return {
        translation: lang[language],
        lang: language
      };
    default:
      return state;
  }
};

export const userDataReducer = (state = {}, action) => {
  switch (action.type) {
    case 'ADD_USERDATA':
      return action.userData;
    default:
      return state;
  }
};

export const changePageReducer = (state = 1, action) => {
  switch (action.type) {
    case 'CHANGE_PAGE':
      return action.page;
    default:
      return state;
  }
};

export const loadTaskReducer = (state = {}, action) => {
  switch (action.type) {
    case 'LOAD_TASK':
      return action;
    default:
      return state;
  }
};

export const missionReducer = (state = [], action) => {
  switch (action.type) {
    case 'CHOOSE_MISSION':
      localStorage.setItem('mission', JSON.stringify(action.mission));
      return action.mission;
    default:
      return state;
  }
};

export const scenarioReducer = (state = {}, action) => {
  switch (action.type) {
    case 'CHOOSE_SCENARIO':
      return action.scenario;
    default:
      return state;
  }
};

export const drillDownScenarioReducer = (state = {}, action) => {
  switch (action.type) {
    case 'CHOOSE_DRILLDOWN_SCENARIO':
      return action.drillDownScenario;
    default:
      return state;
  }
};

export const scenariosReducer = (state = [], action) => {
  switch (action.type) {
    case 'LOAD_SCENARIOS':
      // localStorage.setItem('scenarios', JSON.stringify(action.scenarios));
      return action.scenarios;
    default:
      return state;
  }
};

export const showErrorReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SHOW_ERROR':
      return action;
    default:
      return state;
  }
};

const initKpiState = {
  visible: false,
  data: []
};

export const kpiDebugReducer = (state = initKpiState, action) => {
  switch (action.type) {
    case 'KPI_DEBUG_HANDLE':
      return {
        data: [...state.data, action.payload],
        visible: state.visible
      };
    case 'KPI_DEBUG_SHOW':
      return {
        data: [...state.data],
        visible: action.payload
      };
    default:
      return state;
  }
};

export const showErrorNotAllowedReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SHOW_ERROR_NOT_ALLOWED':
      return action;
    default:
      return state;
  }
};

export const showNetworkErrorReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SHOW_NETWORK_ERROR':
      return action;
    default:
      return state;
  }
};

export const searchProductReducer = (state = null, action) => {
  switch (action.type) {
    case 'SEARCH_PRODUCT':
      return action.id;
    default:
      return state;
  }
};

export const pageLoadingStatusReducer = (state = false, action) => {
  switch (action.type) {
    case 'SET_PAGE_LOADING_STATUS':
      return action.status;
    default:
      return state;
  }
};

export const blockingContent = (state = false, action) => {
  switch (action.type) {
    case 'SET_BLOCKING_CONTENT':
      return action.status;
    default:
      return state;
  }
};

export const setDatePickerReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SET_DATEPICKER_DATE':
      return action.data;
    default:
      return state;
  }
};

export const setGridDatePickerReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SET_GRID_DATEPICKER_DATE':
      return action.data;
    default:
      return state;
  }
};

export const setToggleCasesReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SET_TOGGLE_CASES':
      return action.data;
    default:
      return state;
  }
};

export const setGroupTabReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SET_GROUP_TAB':
      return action.data;
    default:
      return state;
  }
};

export const setClearAllFiltersReducer = (state = false, action) => {
  switch (action.type) {
    case 'SET_CLEAR_FILTERS':
      return action.data;
    default:
      return state;
  }
};

export const setParentScenarioReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SET_PARENT_SCENARIO':
      return action.id;
    default:
      return state;
  }
};

export const setOldParentScenarioReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SET_OLD_PARENT_SCENARIO':
      return action.id;
    default:
      return state;
  }
};


function getImpState() {
  const impersonation = localStorage.getItem('impersonation');
  if (impersonation === 'expired') return 'expired';
  return !!impersonation;
}

export const impersonationReducer = (state = getImpState(), action) => {
  switch (action.type) {
    case 'TOGGLE_IMPERSONATION':
      return action.flag;
    default:
      return state;
  }
};