import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CancelIcon from '@material-ui/icons/Close';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';

import styles from './Panel.css';

class FilterPartPanel extends Component {
  /**
   *
   * @param props
   */
  constructor(props) {
    super(props);
  }

  state = {
    search: '',
    searchToggle: false,
  };

  /**
   *
   */
  componentDidMount() {
    const { suggest } = this.props;
    if (suggest) {
      this.setState({ search: suggest });
    }
  }

  /**
   *
   * @param nextProps
   * @returns {boolean}
   */
  // shouldComponentUpdate(nextProps) {
  //
  // 	return nextProps.name !== this.props.name
  // 			|| nextProps.allColumnsModeAvailable !== this.props.allColumnsModeAvailable
  // 			|| nextProps.allColumnsMode !== this.props.allColumnsMode
  // 			|| nextProps.valuesIsNotEmpty !== this.props.valuesIsNotEmpty
  // 			|| nextProps.contentIsOpened !== this.props.contentIsOpened
  // 			|| nextProps.showSearch !== this.props.showSearch
  // 			|| nextProps.clearIsDisabled !== this.props.clearIsDisabled;
  //
  // }

  /**
   *
   */
  handleAllColumnsModeToggle = () => {
    this.props.allColumnsModeToggle();
  };

  /**
   *
   */
  handleContentToggle = () => {
    const { showSearch, source, suggestFilter, contentToggle, display } = this.props;

    if (!showSearch && source !== 'category') {
      this.setState(
        {
          searchToggle: false,
          search: '',
        },
        () => {
          if (display.length === 0) {
            suggestFilter('');
          }
        }
      );
    }
    contentToggle();
  };

  /**
   *
   */
  handleClearFilter = () => {
    this.props.clearFilter();
  };

  handleSearch = () => {
    const { suggestFilter } = this.props;

    const { search } = this.state;

    suggestFilter(search);
  };

  handleInputSearch = event => {
    this.setState({ search: event.target.value });
  };

  searchItem = () => {
    const { suggestFilter } = this.props;

    const { searchToggle } = this.state;

    this.setState(
      {
        searchToggle: !searchToggle,
        search: '',
      },
      () => {
        if (searchToggle) {
          suggestFilter('');
        }
      }
    );
  };

  /**
   *
   * @returns {XML}
   */
  render() {
    const {
      name,
      allColumnsModeAvailable,
      allColumnsMode,
      valuesIsNotEmpty,
      contentIsOpened,
      clearIsDisabled,
      language,
      showSearch,
      source,
    } = this.props;

    const { search, searchToggle } = this.state;

    const filterPartTranslation = language.translation.scenario.filterPart;
    const isCategories = source !== 'category';
    return (
      <div>
        <div className={[styles.panel, valuesIsNotEmpty ? styles.panelActive : ''].join(' ')}>
          <button className={styles.panelIcon} onClick={() => this.handleContentToggle()}>
            <span className={['material-icons', styles.panelIconWrap].join(' ')}>
              {contentIsOpened ? 'expand_less' : 'expand_more'}
            </span>
          </button>

          <div className={styles.panelName}>
            {!showSearch && isCategories ? (
              <div>
                <div className={styles.panelNameSimple}>
                  <span>{name}</span>
                </div>
                {searchToggle ? (
                  <CancelIcon
                    className={styles.search}
                    onClick={() => {
                      this.searchItem();
                    }}
                  />
                ) : (
                  <SearchIcon
                    className={styles.search}
                    onClick={() => {
                      this.searchItem();
                    }}
                  />
                )}
              </div>
            ) : (
              <div className={styles.panelNameSimple}>
                <span>{name}</span>
              </div>
            )}

            {valuesIsNotEmpty && (
              <button disabled={clearIsDisabled} className={styles.panelButton} onClick={this.handleClearFilter}>
                {filterPartTranslation.clear}
              </button>
            )}
          </div>

          {allColumnsModeAvailable && (contentIsOpened || (!contentIsOpened && valuesIsNotEmpty)) && (
            <button className={styles.panelColumn} onClick={() => this.handleAllColumnsModeToggle()}>
              <span className={['material-icons', styles.panelIconWrap].join(' ')}>
                {allColumnsMode ? 'navigate_before' : 'navigate_next'}
              </span>
            </button>
          )}
          <div className={searchToggle ? styles.searchWrap : styles.searchWrapNone}>
            <InputBase
              className={searchToggle ? styles.searchAreaOn : styles.searchAreaOff}
              value={search}
              onChange={this.handleInputSearch}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  this.handleSearch();
                }
              }}
              placeholder={filterPartTranslation.search}
            />
            <SearchIcon
              className={styles.searchOnWrap}
              onClick={() => {
                this.handleSearch();
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

FilterPartPanel.propTypes = {
  name: PropTypes.string.isRequired,
  allColumnsModeAvailable: PropTypes.bool.isRequired, // eslint-disable-line
  allColumnsMode: PropTypes.bool.isRequired, // eslint-disable-line
  valuesIsNotEmpty: PropTypes.bool.isRequired, // eslint-disable-line
  contentIsOpened: PropTypes.bool.isRequired, // eslint-disable-line
  clearIsDisabled: PropTypes.bool.isRequired, // eslint-disable-line
  allColumnsModeToggle: PropTypes.func.isRequired, // eslint-disable-line
  contentToggle: PropTypes.func.isRequired, // eslint-disable-line
  clearFilter: PropTypes.func.isRequired, // eslint-disable-line
  language: PropTypes.object.isRequired, // eslint-disable-line
  suggestFilter: PropTypes.func.isRequired,
  display: PropTypes.array.isRequired,
  suggest: PropTypes.string, // eslint-disable-line
  // changeView: PropTypes.func.isRequired,
  showSearch: PropTypes.bool,
  source: PropTypes.string,
};

export default connect(store => {
  return {
    language: store.language,
  };
})(FilterPartPanel);
