import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { setPageLoadingStatus, searchProduct } from '../../../store/actions/actions';
import Preload from '../../../constructors/Preload';
import ButtonDefault from '../../Buttons/ButtonDefault';
import { isMobileBrowser } from '../../../utils/browser';
import AlertTaskDropdownDialog from '../AlertTaskDropdownDialog';

import styles from './TaskDropdownMobile.css';

class MobileTaskDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openMenu: false,
      item: {},
      imageFile: null,
      itemId: null,
      anchorEl: null,
      openDialog: false
    };

    this.preload = new Preload();
    this.preload.subscribe(promises => {
      this.props.dispatch(setPageLoadingStatus(promises.length > 0));
    });
  }

  toggleDropdown = () => {
    this.setState({
      dropdownToggle: !this.state.dropdownToggle
    });
  };

  handleOpenMenu = event => {
    this.setState({
      openMenu: true,
      anchorEl: event.currentTarget
    });
  };

  handleOnRequestClose = () => {
    this.setState({
      openMenu: false,
      anchorEl: null
    });
  };

  handleAction = (e, item) => {
    if (item.form === 'img') {
      if (!isMobileBrowser) {
        this.setState({
          openDialog: true
        });
        return false;
      }
      const file = e.target.files;

      if (file && file.length) {
        this.handlePhoto(file, item);
      }
    } else {
      const { dispatch, searchProductStore, setToggleCases, itemId } = this.props;
      this.setState(
        {
          item,
          itemId,
          openMenu: false,
          anchorEl: null
        },
        () => {
          this.loadToServerHandle();
          if (searchProductStore && setToggleCases === true) {
            dispatch(searchProduct(null));
          }
        }
      );
    }
  };

  handlePhoto = (file, item) => {
    this.setState(
      {
        item,
        itemId: this.props.itemId,
        openMenu: false,
        anchorEl: null,
        imageFile: file
      },
      this.loadToServerHandle
    );
  };
  loadToServerHandle() {
    const { shopId, caseType, loadToServer, urlApi } = this.props;
    const { itemId, item, imageFile } = this.state;

    const aestTime = new Date().toLocaleString("en-US", { timeZone: 'Atlantic/Reykjavik' });
    const timeForUpdate = new Date(aestTime).toISOString();
    const isAutoOrder = urlApi === "auto-order";

    const query = new URLSearchParams(location.search);
    const mid = query.has('mid') ? Number(query.get('mid')) : JSON.parse(localStorage.mission).id;

    let formData = new FormData();
    let formDataAutoOreder = {
      missionId: parseInt(mid),
      shopId: shopId,
      status: item.actionTypeTo,
      actionDateTime: moment(timeForUpdate).format("YYYY-MM-DD HH:mm:ss")
    };

    formData.append('itemId', itemId);
    formData.append('actionTypeTo', item.actionTypeTo);
    formData.append('actionTypeFrom', item.actionTypeFrom);
    formData.append('missionId', mid);
    formData.append('shopId', shopId);
    formData.append('caseType', caseType);
    formData.append('actionDateTime', moment(timeForUpdate).format("YYYY-MM-DD HH:mm:ss"));

    item.previous ? formData.append('previous', 1) : null;

    if (imageFile && imageFile.length) {
      for (let i = 0; i < imageFile.length; i++) {
        formData.append('imageFiles[' + i + ']', imageFile[i]);
      }
    }
    loadToServer(isAutoOrder ? formDataAutoOreder : formData);

    this.setState({
      imageFile: null
    });
  }
  handleClose = () => {
    this.setState({
      openDialog: false
    });
  };

  render() {
    const {
      data = [],
      language: {
        translation: { directTask: lang, warnings }
      },
      urlApi
    } = this.props;
    const { openMenu, anchorEl, openDialog } = this.state;
    const dataList = urlApi === "auto-order" ? [data] : data;

    return (
      <div>
        <ButtonDefault fullWidth onClick={this.handleOpenMenu} disabled={dataList.length === 0}>
          {lang.actions}
        </ButtonDefault>
        {dataList.length > 0 && (
          <Menu anchorEl={anchorEl} open={openMenu} onClose={this.handleOnRequestClose}>
            {dataList.map(item => (
              <MenuItem key={item.name} className={styles.menuItem} onClick={e => this.handleAction(e, item)}>
                {item.name}
                {item.form === 'img' && (
                  <input
                    type="file"
                    className={styles.imageInput}
                    onClick={e => {
                      if (!isMobileBrowser) {
                        e.preventDefault();
                      }
                    }}
                    onChange={e => this.handleAction(e, item)}
                    multiple
                    accept="image/*"
                    capture="camera"
                  />
                )}
              </MenuItem>
            ))}
          </Menu>
        )}
        <AlertTaskDropdownDialog open={openDialog} handleClose={this.handleClose} translations={warnings} />
      </div>
    );
  }
}

MobileTaskDropdown.defaultProps = {
  itemId: null,
  searchProductStore: null,
  setToggleCases: {}
};

MobileTaskDropdown.propTypes = {
  data: PropTypes.array,
  dispatch: PropTypes.func.isRequired,
  loadToServer: PropTypes.func.isRequired,
  itemId: PropTypes.number.isRequired,
  shopId: PropTypes.number.isRequired,
  language: PropTypes.object.isRequired,
  searchProductStore: PropTypes.number,
  setToggleCases: PropTypes.object.isRequired,
  caseType: PropTypes.number.isRequired,
  urlApi: PropTypes.string.isRequired
};

export default connect(store => {
  return {
    language: store.language,
    searchProductStore: store.searchProduct,
    setToggleCases: store.setToggleCases
  };
})(MobileTaskDropdown);
