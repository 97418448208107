import PropTypes from 'prop-types';
import axios from 'axios';

import {
  showError,
  showErrorNotAllowed,
  kpiDebugHandle,
  initLocal,
  setBlockingContent,
  setPageLoadingStatus,
  showNetworkError,
  toggleImpersonation
} from '../store/actions/actions';
import history from './history';

const localToken = localStorage.userData ? JSON.parse(localStorage.userData).access_token : null;

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization: 'Bearer ' + localToken,
  },
  validateStatus: function(status) {
    if (status === 401) {
      localStorage.clear();
      history.push('/');
    }
    return status >= 200 && status < 300;
  },
  timeout: 300000
});

axiosInstance.interceptors.request.use(
  function (config) {
    const impersonation = localStorage.impersonation ? localStorage.impersonation : null;
    if (impersonation) {
      config.headers.Impersonation = impersonation;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  response => {
    axiosInstance.store.dispatch(initLocal(response.headers['content-language']));
    axiosInstance.store.dispatch(setBlockingContent(false));

    if (response && response.data.debug && response.data.debug.length > 0) {
      const date = new Date();
      const data = {
        timestamp: +date,
        date: date.toLocaleTimeString(),
        debug: response.data.debug,
        url: response.config.url,
        status: response.status
      };
      axiosInstance.store.dispatch(kpiDebugHandle(data));
    }
    return response;
  },
  error => {
    if (!axios.isCancel(error)) {
      const response = error.response;
      let errorMessage = '';

      if (response && response.data.debug && response.data.debug.length > 0) {
        const date = new Date();
        const data = {
          timestamp: +date,
          date: date.toLocaleTimeString(),
          debug: response.data.debug,
          url: response.config.url,
          status: response.status
        };
        axiosInstance.store.dispatch(kpiDebugHandle(data));
      }

      if (response && (response.status < 200 || response.status >= 300) && response.data.hasOwnProperty('message')) {
        errorMessage = response.data.message;
      } else {
        errorMessage = error.message;
      }
      if (errorMessage && response && response.status !== 422 && response.status !== 403 && response.status !== 405) {
        console.log('Server Error: ', errorMessage);
        axiosInstance.store.dispatch(showError(true, errorMessage));
      }

      if (errorMessage && response && (response.status === 405 || response.status === 403)) {
        axiosInstance.store.dispatch(showErrorNotAllowed(true, errorMessage));
        axiosInstance.store.dispatch(setBlockingContent(true));
        if(response.headers['impersonation'] === 'expired'){
          axiosInstance.store.dispatch(toggleImpersonation('expired'));
          localStorage.setItem('impersonation', 'expired');
        }
      }

      if (error.code && error.code === 'ECONNABORTED') {
        axiosInstance.store.dispatch(setPageLoadingStatus(false));
        axiosInstance.store.dispatch(setBlockingContent(false));
        axiosInstance.store.dispatch(showNetworkError(true));
      }

      axiosInstance.store.dispatch(setPageLoadingStatus(false));

      connectionWatcher(error);
    }
    return Promise.reject(error);
  }
);

function connectionWatcher() {
  let status = navigator.onLine;

  let connectionStatus;
  let i = 0;

  function stopIntervalWatcher() {
    clearInterval(connectionStatus);
  }

  if (!status) {
    setTimeout(() => {
      axiosInstance.store.dispatch(setPageLoadingStatus(true));
      axiosInstance.store.dispatch(setBlockingContent(false));
    }, 1);
    connectionStatus = setInterval(connectionInterval, 1500);
  }

  function connectionInterval() {
    i++;
    status = navigator.onLine;
    if (status) {
      axiosInstance.store.dispatch(setPageLoadingStatus(false));
      axiosInstance.store.dispatch(setBlockingContent(false));
      axiosInstance.store.dispatch(showNetworkError(false));
      stopIntervalWatcher();
      location.reload();
    } else {
      if (i > 2) {
        axiosInstance.store.dispatch(setPageLoadingStatus(false));
        axiosInstance.store.dispatch(setBlockingContent(false));
        axiosInstance.store.dispatch(showNetworkError(true));
        stopIntervalWatcher();
      }
    }
  }
}

// Функция для повторного запроса, если есть такая возможность
// function retryFailedRequest(err) {
// 	err.config.__isRetryRequest = true;
// 	axiosInstance.store.dispatch(setPageLoadingStatus(false));
// 	axiosInstance.store.dispatch(setBlockingContent(false));
// 	axiosInstance.store.dispatch(showNetworkError(false));
// 	return axiosInstance(err.config);
// }

axiosInstance.propTypes = {
  tokenStore: PropTypes.string
};

axiosInstance.defaultProps = {
  tokenStore: ''
};

export default axiosInstance;
