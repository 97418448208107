import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import { IconButton, TextField } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import cls from 'classnames';
import { connect } from 'react-redux';
import styles from './SelectColumnFilter.css';

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SelectColumnFilter = props => {
  const { language, value, items, onChange } = props;

  const ref = useRef();

  const translation = useMemo(() => {
    return language.translation ? language.translation.virtualStock : {};
  }, [language]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (ref && ref.current) {
        ref.current.focus();
      }
    }, 100);

    return () => clearTimeout(timeout);
  }, []);

  const handleChange = event => {
    onChange(event.target.value);
  };

  const handleClear = () => {
    onChange(null);
  };

  return (
    <div>
      <TextField
        fullWidth
        autoFocus
        select
        ref={ref}
        id="select"
        SelectProps={{
          classes: {
            select: cls(value !== null && styles.selectFilled),
            iconOutlined: styles.iconOutlined
          },
          endAdornment: (value !== null) ? (
            <IconButton size="small" onClick={handleClear}>
              <Clear />
            </IconButton>
          ) : ''
        }}
        value={value !== null ? value : ''}
        label={translation.chooseValue}
        variant="outlined"
        size="small"
        className={styles.select}
        onChange={handleChange}
      >
        {items.map(item => (
          <MenuItem key={item} value={item}>{item}</MenuItem>
        ))}
      </TextField>
    </div>
  );
};

SelectColumnFilter.propTypes = {
  value: PropTypes.string,
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  language: PropTypes.object.isRequired
};

export default connect(
  store => {
    return {
      language: store.language
    };
  }
)(SelectColumnFilter);