import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Minimize from '@material-ui/icons/Maximize';
import Close from '@material-ui/icons/Close';
import HideFilters from './FiltersSvg/FiltersSvg';
import { clearAllFilters } from '../../store/actions/actions';
import styles from './ActiveFilters.css';
import { isSafariBrowser } from '../../utils/browser';

class ActiveFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: screen.width > 813, dateStart: '', dateEnd: ''
    };
  }

  componentDidMount() {
    const { defaultDate, filters } = this.props;

    if (filters.date_end && filters.date_start) {
      if (isSafariBrowser) {
        this.setState({
          dateStart: moment(new Date(filters.date_start.replace(/-/g, '/'))).format('DD.MM.YYYY'),
          dateEnd: moment(new Date(filters.date_end.replace(/-/g, '/'))).format('DD.MM.YYYY')
        });
      } else {
        this.setState({
          dateStart: moment(filters.date_start).format('DD.MM.YYYY'),
          dateEnd: moment(filters.date_end).format('DD.MM.YYYY')
        });
      }
    } else {
      this.setState({
        dateStart: defaultDate.data ? this.replaceDate(defaultDate.data.startDate) : '',
        dateEnd: defaultDate.data ? this.replaceDate(defaultDate.data.endDate) : ''
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { dispatch, clearFilters, defaultDate } = this.props;

    let checked = false;

    if (!nextProps.filters.date_end) {
      this.setState({
        dateStart: defaultDate.data ? this.replaceDate(defaultDate.data.startDate) : '',
        dateEnd: defaultDate.data ? this.replaceDate(defaultDate.data.endDate) : ''
      });
    } else if (nextProps.filters.date_end) {
      if (isSafariBrowser) {
        this.setState({
          dateStart: moment(new Date(nextProps.filters.date_start.replace(/-/g, '/'))).format('DD.MM.YYYY'),
          dateEnd: moment(new Date(nextProps.filters.date_end.replace(/-/g, '/'))).format('DD.MM.YYYY')
        });
      } else {
        this.setState({
          dateStart: moment(nextProps.filters.date_start).format('DD.MM.YYYY'),
          dateEnd: moment(nextProps.filters.date_end).format('DD.MM.YYYY')
        });
      }
    }

    Object.values(nextProps.defaultFilters).map(value => {
      if (value.items && value.items.length) {
        checked = true;
      }
    });

    if (checked) {
      dispatch(clearAllFilters('purge'));
    } else if (clearFilters && clearFilters !== 'purge') {
      dispatch(clearAllFilters(false));
    } else {
      dispatch(clearAllFilters(false));
    }

    if (!this.state.dateStart && nextProps.defaultDate.data) {
      this.setState({
        dateStart: this.replaceDate(nextProps.defaultDate.data.startDate),
        dateEnd: this.replaceDate(nextProps.defaultDate.data.endDate)
      });
    }
  }

  hideCase = () => {
    const { toggle } = this.state;
    this.setState({
      toggle: !toggle
    });
  };

  clearAllFilters = () => {
    const { dispatch, clearFilters } = this.props;

    const ableToPurge = clearFilters === 'purge';

    if (ableToPurge) {
      dispatch(clearAllFilters(true));
    }
  };

  replaceDate = date => {
    return date.replace(/(\d+)-(\d+)-(\d+)/, '$3.$2.$1');
  };

  render() {
    const { defaultFilters, clearFilters, isRedash } = this.props;
    const { dateStart, dateEnd } = this.state;
    const { toggle } = this.state;

    const ableToPurge = clearFilters === 'purge';

    return !isRedash && (<div>
        <span className={styles.FiltersWrap}>
          <button
            className={ableToPurge ? styles.SvgWrapActive : styles.SvgWrap}
            onClick={this.clearAllFilters}
            type={'button'}
          >
            {ableToPurge ? <Close className={styles.filterClear} /> : <HideFilters icon={'filter'} />}
          </button>
          <Minimize className={styles.buttonHide} onClick={this.hideCase} />
          {toggle === true ? (<div>
              <div className={styles.openWrapper}>
                {dateStart} - {dateEnd}
              </div>
              {defaultFilters && Object.values(defaultFilters).map((item, keys) => {
                if (item.items) {
                  return (<div key={parseInt(keys.toString())} className={styles.categoryWrap}>
                      {item.items.length !== 0 && <div className={styles.categoriesFilters}>{item.name}</div>}
                      {<div className={styles.checkboxesFilters}>
                        {item.items.map(i => {
                          return (<div key={i} className={styles.checkboxesFilters}>
                              {i}
                            </div>);
                        })}
                      </div>}
                    </div>);
                }
              })}
            </div>) : (<div className={styles.hideWrapper} />)}
        </span>
      </div>);
  }
}

ActiveFilters.defaultProps = {
  defaultDate: {}, defaultFilters: []
};

ActiveFilters.propTypes = {
  dispatch: PropTypes.func.isRequired, // eslint-disable-line
  defaultDate: PropTypes.object, // eslint-disable-line
  setActualDate: PropTypes.object, // eslint-disable-line
  defaultFilters: PropTypes.oneOfType([PropTypes.array, PropTypes.object]), clearFilters: PropTypes.bool.isRequired, // eslint-disable-line
  filters: PropTypes.object, // eslint-disable-line
  isRedash: PropTypes.bool.isRequired // eslint-disable-line
};

export default connect(store => {
  return {
    setActualDate: store.setActualDate, clearFilters: store.setClearAllFilters
  };
})(ActiveFilters);
