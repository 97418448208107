export default class VirtualStock {

  constructor(
    case_id,
    plu_original,
    plu,
    ass_type,
    stock,
    stock_sum,
    product_turnover,
    days_without_sales,
    last_sales_date,
    last_sales_volume,
    last_shipment_date,
    last_shipment_volume,
    promo_start,
    promo_end,
    is_promo,
    vs_case_id,
    is_vs_case,
    title,
    category_name3,
    category_name4
  ) {
    this.case_id = case_id; // +
    this.plu_original = plu_original; // +
    this.plu = plu; // +
    this.ass_type = ass_type; // +
    this.stock = stock; // +
    this.stock_sum = stock_sum; // +
    this.product_turnover = product_turnover; // +
    this.days_without_sales = days_without_sales; // +
    this.last_sales_date = last_sales_date; // +
    this.last_sales_volume = last_sales_volume; // +
    this.last_shipment_date = last_shipment_date; // +
    this.last_shipment_volume = last_shipment_volume; // +
    this.promo_start = promo_start; // +
    this.promo_end = promo_end; // +
    this.is_promo = is_promo; // +
    this.vs_case_id = vs_case_id; // +
    this.is_vs_case = is_vs_case; // +
    this.title = title; // +
    this.category_name3 = category_name3; // +
    this.category_name4 = category_name4; // +
  }

  /**
   *
   * @returns {*[]}
   */
  get promo_period() {
    return [this.promo_start, this.promo_end];
  }

  /**
   *
   * @returns {(string|string)[]}
   */
  getProps() {
    return [...Object.keys(this), /* and getters -> */ 'promo_period'];
  }

  /**
   *
   * @returns {boolean}
   */
  hasChildCase() {
    return !!this.vs_case_id || this.is_vs_case;
  }

  setChildCase(caseId) {
    this.vs_case_id = caseId;
    this.is_vs_case = true;
  }
}