import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Media from 'react-media';
import moment from 'moment';
import { SortingState, IntegratedSorting } from '@devexpress/dx-react-grid';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { Grid, Table, TableHeaderRow, TableColumnResizing } from '@devexpress/dx-react-grid-material-ui';
import ExportDataComponent from '../ExportDataComponent';

import IsTimeTarget from '../TimeTarget';
import UiElement from '../UiElement/UiElement';
import styles from './ScenarioTable.css';

class ScenarioTable extends UiElement {
  constructor(props) {
    super(props);
    this.paperRef = React.createRef();
  }

  state = {
    headers: [],
    rows: [],
    defaultColumnWidths: [],
    direction: '',
    actualColumn: ''
  };

  componentDidMount() {
    this.tableHeadPrepare(this.props.data);
  }

  componentWillReceiveProps(nextProps) {
    const nextData = nextProps.data;
    const prevData = this.props.data;

    if (nextProps.updatesLocked) {
      this.tableHeadPrepare(prevData, nextProps.updatesLocked, this.state.direction);
    } else {
      this.setState(
        {
          headers: [],
          rows: [],
          defaultColumnWidths: []
        },
        () => {
          this.tableHeadPrepare(nextData);
          if (prevData === nextData && !nextProps.updatesLocked) {
            this.setState({
              direction: '',
              actualColumn: ''
            });
          }
        }
      );
    }
  }

  tableHeadPrepare = (tableData, updatesLocked, dataForSort = '') => {
    const {
      language: { translation: { targets = {} } = {} },
      transition
    } = this.props;

    const { data: { header: data, target = 'nothing', verticalDirection, rows } = {} } = tableData;

    const { dir = '', column = '' } = dataForSort;

    let header = [];
    let defaultColumnWidths = [];

    const hasId = tableData.data['sub-scenario'] || 0;
    const dataForAction = {
      id: hasId,
      path: hasId ? 'drill' : 'transition',
      target,
      transition
    };
    let sortVerticalHeader;
    let headerData;

    if (dir && !updatesLocked) {
      const customRows = rows.filter(item => item[target].title === column);
      let buff = [];
      let sort;
      let newSortedHeader = [];

      sort = Object.keys(customRows[0]).sort((a, b) => {
        return dir === 'asc' ? customRows[0][a] - customRows[0][b] : customRows[0][b] - customRows[0][a];
      });

      sort.forEach(item => {
        if (item !== target) {
          buff.push(item);
        }
      });
      buff.unshift(target);

      buff.forEach(items => {
        newSortedHeader.push(data.find(item2 => +items === item2.id || items === item2.title));
      });

      sortVerticalHeader = newSortedHeader;
    }

    if (sortVerticalHeader) {
      headerData = sortVerticalHeader;
    } else {
      headerData = data;
    }

    headerData.map(item => {
      item.name = item.id ? item.id.toString() : target;
      let isFirstColumn = item.title === target;
      const firstTitle = item.title === target ? targets[target] : item.title;
      if ((verticalDirection && hasId) || (transition && verticalDirection)) {
        const subData = {
          id: item.id,
          name: item.title
        };
        const actionData = headerData.length > 1 ? this.drillOrTransition(dataForAction, subData) : null;

        header.push({
          name: item.name,
          title: isFirstColumn ? firstTitle : actionData.name,
          action: isFirstColumn ? null : actionData,
          path: dataForAction.path,
          updatesLocked: updatesLocked
        });
      } else if (!verticalDirection || (!hasId && !transition && verticalDirection)) {
        header.push({
          name: item.name,
          title: item.title ? firstTitle : ''
        });
      }

      defaultColumnWidths.push({
        columnName: item.name,
        width: isFirstColumn ? 150 : Math.floor((this.paperRef.current.offsetWidth - 150) / headerData.length)
      });
    });

    if (dataForSort && updatesLocked) {
      header = this.state.headers;
    }

    this.setState(
      {
        headers: header,
        defaultColumnWidths: defaultColumnWidths
      },
      () => {
        this.tableBodyPrepare(tableData, dataForAction);
      }
    );
  };

  tableBodyPrepare = ({ data }, dataForAction) => {
    const { data: { data: { target = 'nothing', verticalDirection } = {} } = {}, updatesLocked } = this.props;

    const { direction, rows: prevRows } = this.state;

    const isTime = IsTimeTarget(target);
    let rows = [];

    data.rows.map(item => {
      let row = {};
      Object.entries(item).map(value => {
        const isTargetColumn = value[0] === target;

        if (value[1] === null) {
          row[value[0]] = '0';
        } else if (typeof value[1] !== 'object' && !isTargetColumn) {
          row[value[0]] = value[1].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        } else if (isTime && isTargetColumn && !dataForAction.id && !verticalDirection) {
          const time = value[1].name ? value[1].name : value[1];
          row[value[0]] = this.dateNamesTransformHandle(target, [time]).toString();
        } else {
          row[value[0]] =
            typeof value[1] === 'object'
              ? value[1].title
                ? { title: value[1].title, inc: value[1].inc }
                : value[1].name.toString()
              : value[1].toString();
        }

        if ((dataForAction.id > 0 || dataForAction.transition) && isTargetColumn && !verticalDirection) {
          const subData = {
            id: value[1].id,
            name: value[1].name
          };
          row[value[0]] = this.drillOrTransition(dataForAction, subData);
        }
      });
      rows.push(row);
    });

    if (direction && updatesLocked) {
      prevRows.map(item => {
        typeof item[target] === 'object' ? (item[target].updatesLocked = updatesLocked) : '';
      });
      rows = prevRows;
    }
    if (!updatesLocked && !verticalDirection) {
      this.setState({
        direction: '',
        actualColumn: ''
      });
    }

    this.setState({
      rows: rows
    });
  };

  excludeNbsp = item => {
    if (typeof item === 'object') {
      return item.name;
    }
    if (!isNaN(+item.replace(/\s+/g, ''))) {
      const number = parseFloat(item.replace(/\s+/g, '')) * 100 + 1000000;
      return number.toString();
    }
    return item;
  };

  sort = (key, dateSort, dir) => {
    const direction = dir === 'asc';

    return [].concat(this.state.rows).sort((a, b) => {
      const isObject = typeof a[key] === 'object';
      if (dateSort) {
        let date1 = isObject ? new Date(a[key].id) : new Date(a[key].replace(/(\d{2}).(\d{2}).(\d{4})/, '$3/$2/$1'));
        let date2 = isObject ? new Date(b[key].id) : new Date(b[key].replace(/(\d{2}).(\d{2}).(\d{4})/, '$3/$2/$1'));
        if (direction) {
          return date1 > date2 ? -1 : date1 < date2 ? 1 : 0;
        } else {
          return date1 > date2 ? 1 : date1 < date2 ? -1 : 0;
        }
      } else {
        let compareA = isObject ? a[key].name : this.excludeNbsp(a[key]);
        let compareB = isObject ? b[key].name : this.excludeNbsp(b[key]);
        if (direction) {
          return compareA.localeCompare(compareB, 'en-US', { numeric: 'true' });
        } else {
          return compareB.localeCompare(compareA, 'en-US', { numeric: 'true' });
        }
      }
    });
  };

  sortBy = (dir, key, dateSort) => {
    if (!this.props.updatesLocked) {
      this.setState({
        direction: dir,
        rows: this.sort(key, dateSort, dir),
        actualColumn: key
      });
    }
  };

  verticalSortBy = data => {
    const { updatesLocked, data: dataForTable } = this.props;

    if (!updatesLocked) {
      this.setState(
        {
          direction: data.dir,
          actualColumn: data.column
        },
        () => {
          this.tableHeadPrepare(dataForTable, updatesLocked, data);
        }
      );
    }
  };

  dateNamesTransformHandle = (target, data) => {
    const {
      translation: { chart }
    } = this.props.language;

    switch (target) {
      case 'day':
        return data.map(item =>
          item
            .split('-')
            .reverse()
            .join('.')
        );
      case 'week':
        return data.map(item => {
          const targetDate = moment(item).startOf('isoWeek');
          return `${targetDate.week()} ${chart.week} ${targetDate.year()}`;
        });
      case 'month':
        return data.map(item => {
          const newItem = item.split('-').reverse();
          newItem.shift();
          return newItem.join('.');
        });
      case 'quarter':
        return data.map(item => {
          const newItem = item.split('-').reverse();
          const quarter = Math.ceil(+newItem[1] / 3);
          return `${quarter} ${chart.quarter} ${newItem[2]}`;
        });
      case 'year':
        return data.map(item => item.split('-')[0]);
      default:
        return data;
    }
  };

  drillOrTransition = (scenarioCase, data) => {
    const { id, path, transition, target } = scenarioCase;

    const { datePickerDate, transitionIsActive, updatesLocked } = this.props;

    const item = this.dateNamesTransformHandle(target, [data.name]).toString();

    if (path === 'drill') {
      return {
        name: item,
        id: data.id,
        updatesLocked: updatesLocked,
        action: () => {
          const filter = this.prepareFilter(target, data);
          this.getSubScenarioData(id, filter, target);
        }
      };
    } else if (path === 'transition' && Object.keys(transition).length) {
      return {
        name: item,
        id: data.id,
        updatesLocked: updatesLocked,
        action: () => {
          const filter = this.prepareFilter(target, data, transition, datePickerDate);
          this.groupTransition(filter, target);
          transitionIsActive(true);
        },
        transition: Object.keys(transition).length
      };
    } else {
      return item;
    }
  };

  changeColumnWidths = columnWidths => {
    const { defaultColumnWidths, headers } = this.state;
    const tableWidth = this.paperRef.current.offsetWidth - 150;
    let targetWidth = 0;

    defaultColumnWidths.map((item, key) => {
      if (item.width !== columnWidths[key].width) {
        targetWidth = columnWidths[key].width;
      }
    });

    columnWidths.map((item, key) => {
      if (item.width === defaultColumnWidths[key].width) {
        item.width = (tableWidth - targetWidth) / (headers.length - 1);
      }
    });

    this.setState({ defaultColumnWidths: columnWidths });
  };

  render() {
    const { headers, rows, defaultColumnWidths, actualColumn, direction } = this.state;
    const {
      data,
      language: { translation = '' }
    } = this.props;
    const { name, target, verticalDirection } = data.data;

    return (
      <div className={styles.wrap} ref={this.paperRef}>
        <ExportDataComponent data={data} translation={translation} />
        <h3 className={styles.tableName}>{name}</h3>
        <div className={styles.table}>
          <Grid rows={rows} columns={headers}>
            <SortingState />
            <IntegratedSorting />
            <Table
              cellComponent={params =>
                Cell(params, target, verticalDirection, this.verticalSortBy, direction, actualColumn)
              }
            />
            {defaultColumnWidths.length && (
              <Media
                query="(min-width: 280px)"
                render={() => (
                  <TableColumnResizing
                    onColumnWidthsChange={this.changeColumnWidths}
                    defaultColumnWidths={defaultColumnWidths}
                  />
                )}
              />
            )}
            {verticalDirection ? (
              <TableHeaderRow showSortingControls contentComponent={HeaderRow} />
            ) : (
              <TableHeaderRow
                showSortingControls
                sortLabelComponent={params => {
                  return ArrowDirectionComponent(params, this.sortBy, direction, actualColumn, headers);
                }}
              />
            )}
          </Grid>
        </div>
      </div>
    );
  }
}

const ArrowDirectionComponent = ({ children, column, ...restProps }, sortBy, direction, actualColumn, headers) => {
  const dateSort = headers[0] === column && IsTimeTarget(headers[0].name);
  const isColumn = column.name === actualColumn;
  return (
    <span {...restProps} className={styles.arrowContainer}>
      <div className={styles.textHeaderWrap}>{children}</div>
      <div className={styles.arrows}>
        <ArrowDropUp
          className={isColumn && direction === 'asc' ? styles[`${direction}Up`] : ''}
          onClick={() => {
            sortBy('asc', column.name, dateSort);
          }}
          color="disabled"
        />
        <ArrowDropDown
          className={isColumn && direction === 'desc' ? styles[`${direction}Down`] : ''}
          onClick={() => {
            sortBy('desc', column.name, dateSort);
          }}
          color="disabled"
        />
      </div>
    </span>
  );
};

const Cell = (data, target, verticalDirection, verticalSortBy, direction, actualColumn) => {
  const {
    value,
    row,
    column: { name = '' }
  } = data;

  const valueName = typeof row[name] === 'object' ? row[name].name || row[name].title : row[name];
  const isNumber = typeof value === 'string' ? isNaN(+value.replace(/\s+/g, '')) : true;
  const checked = !value.updatesLocked && !verticalDirection;
  let isVertical = verticalDirection && row[target].inc;
  let forVerticalSort = verticalDirection && data.column.name === target;
  const isActualColumn = data.value.title === actualColumn;
  const acsSort = isActualColumn && direction === 'asc';
  const descSort = isActualColumn && direction === 'desc';
  let cellStyle;

  if (name.indexOf('inc') !== -1 && +value !== 0) {
    cellStyle = parseFloat(value.replace(/\s+/g, '')) > 0 ? styles.incPlus : styles.incMinus;
  } else if (typeof row[name] === 'string' && isVertical && !isNumber && +value !== 0) {
    cellStyle = parseFloat(value.replace(/\s+/g, '')) > 0 ? styles.incPlus : styles.incMinus;
  } else if (typeof value === 'object' && !value.transition && checked) {
    cellStyle = styles.ableToDrill;
  } else if (value.transition && checked) {
    cellStyle = styles.linkTranstion;
  }

  return (
    <Table.Cell>
      <span
        className={`${styles.defaultCell} ${cellStyle}`}
        onClick={() => (!value.updatesLocked && value.action()) || null}
        role="presentation"
      >
        {valueName}
      </span>
      {forVerticalSort && (
        <span className={styles.arrowContainer}>
          <ArrowLeftIcon
            className={acsSort ? styles[`${direction}Up`] : ''}
            color="disabled"
            onClick={() => {
              verticalSortBy({ dir: 'asc', column: data.row[target].title });
            }}
          />
          <ArrowRightIcon
            className={descSort ? styles[`${direction}Down`] : ''}
            color="disabled"
            onClick={() => {
              verticalSortBy({ dir: 'desc', column: data.row[target].title });
            }}
          />
        </span>
      )}
    </Table.Cell>
  );
};

const HeaderRow = data => {
  const { title, action: dataAction, path, updatesLocked = '' } = data.column;

  const ableToAction = !updatesLocked && dataAction;
  let cellStyle;

  if (ableToAction && path === 'drill') {
    cellStyle = styles.ableToDrill;
  } else if (ableToAction && path === 'transition') {
    cellStyle = styles.linkTranstion;
  }

  return (
    <span
      className={`${styles.defaultCell} ${cellStyle}`}
      onClick={() => (ableToAction ? dataAction.action() : null)}
      role="presentation"
    >
      {title}
    </span>
  );
};

ArrowDirectionComponent.propTypes = {
  column: PropTypes.object.isRequired
};

ScenarioTable.defaultProps = {
  data: {},
  updatesLocked: false
};

ScenarioTable.propTypes = {
  data: PropTypes.object.isRequired, // eslint-disable-line
  classes: PropTypes.object, // eslint-disable-line
  language: PropTypes.object.isRequired, // eslint-disable-line
  updatesLocked: PropTypes.bool, // eslint-disable-line
  filters: PropTypes.func.isRequired, // eslint-disable-line
  updateFilters: PropTypes.func.isRequired, // eslint-disable-line
  setDrillFilters: PropTypes.func.isRequired, // eslint-disable-line
  transition: PropTypes.object.isRequired, // eslint-disable-line
  transitionIsActive: PropTypes.func.isRequired, // eslint-disable-line
  datePickerDate: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  scenario: PropTypes.object.isRequired // eslint-disable-line react/forbid-prop-types
};

const mapStateToProps = store => ({
  scenario: store.scenario,
  language: store.language,
  parentScenario: store.parentScenario
});

export default connect(mapStateToProps)(ScenarioTable);
