import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MDSpinner from 'react-md-spinner';
import { connect } from 'react-redux';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import configAxios from '../../config/axios';
import FilterPartPanel from './Panel';
import FilterPartHead from './Head';
import FilterPartBody from './Body';
import FilterPartDisplay from './Display';

import styles from './FilterPart.css';

class FilterPart extends Component {
  /**
   *
   * @type {number}
   */
  static UPDATE_DELAY = 1000;

  /**
   *
   * @type {number}
   */
  static DEFAULT_WIDTH = 222;

  /**
   *
   * @type {number}
   */
  static COLUMN_DEFAULT_WIDTH = 66;

  /**
   *
   * @type {string}
   */
  static PROGRESS_COLOR = '#00A5E6';

  /**
   *
   * @param data
   * @param kpi
   * @returns {Array}
   */
  static getKPINames(data, kpi) {
    if (data.length === 0) {
      return [];
    }

    const length = kpi.length;
    const firstItem = data[0];
    const list = [];

    for (let i = 0; i < length; i++) {
      const kpiItem = firstItem[kpi[i].source];
      list[i] = kpiItem && kpiItem.hasOwnProperty('name') ? kpiItem.name : '-';
    }

    return list;
  }

  /**
   *
   * @param data
   * @param kpi
   * @returns {Array}
   */
  static getMetricsNames(data, kpi) {
    if (!data || data.length === 0) {
      return [];
    }

    const length = kpi.length;
    const list = [];

    for (let i = 0; i < length; i++) {
      list[i] = data[i].name;
    }

    return list;
  }

  /**
   *
   * @param props
   */
  constructor(props) {
    super(props);

    this.disposables = [];
    this.updateTimeout = null;

    this.state = {
      level: 1,
      kpi: [],
      kpiNames: [],
      items: [],
      parents: [],
      selectedItemsParents: [],
      display: [],
      singleLevel: false,
      loaded: false,
      loading: false,
      updating: false,
      contentIsOpened: screen.width > 813,
      allColumnsMode: false,
      loadMoreFilters: true,
      defaultFiltersBox: [],
      suggest: '',
      showSearch: false,

    };
  }

  /**
   *
   */
  componentDidMount() {
    const source = this.props.data.data.options.entity.source;

    this.props.updateFiltersState(true, source);

    this.loadLevel().then(
      () => {
        this.props.updateFiltersState(false, source);
      },
      () => {
        this.props.updateFiltersState(false, source);
      }
    );
    this.props.updateDisplay(this.props.data.data, false, true);
  }

  /**
   *
   * @param nextProps
   */
  componentWillReceiveProps(nextProps) {
    const nextFilters = nextProps.filters;
    const { clearAllFilters } = this.props;
    const filtersSequence = this.props.filtersSequence;
    const filtersSequenceSkip = nextProps.filtersSequenceSkip;
    const nextFiltersSequence = nextProps.filtersSequence;
    const source = this.props.data.data.options.entity.source;
    const filterLength = nextFiltersSequence.length - 1;
    const isDate = nextFiltersSequence[filterLength] !== 'date';
    const { items } = this.state;

    if (filtersSequence !== nextFiltersSequence && source !== filtersSequenceSkip) {
      if (this.updateTimeout) {
        clearTimeout(this.updateTimeout);
      }

      this.updateTimeout = setTimeout(() => {
        this.updateLevel(nextFilters, nextFiltersSequence);
      }, FilterPart.UPDATE_DELAY);
    }

    if (clearAllFilters && typeof clearAllFilters === 'boolean') {
      this.clearAllFilters();
    }
    if (nextProps.setGroupTab !== this.props.setGroupTab && isDate) {
      let typeFilter = nextFilters[nextFiltersSequence[filterLength]];
      if (typeFilter) {
        items.map(item => {
          if (typeFilter.indexOf(item.id) >= 0) {
            this.setState({
              display: [item].sort((a, b) => a.order - b.order)
            });
            const toScenario = this.upendToFilters(
              [item],
              { key: nextFiltersSequence[filterLength], name: nextProps.data.data.name },
              false,
              true
            );
            this.props.updateDisplay(toScenario, true);
          }
        });
      }
    }
  }
  /**
   *
   */
  componentWillUnmount() {
    this.disposables.forEach(fn => fn());
  }

  /**
   *
   * @param filters
   * @param filtersSequence
   * @returns {{filters: Array}}
   */

  clearAllFilters = () => {
    const { updateFilters, filters } = this.props;

    let nextFilters;

    if (filters.date_start && filters.date_end) {
      nextFilters = {
        date_start: filters.date_start,
        date_end: filters.date_end
      };
    } else {
      nextFilters = {};
    }

    this.props.updateDisplay(this.props.data.data, false, true, true);
    updateFilters(nextFilters, 'clearFilters');
  };

  getFiltersSequenceParams(filters, filtersSequence) {
    const params = {
      filters: []
    };

    if (filtersSequence.length) {
      filtersSequence.forEach(source => {
        if (source === 'date') {
          return;
        }

        const pluralSource = source;
        const values = filters.hasOwnProperty(pluralSource) ? filters[pluralSource] : [];

        params.filters.push({
          entity: source,
          values: values
        });
      });
    }

    return params;
  }

  /**
   *
   * @param nextFilters
   * @param filtersSequence
   * @param changed
   */
  updateLevel(nextFilters, filtersSequence) {
    this.disposables.forEach(fn => fn());

    const source = this.props.data.data.options.entity.source;
    const selectedItemsParents = this.state.selectedItemsParents.slice();

    let item = null;

    if (selectedItemsParents.length) {
      item = selectedItemsParents.pop();
    }

    const filtersSequenceParams = this.getFiltersSequenceParams(nextFilters, filtersSequence);

    this.setState(
      {
        updating: true,
        parents: selectedItemsParents
      },
      () => {
        this.props.updateFiltersState(true, source);

        this.loadLevel(item, filtersSequenceParams, true).then(
          () => {
            this.setState({
              updating: false
            });

            this.props.updateFiltersState(false, source);
          },
          () => {
            this.setState({
              updating: false
            });

            this.props.updateFiltersState(false, source);
          }
        );
      }
    );
  }

  /**
   *
   * @param parent
   * @param filtersSequenceParams
   * @param withFiltersSequence
   * @param offset
   * @returns {*}
   */
  loadLevel(parent = null, filtersSequenceParams = {}, withFiltersSequence = false, offset = 0) {
    const { suggest } = this.state;

    const data = this.props.data.data;
    const filters = this.props.filters;
    const filtersSequence = this.props.filtersSequence;

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const { items } = this.state;
    const parents = this.state.parents.slice();
    const {
      drillToFilters,
      isDrilled,
      match: {
        params: { missionId = null, scenarioId = null }
      }
    } = this.props;

    if (!withFiltersSequence) {
      filtersSequenceParams = this.getFiltersSequenceParams(filters, filtersSequence);
      if (filtersSequenceParams.filters[0] && drillToFilters) {
        if (drillToFilters.id && drillToFilters.id.length > 1 && isDrilled) {
          const toScenario = this.upendToFilters(drillToFilters, 'Categories', filtersSequenceParams);
          this.props.updateDisplay(toScenario, true);
        }
      }
    }

    const requestData = {
      entity: {
        ...data.options.entity,
        ...filtersSequenceParams,
        date_start: filters.date_start,
        date_end: filters.date_end
      },
      kpi: data.options.kpi,
      mission: missionId
    };

    const nextState = {
      level: 1,
      kpi: requestData.kpi,
      loading: true
    };

    if (parent) {
      requestData.entity.category = parent.id;
      parents.push(parent);
      nextState.parents = parents;
      nextState.level = parent.level + 1;

      if (requestData.entity.source === 'category') {
        requestData.entity.level = nextState.level;
      }
    }

    if (
      !suggest &&
      requestData.entity.filters.length === 1 &&
      requestData.entity.filters[0].entity === requestData.entity.source
    ) {
      requestData.entity.filters = [];
    }

    if (suggest) {
      requestData.entity.suggest = suggest;
    }

    // проверка чтоб при смене дейтпиккера не слеть запрос на последний выбранный фильтр
    if (
      !suggest &&
      requestData.entity.filters.length > 1 &&
      (requestData.entity.date_start || requestData.entity.date_end) &&
      requestData.entity.filters[requestData.entity.filters.length - 1].entity === requestData.entity.source
    ) {
      requestData.entity.filters.pop();
    }

    if (requestData.entity.source !== 'category') {
      requestData.entity.limit = 30;
      requestData.entity.offset = offset;
    }
    this.setState(nextState);

    // const scenarioData = JSON.parse(localStorage.scenario);
    // const missionData = JSON.parse(localStorage.mission);

    const promise = configAxios({
      method: 'POST',
      url: `/scenario/${scenarioId}/filter-block?mission=${missionId}`,
      data: requestData,
      cancelToken: source.token
    });

    promise.then(
      response => {
        const responseData = response.data.data;
        const data = responseData ? responseData : [];

        const kpiNames = FilterPart.getMetricsNames(Object.keys(data).length ? data[0].metrics : [], nextState.kpi);

        data.map(a => {
          items.map(c => {
            if (a.id === c.id) {
              const index = items.indexOf(c);
              items.splice(index, 1);
            }
          });
        });

        let newItems = [];
        if ((suggest && !offset) || (suggest === '' && !offset)) {
          newItems = data;
        } else {
          newItems = items.concat(data);
        }

        const temp = {
          items: newItems,
          loaded: true,
          loading: false,
          kpiNames: kpiNames
        };

        if (withFiltersSequence || requestData.entity.source === 'category') {
          temp.items = data;
        }

        this.setState({
          loadMoreFilters: true
        });

        if (data.length < 30) {
          this.setState({
            loadMoreFilters: false
          });
        }

        if (data.length && !data[0].hasOwnProperty('level') && nextState.level === 1) {
          temp['singleLevel'] = true;
        }

        let displayCopy = [];

        this.setState(temp, () => {
          if (filters[requestData.entity.source]) {
            const { items } = this.state;
            items.map(a => {
              filters[requestData.entity.source].map(b => {
                if (a.id === b) {
                  displayCopy.push(a);
                }
              });
            });
          }
        });
        if (displayCopy[0]) {
          const toScenario = this.upendToFilters(displayCopy, this.props.data.data);
          this.props.updateDisplay(toScenario, true);
        }
        this.setState({
          display: displayCopy
        });
      },
      () => {
        this.setState({
          loaded: true,
          loading: false
        });
      }
    );

    this.disposables.push(() => source.cancel());
    return promise;
  }

  /**
   *
   */
  handleLoadMoreItems = () => {
    const { loadMoreFilters, items } = this.state;
    if (loadMoreFilters) {
      this.loadLevel(null, {}, false, items.length);
    }
  };

  /**
   *
   */
  handleAllColumnsModeToggle = () => {
    this.setState(state => {
      return {
        allColumnsMode: !state.allColumnsMode
      };
    });
  };

  /**
   *
   */
  handleContentToggle = () => {
    this.setState(state => {
      return {
        allColumnsMode: false,
        contentIsOpened: !state.contentIsOpened,
        showSearch: !state.showSearch,
      };
    });
  };

  /**
   *
   * @param item
   */
  handleLoadLevel = item => {
    this.disposables.forEach(fn => fn());
    this.loadLevel(item);
  };

  /**
   *
   */
  handleLoadPrevLevel = () => {
    this.disposables.forEach(fn => fn());

    const parents = this.state.parents.slice();

    let item = null;

    if (!parents.length) {
      return;
    }

    parents.pop();

    if (parents.length) {
      item = parents.pop();
    }

    this.setState(
      {
        parents: parents
      },
      () => {
        this.loadLevel(item);
      }
    );
  };

  /**
   *
   * @param item
   */

  upendToFilters = (item, key, isDrilled, isTransition) => {
    if (isDrilled) {
      const keys = isDrilled.filters[0].values[0];
      const idFilters = item.id;
      const checked = idFilters.indexOf(keys);
      let bufferArray = item.name.filter((item, i) => i === checked);
      const filters = {};
      filters['category'] = { name: key, items: bufferArray, id: checked };
      bufferArray = [];
      return filters;
    }
    if (isTransition) {
      let bufferArray = [];
      let bufferId = [];
      item.map(item => {
        bufferArray.push(item.name);
        bufferId.push(item.id);
      });
      const source = key;
      const filters = {};
      filters[source.key] = { name: key.name, items: bufferArray, id: bufferId };
      bufferId = [];
      bufferArray = [];
      return filters;
    }
    if (item) {
      let bufferArray = [];
      let bufferId = [];
      item.map(item => {
        bufferArray.push(item.name);
        bufferId.push(item.id);
      });
      const source = key.options.entity.source;
      const filters = {};
      filters[source] = { name: key.name, items: bufferArray, id: bufferId };
      bufferId = [];
      bufferArray = [];
      return filters;
    } else {
      const filters = {};
      filters[key] = {};
      return filters;
    }
  };

  handleChooseItem = item => {
    const source = this.props.data.data.options.entity.source;
    const caseName = this.props.data.data;
    const pluralSource = source;
    const filters = this.props.filters;
    const items = this.state.items;
    const parents = this.state.parents;

    const { display } = this.state;

    let nextFilters = {
      ...filters
    };

    let displayCopy = [...display];

    let displayFilter = [];
    displayCopy.map(a => {
      if (Object.keys(nextFilters).length) {
        nextFilters[pluralSource].map(b => {
          if (a.id === b) {
            displayFilter.push(a.id);
          }
        });
      }
    });

    nextFilters[pluralSource] = displayFilter;

    if (!nextFilters.hasOwnProperty(pluralSource)) {
      nextFilters[pluralSource] = [];
    } else {
      nextFilters[pluralSource] = nextFilters[pluralSource].slice();
    }

    const sourceValues = nextFilters[pluralSource];
    //prettier-ignore
    const status =
      sourceValues.length && items.length
        ? sourceValues.every(typeValue => {
          return items.some(itemValue => {
            return itemValue.id === typeValue;
          });
        })
        : false;

    if (!status) {
      this.setState({
        display: []
      });
      nextFilters[pluralSource] = [];
    }

    const index = nextFilters[pluralSource].indexOf(item.id);

    if (index !== -1) {
      nextFilters[pluralSource].splice(index, 1);
      let displayCopy = display;
      displayCopy.splice(index, 1);
      this.setState(
        {
          display: displayCopy.sort((a, b) => a.order - b.order)
        },
        () => {
          const toScenario = this.upendToFilters([...this.state.display], caseName);
          this.props.updateDisplay(toScenario, true);
        }
      );
    } else {
      item.order = items.indexOf(item);
      nextFilters[pluralSource].push(item.id);
      this.setState(
        prevState => ({
          display: [...prevState.display, item].sort((a, b) => a.order - b.order)
        }),
        () => {
          const toScenario = this.upendToFilters([...this.state.display], caseName);
          this.props.updateDisplay(toScenario, true);
        }
      );
    }
    if (nextFilters[pluralSource].length === 0) {
      delete nextFilters[pluralSource];
    }

    if (source === 'category') {
      if (nextFilters.hasOwnProperty(pluralSource) && item.hasOwnProperty('level')) {
        nextFilters['level'] = item.level;
      } else if (nextFilters.hasOwnProperty('level')) {
        delete nextFilters.level;
      }

      this.setState({
        selectedItemsParents:
          parents && parents.length && nextFilters.hasOwnProperty(pluralSource) ? parents.slice() : []
      });
    }
    this.props.updateFilters(nextFilters, source);
  };

  /**
   *
   */
  handleClearFilter = () => {
    const {
      filters,
      updateFilters,
      setDrillFilters,
      updateDisplay,
      drillFilters: { date_start = '', date_end = '' } = {}
    } = this.props;

    const source = this.props.data.data.options.entity.source;
    const pluralSource = source;

    let nextFilters = {
      ...filters
    };
    const toScenario = this.upendToFilters(false, source);
    updateDisplay(toScenario, true);
    if (nextFilters.hasOwnProperty(pluralSource)) {
      delete nextFilters[pluralSource];

      this.setState({
        display: [],
        selectedItemsParents: []
      });

      updateFilters(nextFilters, source);
    }

    const newDrillFilters = {
      date_start,
      date_end,
      ...nextFilters
    };
    setDrillFilters(newDrillFilters);
  };

  /**
   *
   */
  handleSuggestFilter = suggest => {
    this.setState(
      {
        suggest,
        loading: true
      },
      () => {
        this.loadLevel();
      }
    );
  };

  handleChangeView = () => {
    this.setState({ showSearch: !this.state.showSearch });
  };

  /**
   *
   * @returns {XML}
   */
  render() {
    const data = this.props.data.data;
    const name = data.name;
    const source = data.options.entity.source;
    const {
      kpi,
      kpiNames,
      items,
      parents,
      display,
      singleLevel,
      allColumnsMode,
      contentIsOpened,
      loaded,
      loading,
      updating,
      suggest,
      showSearch
    } = this.state;

    const { filters, filtersLoading } = this.props;

    const pluralSource = source;
    const values = filters.hasOwnProperty(pluralSource) ? filters[pluralSource] : [];
    const valuesIsNotEmpty = values.length > 0;
    const headIsVisible = parents.length > 0 || (loaded && items.length > 0);
    const loadingIsVisible = !loaded;
    const updatingIsVisible = loaded && (filtersLoading || updating || loading);

    const style = {};
    const attrs = {};
    const color = '#00A5E6';

    if (allColumnsMode) {
      style.width = FilterPart.DEFAULT_WIDTH + FilterPart.COLUMN_DEFAULT_WIDTH * kpi.length;
      attrs['data-all-columns-mode'] = true;
    }

    if (contentIsOpened) {
      attrs['data-content-is-opened'] = true;
    }

    return (
      <div className={styles.container}>
        <div
          className={[styles.inner, !contentIsOpened && valuesIsNotEmpty ? styles.displayWrap : ''].join(' ')}
          {...attrs}
          style={style}
        >
          <FilterPartPanel
            name={name}
            display={display}
            allColumnsModeAvailable={kpiNames.length > 1}
            allColumnsMode={!!allColumnsMode}
            valuesIsNotEmpty={valuesIsNotEmpty}
            contentIsOpened={contentIsOpened}
            clearIsDisabled={updatingIsVisible}
            allColumnsModeToggle={this.handleAllColumnsModeToggle}
            contentToggle={this.handleContentToggle}
            clearFilter={this.handleClearFilter}
            suggestFilter={this.handleSuggestFilter}
            suggest={suggest}
            showSearch={showSearch}
            changeView={this.handleChangeView}
            source={source}
          />

          <div
            className={[
              styles.content,
              contentIsOpened ? styles.contentIsOpened : '',
              headIsVisible ? styles.contentWithHead : ''
            ].join(' ')}
          >
            {headIsVisible && (
              <FilterPartHead
                parents={parents}
                kpiNames={kpiNames}
                singleLevel={singleLevel}
                loadPrevLevel={this.handleLoadPrevLevel}
                contentIsOpened={allColumnsMode}
              />
            )}

            <FilterPartBody
              kpi={kpi}
              items={items}
              values={values}
              singleLevel={singleLevel}
              loadLevel={this.handleLoadLevel}
              chooseItem={this.handleChooseItem}
              source={source}
              loadMoreItems={this.handleLoadMoreItems}
              loading={loading}
            />

            {loadingIsVisible && (
              <div className={styles.loading}>
                <MDSpinner singleColor={color} size={20} />
              </div>
            )}

            {updatingIsVisible && (
              <div className={styles.updating}>
                <MDSpinner singleColor={color} size={20} />
              </div>
            )}
          </div>

          {!contentIsOpened && valuesIsNotEmpty && (
            <div>
              {updatingIsVisible && (
                <div className={styles.updating}>
                  <MDSpinner singleColor={color} size={20} />
                </div>
              )}
              <FilterPartDisplay
                kpi={kpi}
                kpiNames={kpiNames}
                display={display}
                name={name}
                chooseItem={this.handleChooseItem}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

FilterPart.propTypes = {
  data: PropTypes.object.isRequired, // eslint-disable-line
  match: PropTypes.object.isRequired, // eslint-disable-line
  setGroupTab: PropTypes.object.isRequired, // eslint-disable-line
  filters: PropTypes.object.isRequired, // eslint-disable-line
  filtersSequence: PropTypes.array.isRequired, // eslint-disable-line
  filtersSequenceSkip: PropTypes.string, // eslint-disable-line
  filtersLoading: PropTypes.bool.isRequired, // eslint-disable-line
  updateFilters: PropTypes.func.isRequired, // eslint-disable-line
  updateFiltersState: PropTypes.func.isRequired,
  updateDisplay: PropTypes.func.isRequired,
  setDrillFilters: PropTypes.func.isRequired,
  drillFilters: PropTypes.object.isRequired, // eslint-disable-line
  mission: PropTypes.object.isRequired, // eslint-disable-line
  drillToFilters: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  isDrilled: PropTypes.bool.isRequired, // eslint-disable-line
  clearAllFilters: PropTypes.bool.isRequired // eslint-disable-line
};

export default withRouter(
  connect(store => {
    return {
      setGroupTab: store.setGroupTab,
      clearFilters: store.setClearAllFilters
    };
  })(FilterPart)
);
