import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Fab from '@material-ui/core/Fab';
import Dialog from '@material-ui/core/Dialog';
import ImageIcon from '@material-ui/icons/Image';
import DialogContent from '@material-ui/core/DialogContent';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import configAxios from '../../../config/axios';
import ButtonDefault from '../../Buttons/ButtonDefault';

import styles from './CarouselPhoto.css';

const baseURL = configAxios.defaults.baseURL;

class CarouselPhoto extends Component {
  state = {
    indexPhoto: 0,
    open: false,
    photoIsLoad: false
  };

  openHandle = () => {
    this.setState({
      open: true
    });
  };

  closeHandle = () => {
    this.setState({
      open: false,
      indexPhoto: 0
    });
  };

  prevPhotoHandle = () => {
    const { indexPhoto, photoIsLoad } = this.state;
    const { images } = this.props;

    if (!photoIsLoad) return;

    this.setState({
      indexPhoto: indexPhoto === 0 ? images.length - 1 : indexPhoto - 1,
      photoIsLoad: false
    });
  };

  nextPhotoHandle = () => {
    const { indexPhoto, photoIsLoad } = this.state;
    const { images } = this.props;

    if (!photoIsLoad) return;

    this.setState({
      indexPhoto: indexPhoto + 1 === images.length ? 0 : indexPhoto + 1,
      photoIsLoad: false
    });
  };

  imageLoaded = () => {
    this.setState({
      photoIsLoad: true
    });
  };

  render() {
    const { indexPhoto, open } = this.state;
    const { images, mobile = false, mobileBtnText = '' } = this.props;
    if (images === null || images.length === 0) return null;

    const src = images[indexPhoto] && images[indexPhoto][0] === 'h' ? images[indexPhoto] : baseURL + images[indexPhoto];

    return (
      images && (
        <Fragment>
          {mobile && mobileBtnText.length > 0 ? (
            <ButtonDefault fullWidth onClick={this.openHandle}>
              {mobileBtnText}
            </ButtonDefault>
          ) : (
            <Fab color="primary" size="small" onClick={this.openHandle}>
              <ImageIcon />
            </Fab>
          )}
          <Dialog open={open} onClose={this.closeHandle}>
            <DialogContent>
              <img src={src} alt="" width="100%" onLoad={this.imageLoaded} />
              <p>{`${indexPhoto + 1} / ${images.length}`}</p>
              {images.length > 1 && (
                <Fragment>
                  <div className={styles.arrow} style={{ left: 10 }}>
                    <Fab color="primary" size="small" onClick={this.prevPhotoHandle}>
                      <KeyboardArrowLeftIcon />
                    </Fab>
                  </div>
                  <div className={styles.arrow} style={{ right: 10 }}>
                    <Fab color="primary" size="small" onClick={this.nextPhotoHandle}>
                      <KeyboardArrowRightIcon />
                    </Fab>
                  </div>
                </Fragment>
              )}
            </DialogContent>
          </Dialog>
        </Fragment>
      )
    );
  }
}

CarouselPhoto.propTypes = {
  mobile: PropTypes.bool,
  mobileBtnText: PropTypes.string,
  images: PropTypes.array.isRequired
};

export default CarouselPhoto;
