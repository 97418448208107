import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Fab from '@material-ui/core/Fab';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import CommentIcon from '@material-ui/icons/Comment';
import Preload from '../../../constructors/Preload';
import { loadTask, setPageLoadingStatus } from '../../../store/actions/actions';
import configAxios from '../../../config/axios';
import ButtonAir from '../../Buttons/ButtonAir';

import styles from './Comment.css';


class Comment extends Component {
  constructor(props) {
    super(props);
    const { dispatch } = this.props;

    this.preload = new Preload();
    this.preload.subscribe(promises => {
      dispatch(setPageLoadingStatus(promises.length > 0));
    });
  }

  state = {
    openComment: false,
    item: {},
    comment: '',
    itemId: null,
    disabledComment: true
  };

  componentDidMount() {
    this.handleSetComment();
  }

  /**
   *
   * @param nextProps
   */
  componentWillReceiveProps(nextProps) {
    if (nextProps.text !== this.props.text) {
      this.handleSetComment(nextProps.text);
    }
  }

  handleSetComment = nextText => {
    const { text } = this.props;

    this.setState({
      comment: nextText || nextText === '' ? nextText : text
    });
  };

  handleCommentChange = e => {
    e.preventDefault();

    this.setState({
      comment: e.target.value,
      disabledComment: false
    });
  };

  handleCommentOpen = e => {
    this.setState({
      anchorEl: e.currentTarget,
      openComment: true
    });
  };

  handleCommentClose = () => {
    this.setState({
      openComment: false
    });
  };

  updateComment = () => {
    const { dispatch, itemId, shopId, caseType, urlApi } = this.props;
    const { comment } = this.state;

    const query = new URLSearchParams(location.search);
    const mid = query.has('mid') ? Number(query.get('mid')) : JSON.parse(localStorage.mission).id;

    const promise = configAxios({
      method: 'POST',
      url: `${urlApi}/comment`,
      data: {
        shopId,
        text: comment,
        itemId,
        caseType,
        missionId: mid
      }
    });

    this.preload.add(promise);

    promise.then(() => {
      this.setState(
        {
          openComment: false,
          disabledComment: true
        },
        () => {
          dispatch(loadTask(true));
        }
      );
    });

    return promise;
  };

  deleteComment = () => {
    const { dispatch, itemId, caseType, urlApi } = this.props;

    const query = new URLSearchParams(location.search);
    const mid = query.has('mid') ? Number(query.get('mid')) : JSON.parse(localStorage.mission).id;

    const promise = configAxios({
      method: 'DELETE',
      url: `${urlApi}/comment-delete`,
      params: {
        itemId,
        missionId: mid,
        caseType
      }
    });

    this.preload.add(promise);

    promise.then(() => {
      this.setState(
        {
          openComment: false,
          disabledComment: true
        },
        () => {
          dispatch(loadTask(true));
        }
      );
    });

    return promise;
  };

  render() {
    const { text, language } = this.props;
    const { openComment, comment, anchorEl, disabledComment } = this.state;
    const lang = language.translation.directTask;

    return (
      <Fragment>
        <Fab
          size="small"
          color="primary"
          onClick={e => {
            this.handleCommentOpen(e);
          }}
        >
          {text ? <CommentIcon /> : <AddIcon />}
        </Fab>
        <Popover
          open={openComment}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right'
          }}
          onClose={this.handleCommentClose}
        >
          <div className={styles.popover}>
            <TextField
              className={styles.textField}
              placeholder={lang.comment}
              onChange={this.handleCommentChange}
              multiline
              rows={1}
              rowsMax={5}
              fullWidth
              value={comment}
            />
            <div className={styles.actionButton}>
              <ButtonAir onClick={this.updateComment} disabled={disabledComment}>
                {lang.add}
              </ButtonAir>
              {text && (
                <Button variant="contained" color="secondary" onClick={this.deleteComment}>
                  {lang.delete}
                </Button>
              )}
            </div>
          </div>
        </Popover>
      </Fragment>
    );
  }
}

Comment.defaultProps = {
  data: [],
  itemId: null,
  newTree: null
};

Comment.propTypes = {
  urlApi: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  itemId: PropTypes.number.isRequired,
  shopId: PropTypes.number.isRequired,
  caseType: PropTypes.number.isRequired,
  language: PropTypes.object.isRequired
};

export default connect(store => {
  return {
    language: store.language
  };
})(Comment);
