import VirtualStock from './VirtualStock';

export default class VirtualStockFactory {
  static createFromServerData({
    case_id,
    plu_original,
    plu,
    ass_type,
    stock,
    stock_sum,
    product_turnover,
    days_without_sales,
    last_sales_date,
    last_sales_volume,
    last_shipment_date,
    last_shipment_volume,
    promo_start,
    promo_end,
    is_promo,
    vs_case_id,
    is_vs_case,
    title,
    category_name3,
    category_name4
  }) {
    return new VirtualStock(
      case_id,
      plu_original,
      plu,
      ass_type,
      stock,
      stock_sum,
      product_turnover,
      days_without_sales,
      last_sales_date,
      last_sales_volume,
      last_shipment_date,
      last_shipment_volume,
      promo_start,
      promo_end,
      is_promo,
      vs_case_id,
      is_vs_case,
      title,
      category_name3,
      category_name4
    );
  }
}