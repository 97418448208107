import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Chip from '@material-ui/core/Chip';
import Preload from '../../components/Preload';
import {
  loadScenarios,
  setDatePickerAction,
  chooseMission,
  setPageLoadingStatus,
  chooseScenario,
} from '../../store/actions/actions';
import configAxios from '../../config/axios';
import ErrorDialog from '../../components/ErrorDialogSelect';

import styles from './Home.css';
import ScenarioIcon from '../../components/ScenarioIcon';

class Home extends Component {
  /**
   *
   * @param scenarios
   */
  static prepareScenarios(scenarios) {
    return scenarios.map(item => {
      item['url'] =
        (item.scenario_kind !== 'office' || item.scenario_kind !== 'redash' ? '/' : '/scenario/') + item['link'];
      return item;
    });
  }

  static purgeLocalStorage() {
    localStorage.removeItem('scenario');
    localStorage.removeItem('missions');
    localStorage.removeItem('scenarios');
    localStorage.removeItem('mission');
  }

  /**
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.disposables = [];

    this.state = {
      missions: [],
      errorDialog: false,
      errorMsg: '',
    };
  }

  /**
   *
   */
  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(setDatePickerAction({}));
    dispatch(loadScenarios([]));

    this.loadMissions();
  }

  /**
   *
   */
  componentWillUnmount() {
    this.disposables.forEach(fn => fn());
  }

  componentWillReceiveProps (nextProps) {
    if(
      (!this.props.impersonation && nextProps.impersonation) ||
      (this.props.impersonation && !nextProps.impersonation)
    ){
      this.loadMissions();
    }
  }
  /**
   *
   * @returns {Promise.<T>|ThenPromise<never | T>|ThenPromise.<R|*>}
   */
  loadMissions = () => {
    const { dispatch } = this.props;
    const tokenStore = this.props.userData.access_token;
    const tokenLocal = localStorage.userData ? JSON.parse(localStorage.userData).access_token : null;
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    dispatch(setPageLoadingStatus(true));

    if (tokenStore && !tokenLocal) {
      configAxios.defaults.headers = {
        Authorization: 'Bearer ' + tokenStore,
      };
    }

    const promise = configAxios({
      method: 'GET',
      url:
        '/user/missions?expand=blocking,counter,scenarios,netName,companyName&fields=id,name,link,netName,companyName,blocking,counter,scenarios.id,scenarios.name,scenarios.link,scenarios.model_prefix,scenarios.show_counter,scenarios.scenario_type,scenarios.scenario_kind',
      cancelToken: source.token,
    });

    promise.then(response => {
      dispatch(setPageLoadingStatus(false));
      response.data.data.sort((a, b) => a.name.localeCompare(b.name));
      localStorage.setItem('missions', JSON.stringify(response.data.data));
      this.setState({
        missions: response.data.data,
      });
    });

    this.disposables.push(() => source.cancel('cancelled'));

    return promise;
  };

  /**
   *
   * @param mission
   * @returns {Promise.<T>|ThenPromise<never | T>|ThenPromise.<R|*>}
   */
  loadScenarios = mission => {
    const { dispatch } = this.props;

    const CancelToken = axios.CancelToken;

    const source = CancelToken.source();

    dispatch(setPageLoadingStatus(true));

    let xhr = configAxios({
      method: 'GET',
      url: `/mission/${mission.id}?expand=scenarios&fields=scenarios`,
      cancelToken: source.token,
    }).then(response => {
      const scenarios = Home.prepareScenarios(response.data.scenarios);
      dispatch(loadScenarios(scenarios));
      dispatch(setPageLoadingStatus(false));
      return scenarios;
    });

    this.disposables.push(() => source.cancel('cancelled'));

    return xhr;
  };

  /**
   *
   * @param mission
   */
  chooseMissionHandler = mission => {
    const { translation } = this.props.language;
    const blocked = mission.blocking.filter(block => block.active == true);
    if (blocked.length) {
      this.setState({
        errorDialog: true,
        errorMsg: mission.blocking[0].text,
      });
    } else {
      const { dispatch } = this.props;
      const { missions } = this.state;
      const index = missions.map(item => item.id).indexOf(mission.id);
      const missionScenarios = missions[index].scenarios;

      const scenarios = Home.prepareScenarios(missionScenarios);
      dispatch(chooseMission(mission));

      if (scenarios && scenarios.length) {
        dispatch(loadScenarios(scenarios));
        dispatch(chooseScenario(scenarios[0]));
        const url =
          scenarios[0].scenario_kind === 'office' || scenarios[0].scenario_kind === 'redash'
            ? `/scenario/${mission.id}/${scenarios[0].id}`
            : `${scenarios[0].url}?mid=${mission.id}&sid=${scenarios[0].id}`;
        localStorage.setItem('scenario', JSON.stringify(scenarios[0]));
        const win = window.open(url, '_blank');
        if (win != null) win.focus();
      } else {
        alert(translation.errors.scenariosEmpty);
        console.log('Scenarios array is empty');
      }
    }
  };

  handleDialogState = () => {
    this.setState({
      errorDialog: false,
    });
  };

  render() {
    const { missions, errorDialog, errorMsg } = this.state;
    const ifTask = this.props.language.translation.counterTask;
    return (
      <div>
        <Preload />
        <Grid fluid className={styles.containerGrid}>
          <Row className={styles.containerRow}>
            {missions.map(item => {
              const { name, counter, netName, companyName } = item;
              const blocked = item.blocking.filter(block => !!block.active);
              const iconName = item.scenarios[0].scenario_type;
              if (iconName === 'freshness') {
                return null;
              }
              const userData = this.props.userData;
              let owner = netName;
              // TODO Make a bool mark on the backend that this is a demo user
              if (userData && userData.login.includes('demo') && userData.name.includes('DEMO')) {
                owner = companyName;
              }

              return (
                <Col
                  xs={6}
                  md={4}
                  key={item.id}
                  className={[styles.missionsCol, blocked.length ? styles.missionsColDisable : ''].join(' ')}
                  onClick={() => this.chooseMissionHandler(item)}
                >
                  <div className={styles.iconWrap}>{iconName ? <ScenarioIcon icon={iconName} /> : <div />}</div>
                  <div className={styles.nameWrap}>
                    {name}
                    {owner && <div className={styles.elementRetailerName}>{owner}</div>}
                  </div>
                  {typeof counter === 'number' && (
                    <Chip className={styles.counter} label={counter > 0 ? ifTask.task : ifTask.noTask} />
                  )}
                </Col>
              );
            })}
          </Row>
        </Grid>
        <ErrorDialog active={errorDialog} errorText={errorMsg} handleDialogState={this.handleDialogState} />
      </div>
    );
  }
}

Home.defaultProps = {
  userData: {},
  language: {},
};

Home.propTypes = {
  dispatch: PropTypes.func.isRequired,
  language: PropTypes.object,
  userData: PropTypes.object,
  impersonation: PropTypes.bool,
};

export default connect(store => {
  return {
    language: store.language,
    userData: store.userData,
    impersonation: store.impersonation
  };
})(Home);
