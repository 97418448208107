import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './MobileCaseIcon.css';

const AddCase = () => {
  return (
    <svg
      version="1.1"
      id="Слой_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 76.6 73.8"
      xml="preserve"
      className={styles.svgIcon}
    >
      <g>
        <path
          d="M76.6,50.8l-0.3-2.2l-6.1-32.8c-0.2-1.2-1.3-2.1-2.6-2.1h-6.9c-0.4,0-0.8,0.2-1.1,0.5s-0.5,0.7-0.5,1.1
                    c0,0.9,0.7,1.6,1.6,1.6h6.3c0.1,0,0.2,0.1,0.2,0.1L73,48.1H45.6l-0.1,1.6c-0.4,2.5-1.9,4.4-4,5.2c-2.1,0.8-4.2,0.7-6-0.4
                    c-1.7-1-2.9-2.7-3.3-4.7c0-0.1,0-0.1,0-0.2L32,48.1H3.6L9.5,17c0-0.1,0.1-0.1,0.2-0.1h6.8c0.9,0,1.6-0.7,1.6-1.6s-0.7-1.6-1.6-1.6
                    H9c-1.2,0-2.3,0.9-2.6,2.1L0.2,48.6L0,50.9v20.5c0,1.3,1.1,2.4,2.4,2.4H74c1.5,0,2.6-1.2,2.6-2.6L76.6,50.8z M73.4,51.4v18.8
                    c0,0.2-0.1,0.3-0.3,0.3H3.6c-0.2,0-0.4-0.2-0.4-0.4V51.4h26c1.2,4.3,5.1,7.3,9.6,7.3c0.3,0,0.6,0,0.9,0c0.1,0,0.1,0,0.3,0l0.1,0
                    c0.2,0,0.3,0,0.5-0.1c3.8-0.7,6.8-3.4,7.9-7.1H73.4z"
        />
        <path
          d="M24.5,16.7h12.4v12.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4V16.7h12.4c0.8,0,1.4-0.6,1.4-1.4s-0.6-1.4-1.4-1.4H39.7V1.4
		        c0-0.8-0.6-1.4-1.4-1.4s-1.4,0.6-1.4,1.4v12.4H24.5c-0.8,0-1.4,0.6-1.4,1.4S23.7,16.7,24.5,16.7z"
        />
      </g>
    </svg>
  );
};

const HideCase = () => {
  return (
    <svg
      version="1.1"
      id="Слой_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 79.4 64.6"
      xml="preserve"
      className={styles.svgIcon}
    >
      <g>
        <path
          d="M77.3,9.1L32.5,8.8L18.9,0.3C18.6,0.1,18.2,0,17.8,0H2.1C0.9,0,0,0.9,0,2.1l0,60.3c0,1.2,0.9,2.1,2.1,2.1l75.2,0
                    c0,0,0,0,0,0c0.6,0,1.1-0.2,1.5-0.6c0.4-0.4,0.6-0.9,0.6-1.5V11.2C79.4,10,78.5,9.1,77.3,9.1z M7.1,9.4c-0.4,0.4-0.7,1-0.7,1.6
                    v49.4c0,0.1-0.1,0.1-0.1,0.1H4c-0.1,0-0.1-0.1-0.1-0.1V4c0-0.1,0.1-0.1,0.1-0.1h12.8c0.3,0,0.6,0.1,0.9,0.2l7.5,4.7L8.7,8.7
                    C8.1,8.7,7.5,8.9,7.1,9.4z M75.5,60.6C75.5,60.6,75.5,60.6,75.5,60.6c-0.1,0.1-0.1,0.1-0.1,0.1l-65-0.3c-0.1,0-0.1-0.1-0.1-0.1
                    V12.7c0,0,0-0.1,0-0.1c0,0,0,0,0.1,0c0,0,0,0,0,0l21.4,0.1l0,0l43.6,0.2c0.1,0,0.1,0.1,0.1,0.1V60.6z"
        />
        <path
          d="M62,35.1l-8.4-5.4c-0.7-0.4-1.4-0.8-2.2-1.2l6.4-6.8c0.5-0.6,0.5-1.5-0.1-2c-0.6-0.5-1.5-0.5-2,0.1l-7.2,7.7
                    c-5.4-1.6-11.4-0.8-16.3,2.3l-8.4,5.4c-1.2,0.7-1.2,2.5,0,3.2l8.4,5.4c0.2,0.1,0.4,0.2,0.6,0.3l-4.8,5.1c-0.5,0.6-0.5,1.5,0.1,2
                    c0.6,0.5,1.5,0.5,2-0.1l5.4-5.7c2.4,1,4.9,1.4,7.4,1.4c3.7,0,7.4-1,10.7-3.1l8.4-5.4C63.2,37.5,63.2,35.8,62,35.1z M46.8,29.3
                    l-0.4,0.5c-1-0.5-2.1-0.9-3.4-0.9C44.3,28.9,45.5,29,46.8,29.3z M34.7,42.1c-0.2-0.1-0.5-0.3-0.7-0.4l-7.3-4.6
                    c-0.3-0.2-0.3-0.7,0-0.9l7.3-4.6c2.7-1.7,5.8-2.6,8.9-2.7c-3.9,0-7.1,3.2-7.1,7.2c0,1.4,0.4,2.7,1.1,3.8L34.7,42.1z M59.5,37.1
                    l-7.3,4.6c-4.4,2.8-9.9,3.4-14.8,1.7l1.5-1.5c1.2,0.8,2.6,1.3,4.1,1.3c4,0,7.2-3.2,7.2-7.2c0-1.7-0.6-3.2-1.6-4.4l1.3-1.3
                    c0.8,0.4,1.6,0.8,2.4,1.3l7.3,4.6C59.8,36.4,59.8,36.9,59.5,37.1z"
        />
      </g>
    </svg>
  );
};

const SortCase = () => {
  return (
    <svg
      version="1.1"
      id="Слой_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 72.7 73"
      xml="preserve"
      className={styles.svgIcon}
    >
      <g>
        <path
          d="M72.4,42.9L49.5,3c-0.4-0.7-1.2-1.1-2-1.1c-0.8,0-1.6,0.5-2,1.2L37.8,18V2.4c0-1.3-1.1-2.4-2.4-2.4H12.7
                    c-1.3,0-2.4,1.1-2.4,2.4v24.3H2.3c-0.8,0-1.5,0.4-2,1.1c-0.4,0.7-0.4,1.6,0,2.3l22.9,39.8c0.4,0.7,1.2,1.1,2,1.1
                    c0.8,0,1.6-0.5,2-1.2l7.7-14.9v15.7c0,1.3,1.1,2.4,2.4,2.4H60c1.3,0,2.4-1.1,2.4-2.4V46.3h8.1c0.8,0,1.6-0.4,2-1.1
                    C72.8,44.4,72.8,43.6,72.4,42.9z M14.1,30.4V3.8c0-0.1,0-0.1,0.1-0.1h19.8c0.1,0,0.1,0,0.1,0.1v21.4L24.8,43
                    c-0.4,0.7-0.3,1.5,0.1,2.2c0.4,0.7,1.1,1.1,1.9,1.1h8.1v0.5l-9.8,18.9L4.8,30.4H14.1z M29.3,42.5L47.6,7.2L68,42.5h-9.3v26.6
                    c0,0.1,0,0.1-0.1,0.1H38.8c-0.1,0-0.1,0-0.1-0.1V42.5H29.3z"
        />
      </g>
    </svg>
  );
};

const DeleteCase = () => {
  return (
    <svg
      version="1.1"
      id="Слой_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 78 73.8"
      xml="preserve"
      className={styles.svgIcon}
    >
      <g>
        <path
          d="M76.6,48.6l-1.7-9.3c-0.1-0.8-0.8-1.3-1.6-1.3c-0.5,0-0.9,0.2-1.2,0.6c-0.3,0.4-0.4,0.9-0.3,1.3l1.5,8.4H45.8l-0.1,1.6
                        c-0.4,2.5-1.9,4.4-4,5.2c-2,0.8-4.2,0.6-6-0.4c-1.7-1-2.9-2.7-3.3-4.7c0-0.1,0-0.1,0-0.2l-0.1-1.5H3.9L9.8,17
                        c0-0.1,0.1-0.1,0.2-0.1h26.9c0.9,0,1.6-0.7,1.6-1.6s-0.7-1.6-1.6-1.6H9.3c-1.2,0-2.3,0.9-2.6,2.1L0.5,48.6l-0.3,2.3v20.5
                        c0,1.3,1.1,2.4,2.4,2.4h71.6c1.5,0,2.6-1.2,2.6-2.6l0-20.3L76.6,48.6z M73.6,51.4v18.8c0,0.2-0.1,0.3-0.3,0.3H3.9
                        c-0.2,0-0.4-0.2-0.4-0.4V51.4h26c1.2,4.3,5.1,7.3,9.6,7.3c0.3,0,0.6,0,0.9,0c0.1,0,0.1,0,0.3,0l0.1,0c0.2,0,0.3,0,0.5-0.1
                        c3.8-0.7,6.8-3.4,7.9-7.1H73.6z"
        />
        <path
          d="M59.6,39.1c10.2,0,18.4-8.3,18.4-18.4S69.7,2.2,59.6,2.2s-18.4,8.3-18.4,18.4S49.4,39.1,59.6,39.1z M59.6,5.5
                        c8.4,0,15.2,6.8,15.2,15.2c0,8.4-6.8,15.2-15.2,15.2S44.4,29,44.4,20.7C44.4,12.3,51.2,5.5,59.6,5.5z"
        />
        <path
          d="M50.5,28c0,0.5,0.2,0.9,0.5,1.2c0.7,0.7,1.7,0.7,2.4,0l6.2-6.2l6.2,6.2c0.3,0.3,0.7,0.5,1.2,0.5s0.9-0.2,1.2-0.5
                        c0.3-0.3,0.5-0.7,0.5-1.2c0-0.5-0.2-0.9-0.5-1.2L62,20.7l6.2-6.2c0.3-0.3,0.5-0.7,0.5-1.2c0-0.5-0.2-0.9-0.5-1.2
                        c-0.7-0.7-1.7-0.7-2.4,0l-6.2,6.2l-6.2-6.2c-0.7-0.7-1.7-0.7-2.4,0c-0.3,0.3-0.5,0.7-0.5,1.2c0,0.5,0.2,0.9,0.5,1.2l6.2,6.2
                        L51,26.8C50.7,27.1,50.5,27.6,50.5,28z"
        />
      </g>
    </svg>
  );
};

const BarcodeCase = () => {
  return (
    <svg
      version="1.1"
      id="Слой_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 85 57.7"
      xml="preserve"
      className={styles.svgIcon}
    >
      <g>
        <path
          d="M19.4,53.8L3.9,53.8V38.6c0-0.5-0.2-1-0.6-1.4c-0.4-0.4-0.8-0.6-1.4-0.6c0,0,0,0,0,0c-1.1,0-1.9,0.9-1.9,1.9v19.1h19.4
                    c1.1,0,1.9-0.9,1.9-1.9C21.3,54.7,20.4,53.8,19.4,53.8z"
        />
        <path
          d="M83,36.4c-1.1,0-1.9,0.9-1.9,1.9l0,15.5H65.9c-0.5,0-1,0.2-1.4,0.6c-0.4,0.4-0.6,0.9-0.6,1.4c0,1.1,0.9,1.9,1.9,1.9H85
                    V38.3C85,37.2,84.1,36.4,83,36.4z"
        />
        <path
          d="M65.6,0c-1.1,0-1.9,0.9-1.9,1.9c0,1.1,0.9,1.9,1.9,1.9l15.5,0.1v15.2c0,0.5,0.2,1,0.6,1.4c0.4,0.4,0.8,0.6,1.4,0.6
                    c0,0,0,0,0,0c1.1,0,1.9-0.9,1.9-1.9V0H65.6z"
        />
        <path
          d="M1.9,21.3c1.1,0,1.9-0.9,1.9-1.9l0-15.5h15.2c0.5,0,1-0.2,1.4-0.6C20.8,2.9,21,2.4,21,1.9C21,0.9,20.2,0,19.1,0H0v19.4
                    C0,20.4,0.9,21.3,1.9,21.3z"
        />
        <path d="M9.8,8.3c-0.9,0-1.7,0.7-1.7,1.7v36.6c0,0.9,0.7,1.7,1.7,1.7h6.1c0.9,0,1.7-0.7,1.7-1.7V10c0-0.9-0.7-1.7-1.7-1.7H9.8z" />
        <path
          d="M23.6,8.3h-1.4c-0.9,0-1.7,0.7-1.7,1.7v36.6c0,0.9,0.7,1.7,1.7,1.7h1.4c0.9,0,1.7-0.7,1.7-1.7V10
                    C25.2,9.1,24.5,8.3,23.6,8.3z"
        />
        <path
          d="M31.8,8.3h-1.4c-0.9,0-1.7,0.7-1.7,1.7v28.5c0,0.9,0.7,1.7,1.7,1.7h1.4c0.9,0,1.7-0.7,1.7-1.7V10
                    C33.4,9.1,32.7,8.3,31.8,8.3z"
        />
        <path
          d="M39.3,8.3h-1.4c-0.9,0-1.7,0.7-1.7,1.7v28.5c0,0.9,0.7,1.7,1.7,1.7h1.4c0.9,0,1.7-0.7,1.7-1.7V10C41,9.1,40.2,8.3,39.3,8.3
                    z"
        />
        <path
          d="M31.8,41.9h-1.4c-0.9,0-1.7,0.7-1.7,1.7v2.9c0,0.9,0.7,1.7,1.7,1.7h1.4c0.9,0,1.7-0.7,1.7-1.7v-2.9
                    C33.4,42.6,32.7,41.9,31.8,41.9z"
        />
        <path
          d="M39.3,41.9h-1.4c-0.9,0-1.7,0.7-1.7,1.7v2.9c0,0.9,0.7,1.7,1.7,1.7h1.4c0.9,0,1.7-0.7,1.7-1.7v-2.9
                    C41,42.6,40.2,41.9,39.3,41.9z"
        />
        <path
          d="M51.6,8.3h-6.1c-0.9,0-1.7,0.7-1.7,1.7v36.6c0,0.9,0.7,1.7,1.7,1.7h6.1c0.9,0,1.7-0.7,1.7-1.7V10
                    C53.2,9.1,52.5,8.3,51.6,8.3z"
        />
        <path
          d="M59.3,8.3h-1.4c-0.9,0-1.7,0.7-1.7,1.7v36.6c0,0.9,0.7,1.7,1.7,1.7h1.4c0.9,0,1.7-0.7,1.7-1.7V10
                    C60.9,9.1,60.2,8.3,59.3,8.3z"
        />
        <path
          d="M66.1,40.1h1.4c0.9,0,1.7-0.7,1.7-1.7V10c0-0.9-0.7-1.7-1.7-1.7h-1.4c-0.9,0-1.7,0.7-1.7,1.7v28.5
                    C64.4,39.4,65.2,40.1,66.1,40.1z"
        />
        <path d="M75,48.1c0.9,0,1.7-0.7,1.7-1.7V10c0-0.9-0.7-1.7-1.7-1.7h-1.4C72.7,8.3,72,9.1,72,10v36.5c0,0.9,0.7,1.7,1.7,1.7H75z" />
        <path
          d="M64.4,46.5c0,0.9,0.7,1.7,1.7,1.7h1.4c0.9,0,1.7-0.7,1.7-1.7v-2.9c0-0.9-0.7-1.7-1.7-1.7h-1.4c-0.9,0-1.7,0.7-1.7,1.7V46.5
                    z"
        />
      </g>
    </svg>
  );
};

class MobileCaseIcon extends Component {
  renderIcon = () => {
    const { icon } = this.props;
    switch (true) {
      case icon.includes('add'):
        return <AddCase />;
      case icon.includes('hide'):
        return <HideCase />;
      case icon.includes('sort'):
        return <SortCase />;
      case icon.includes('delete'):
        return <DeleteCase />;
      case icon.includes('barcode'):
        return <BarcodeCase />;
    }
  };
  render() {
    return <div className={this.props.className}>{this.renderIcon()}</div>;
  }
}

export default MobileCaseIcon;

MobileCaseIcon.propTypes = {
  icon: PropTypes.string,
  className: PropTypes.string
};
