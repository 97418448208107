import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import history from '../../../config/history';
import configAxios from '../../../config/axios';
import { addUserData } from '../../../store/actions/actions';

class Auth extends Component {
  state = {};

  componentDidMount() {
    const { dispatch, location } = this.props;

    let str = location.search;
    const token = str.substr(str.indexOf('=') + 1);

    localStorage.clear();
    dispatch(addUserData({}));

    if (token) {
      this.getUserData(token);
    }
  }

  getUserData = token => {
    configAxios.defaults.headers = {
      Authorization: 'Bearer ' + token
    };

    configAxios({
      method: 'GET',
      url: '/user/me',
      params: {
        expand: 'company'
      }
    }).then(response => {
      localStorage.userData = JSON.stringify(response.data);
      history.push('/');
    });
  };

  render() {
    return <div />;
  }
}

Auth.propTypes = {
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(connect(state => state)(Auth));
