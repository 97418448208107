import Shop from './Shop';

export default class ShopFactory {
  static createFromServerData({ id, original_id, shop_code, text }) {
    return new Shop(
      Number(id),
      Number(original_id),
      shop_code,
      text
    );
  }
}