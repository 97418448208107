export default {
  mobileFirefoxAlert: 'Di browser ini pemrosesan tugas tidak tersedia.',
  datePicker: {
    from: 'Dari',
    to: 'Hingga',
    cancel: 'Batal',
    apply: 'Terapkan',
    month: [
      'Januari',
      'Februari',
      'Maret',
      'April',
      'Mei',
      'Juni',
      'Juli',
      'Agustus',
      'September',
      'Oktober',
      'November',
      'Desember'
    ],
    week: 'minggu',
    weekShot: 'mgg',
    buttonName: {
      date: 'Tanggal',
      week: 'Minggu',
      month: 'Bulan'
    },
    modal: {
      header: 'Peringatan',
      text: 'Rentang tanggal yang dipilih tidak valid',
      action: 'Terima'
    }
  },
  filters: {
    name: 'Nama'
  },
  header: {
    logout: 'Keluar',
    changePassword: 'Ubah kata sandi',
    settings: 'Pengaturan',
    title: 'Lihat antarmuka pengguna',
    searchTitle: 'Cari pengguna',
    input: 'Mulai mengetik nama atau login...',
    cancel: 'Batal',
    view: 'Lihat',
    id: 'ID',
    login: 'Login',
    name: 'Nama',
    phone: 'Telepon',
    email: 'Email',
    stopSettings: 'Hentikan tampilan'
  },
  scenario: {
    noDataReceived: 'Tidak ada data yang diterima',
    filterPart: {
      name: 'Nama',
      clear: 'Bersihkan',
      notFound: 'Tidak ditemukan',
      search: 'Cari...'
    }
  },
  directTask: {
    addCase: 'Tambah kasus',
    addComment: 'Komentar',
    actions: 'Tindakan',
    searchProduct: 'PLU/Kode batang',
    searchProductShort: 'PLU/Kode batang',
    photo: 'Foto',
    back: 'Kembali',
    treeEmpty: 'TINDAKAN TIDAK TERSEDIA',
    step: 'Langkah',
    sortingButton: 'Pengurutan',
    sortButton: 'Urut',
    add: 'Tambah',
    delete: 'Hapus',
    deleteManualCases: 'Menghapus tugas',
    show: 'Tampilkan',
    hide: 'Sembunyikan',
    comment: 'Komentar',
    showDoneCases: 'Tampilkan tugas yang sudah selesai',
    hideDoneCases: 'Sembunyikan tugas yang sudah selesai'
  },
  gridTable: {
    pluList: 'Daftar PLU'
  },
  casesForm: {
    product: 'Produk',
    loadFoto: 'Muat Foto',
    plu: 'Nama/PLU',
    success: 'Kasus telah dibuat',
    add: 'Membuat',
    new: 'Baru',
    delete: 'Menghapus',
    cancel: 'Membatalkan',
    comment: 'Komentar'
  },
  pagination: {
    apply: 'Pergi',
    placeholder: 'Halaman'
  },
  restorePass: {
    noLength: 'Kata sandi harus memiliki setidaknya 12 karakter',
    noLowercase: 'Kata sandi harus memiliki setidaknya satu huruf kecil',
    noUppercase: 'Kata sandi harus memiliki setidaknya satu huruf besar',
    noNumber: 'Kata sandi harus memiliki setidaknya satu simbol numerik',
    noMatch: 'Kata sandi tidak cocok',
    password: 'Kata sandi',
    repeatPassword: 'Ulangi kata sandi',
    ok: 'Terima',
    cancel: 'Batal'
  },
  navigation: {
    missions: 'Misi',
    scenarios: 'Skenario'
  },
  changePassword: {
    previousPassword: 'Kata sandi saat ini',
    newPassword: 'Kata sandi baru',
    confirmPassword: 'Kata sandi baru sekali lagi',
    apply: 'Terapkan',
    title: 'Ubah kata sandi',
    success: 'Kata sandi berhasil diubah',
    wrongPassword: 'Kata sandi salah',
    weakPassword: 'Kata sandi tidak memenuhi persyaratan',
    passwordsNotMatch: 'Kata sandi tidak cocok',
    requirements: 'Kata sandi baru harus memiliki setidaknya 12 karakter, 1 huruf besar dan kecil, dan 1 nomor' +
      'Kata sandi baru harus memiliki setidaknya 12 karakter, 1 huruf besar dan kecil, dan 1 nomor'
  },
  auth: {
    login: 'Login',
    password: 'Kata sandi',
    restorePass: 'Pulihkan kata sandi',
    submit: 'Kirim',
    loginError: 'Login dan/atau kata sandi tidak valid'
  },
  restorePassForm: {
    restore: 'Pulihkan',
    cancel: 'Batal',
    info: 'Harap masukkan email Anda yang terdaftar di Platform Hibrida OSA',
    emailInvalid: 'Email yang dikirim tidak ditemukan',
    email: 'Email',
    success: 'Tautan pemulihan telah dikirim ke email Anda'
  },
  search: {
    notFound: 'Tidak ditemukan'
  },
  noMatchPage: {
    title: 'Halaman yang diminta tidak ditemukan',
    body: 'Kembali ke beranda'
  },
  warnings: {
    title: 'Peringatan',
    isDesktop: 'Mengunggah foto hanya tersedia di versi mobile sistem. Gunakan kamera ponsel atau tablet Anda, silakan.'
  },
  errors: {
    netErrorText: 'Kesalahan jaringan. Harap muat ulang halaman',
    netErrorReload: 'Muat ulang',
    netErrorClose: 'Tutup',
    netErrorTitle: 'Kesalahan',
    noShops: 'OH! Sepertinya belum ada toko yang ditetapkan untuk Anda. Beritahu manajer Anda tentang hal itu',
    noCases: 'Tampaknya layanan kami tidak dapat menemukan satu pun kasing di toko ini. Mulailah membuatnya sendiri',
    noAutoOrder: 'Tidak ada tugas untuk menyesuaikan pesanan otomatis',
    causeError: 'Kesalahan',
    scenariosEmpty: 'Anda tidak memiliki skrip yang ditetapkan'
  },
  officeUI: {
    resetZoom: 'Atur ulang zoom'
  },
  gridAutocomplete: {
    placeholder: 'Mulai mengetik nama toko',
    notFound: 'Toko tidak ditemukan'
  },
  targets: {
    nothing: '',
    shop: 'Toko',
    category: 'Kategori',
    supplier: 'Pemasok',
    vendor: 'Penjual',
    brand: 'Merek',
    plu: 'PLU',
    day: 'Hari',
    week: 'Minggu',
    month: 'Bulan',
    quarter: 'Kuartal',
    year: 'Tahun'
  },
  bubbleChart: {
    qty: 'qty',
    currency: 'IDR'
  },
  chart: {
    quarter: 'kuartal',
    week: 'minggu'
  },
  download: {
    CSV: 'Unduh CSV',
    XLS: 'Unduh XLS'
  },
  counterTask: {
    task: 'Tugas tertunda',
    noTask: 'Tidak ada tugas'
  },
  filtersShop: {
    shopName: 'Alamat toko',
    shopId: 'Nama toko'
  },
  chartCaption: {
    thousand: 'rb',
    million: 'jt',
    billion: 'mld'
  },
  common: {
    close: 'Tutup',
    userNotFound: 'Pengguna tidak ditemukan',
    yes: 'Ya',
    no: 'Tidak',
  },
  virtualStock: {
    headers: {
      case_id: "ID",
      plu_original: "PLU",
      plu: "PLU internal",
      shop_id: "ID Toko",
      ass_type: "Jenis Assortmen",
      category_id3: "ID Bagian",
      category_id4: "ID Kategori",
      stock: "Volume Sisa",
      stock_sum: "Jumlah Sisa",
      product_turnover: "Perputaran (turnover)",
      days_without_sales: "Hari tanpa penjualan",
      last_sales_date: "Tanggal penjualan terakhir",
      last_sales_volume: "Volume penjualan terakhir",
      last_shipment_date: "Tanggal pengiriman terakhir",
      last_shipment_volume: "Volume pengiriman terakhir",
      promo_start: "Mulai Promo",
      promo_end: "Akhiri Promo",
      promo_period: "Periode Promo",
      is_promo: "Promo",
      title: "Nama",
      category_name3: "Bagian",
      category_name4: "Kategori"
    },
    hideReadyTasks: "Sembunyikan tugas yang selesai",
    nothingFound: "Tidak Ada yang Ditemukan",
    shopsNotFound: "Toko tidak ditemukan",
    chooseShop: "Pilih toko",
    createTasks: "Buat tugas",
    taskCreating: "Membuat tugas",
    next: "Lanjut",
    columns: "Kolom",
    from: "Dari",
    ok: "OK",
    cancel: "Batal",
    filter: "Filter",
    chooseValue: "Pilih Nilai",
    enterValue: "Masukkan Nilai",
    rangeFrom: "Dari",
    rangeTo: "Ke",
    selected: "Dipilih",
    foundRows: "Baris Ditemukan",
    createdTasksTotal: "Total Tugas yang Dibuat"
  }
};
