export default class DateUtil {
  /**
   * Result example: 2023-04-20
   * @returns {string}
   */
  static nowIsoDate() {
    return DateUtil.toIsoDate(new Date());
  }

  /**
   * Result example: 2023-04-20 14:12:00
   * @returns {string}
   */
  static nowIsoDateTime() {
    return DateUtil.toIsoDateTime(new Date());
  }

  /**
   * Result example: 2023-04-20
   * @returns {string}
   */
  static nowUtcIsoDate() {
    return DateUtil.toUtcIsoDate(new Date());
  }

  /**
   * Result example: 2023-04-20 14:20:53
   * @returns {string}
   */
  static nowUtcIsoDateTime() {
    return DateUtil.toUtcIsoDateTime(new Date());
  }

  /**
   * Result example: 2023-04-20
   * @param {Date} date
   * @returns {string}
   */
  static toIsoDate(date) {
    return [
      date.getFullYear(),
      ('0' + (date.getMonth() + 1)).slice(-2),
      ('0' + date.getDate()).slice(-2),
    ].join('-');
  }

  /**
   * Result example: 2023-04-20 14:20:00
   * @param {Date} date
   * @returns {string}
   */
  static toIsoDateTime(date) {
    return [
      [
        date.getFullYear(),
        ('0' + (date.getMonth() + 1)).slice(-2),
        ('0' + date.getDate()).slice(-2),
      ].join('-'),
      [
        ('0' + date.getHours()).slice(-2),
        ('0' + date.getMinutes()).slice(-2),
        ('0' + date.getSeconds()).slice(-2),
      ].join(':')
    ].join(' ');
  }

  /**
   * Result example: 2023-04-20
   * @param {Date} date
   * @returns {string}
   */
  static toUtcIsoDate(date) {
    return [
      date.getUTCFullYear(),
      ('0' + (date.getUTCMonth() + 1)).slice(-2),
      ('0' + date.getUTCDate()).slice(-2),
    ].join('-');
  }

  /**
   * Result example: 2023-04-20 14:20:00
   * @param {Date} date
   * @returns {string}
   */
  static toUtcIsoDateTime(date) {
    return [
      [
        date.getUTCFullYear(),
        ('0' + (date.getUTCMonth() + 1)).slice(-2),
        ('0' + date.getUTCDate()).slice(-2),
      ].join('-'),
      [
        ('0' + date.getUTCHours()).slice(-2),
        ('0' + date.getUTCMinutes()).slice(-2),
        ('0' + date.getUTCSeconds()).slice(-2),
      ].join(':')
    ].join(' ');
  }

  /**
   * Result example: 20.04.2023
   * @param {Date} date
   * @returns {string}
   */
  static displayDate(date) {
    return [
      ('0' + date.getDate()).slice(-2),
      ('0' + (date.getMonth() + 1)).slice(-2),
      date.getFullYear(),
    ].join('.');
  }

  /**
   * @param {Date} date
   * @returns {boolean}
   */
  static isToday(date) {
    return DateUtil.toIsoDate(date) === DateUtil.toIsoDate(new Date());
  }

  /**
   * @param {Date} date
   * @returns {number}
   */
  static diffDaysFromToday(date) {
    return Math.ceil(Math.abs(date - new Date()) / (1000 * 60 * 60 * 24));
  }
}