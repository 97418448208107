import React, { Component } from 'react';

import PropTypes from 'prop-types';

const IconCopy = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Слой_1"
      x="0"
      y="0"
      enableBackground="new 0 0 512 512"
      version="1.1"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      fill="#2070B0"
      style={{ width: "20px", height: "20px" }}
    >
      <path
        d="M311 512H120c-44.1 0-80-35.9-80-80V161c0-44.1 35.9-80 80-80h191c44.1 0 80 35.9 80 80v271c0 44.1-35.9 80-80 80zM120 121c-22.1 0-40 17.9-40 40v271c0 22.1 17.9 40 40 40h191c22.1 0 40-17.9 40-40V161c0-22.1-17.9-40-40-40H120zm351 261V80c0-44.1-35.9-80-80-80H169c-11 0-20 9-20 20s9 20 20 20h222c22.1 0 40 17.9 40 40v302c0 11 9 20 20 20s20-9 20-20z"
        className="st0"
        fill="#2070B0"
      />
    </svg>
  );
};

class IconFilters extends Component {
  renderIcon = () => {
    const { icon } = this.props;
    switch (true) {
      case icon.includes('copy'):
        return <IconCopy />;
    }
  };

  render() {
    return <div>{this.renderIcon()}</div>;
  }
}

export default IconFilters;

IconFilters.propTypes = {
  icon: PropTypes.string,
};
