import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-flexbox-grid';
import Widget from '../../components/Widget/Widget';
import WidgetDownload from '../../components/WidgetDownload/WidgetDownload';
import Chart from '../../components/Chart';
import ComposedChart from '../../components/Chart/ComposedChart';
import PieChart from '../../components/Chart/PieChart';
import Table from '../../components/ScenarioTable';
import Group from '../../components/Group';
import UpdatesController from './UpdatesController';
import RedashLink from '../../components/RedashLink';

import styles from './ScenarioContent.css';

class ScenarioContent extends Component {
  /**
   *
   * @param props
   */
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.content !== this.props.content;
  }

  render() {
    const { content, filters, setDrillFilters, updateFilters, link } = this.props;
    return (
      <div>
        {content &&
          content.map((item, indexRow) => {
            const isGroup = item[0].type === 'group';
            const key = '' + indexRow;
            const containerWrap = item[0].type === 'group' ? styles.containerRowGroup : styles.containerRow;
            return (
              <Row key={key} className={`${containerWrap} ${styles.customContainerRow}`}>
                <div className={!isGroup ? styles.scenarioContainer : ''}>
                  {item.map((item, indexItem) => {
                    const key = '' + indexRow + indexItem;
                    const { width } = item;
                    switch (item.type) {
                      case 'widget':
                        return (
                          <UpdatesController width={width.toString()} key={key}>
                            <Widget
                              data={item}
                              filters={filters}
                              updateFilters={updateFilters}
                              setDrillFilters={setDrillFilters}
                            />
                          </UpdatesController>
                        );
                      case 'report':
                        return <WidgetDownload data={item} key={key} />;
                      case 'chart':
                        if (item.data.type === 'bubble' || item.data.type === 'scatter') {
                          return (
                            <UpdatesController width={width} key={key}>
                              <ComposedChart
                                data={item}
                                filters={filters}
                                updateFilters={updateFilters}
                                setDrillFilters={setDrillFilters}
                              />
                            </UpdatesController>
                          );
                        }
                        if (item.data.source[0].type === 'pie') {
                          return (
                            <UpdatesController width={width} key={key}>
                              <PieChart
                                data={item}
                                filters={filters}
                                updateFilters={updateFilters}
                                setDrillFilters={setDrillFilters}
                              />
                            </UpdatesController>
                          );
                        } else {
                          return (
                            <UpdatesController width={width} key={key}>
                              <Chart
                                data={item}
                                filters={filters}
                                updateFilters={updateFilters}
                                setDrillFilters={setDrillFilters}
                              />
                            </UpdatesController>
                          );
                        }
                      case 'table':
                        return (
                          <UpdatesController width={width} key={key}>
                            <Table
                              data={item}
                              filters={filters}
                              updateFilters={updateFilters}
                              setDrillFilters={setDrillFilters}
                            />
                          </UpdatesController>
                        );
                      case 'group':
                        return (
                          <Group
                            key={key}
                            dataGroup={item}
                            filters={filters}
                            updateFilters={updateFilters}
                            setDrillFilters={setDrillFilters}
                          />
                        );
                      default:
                        return false;
                    }
                  })}
                </div>
              </Row>
            );
          })}
          {link[`dashboard-link`] && <RedashLink link={link} />}
      </div>
    );
  }
}

ScenarioContent.defaultProps = {
  content: [],
  link: {},
};

ScenarioContent.propTypes = {
  content: PropTypes.array.isRequired, // eslint-disable-line
  filters: PropTypes.func.isRequired, // eslint-disable-line
  updateFilters: PropTypes.func.isRequired, // eslint-disable-line
  setDrillFilters: PropTypes.func.isRequired, // eslint-disable-line
  link: PropTypes.object.isRequired, // eslint-disable-line
};

export default ScenarioContent;
