import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import DateWeeksComponent from '../DateWeeksComponent/DateWeeksComponent';
import DateMonthComponent from '../DateMonthComponent/DateMonthComponent';
import DatePickerComponent from '../DatePickerComponent/DatePickerComponent';

import styles from './DateComponent.css';

class DateComponent extends Component {
  state = {
    show: 'date'
  };

  componentDidMount() {}

  handlerChangeTab = name => {
    this.setState({
      show: name
    });
  };

  render() {
    const {
      key,
      data,
      filters,
      updateFilters,
      setDrillFilters,
      language: { translation: { datePicker: { buttonName } = {} } = {} } = {}
    } = this.props;

    const { hold = '' } = data;

    const { show } = this.state;

    return (
      <div>
        <div className={styles.tabs}>
          <button
            onClick={() => this.handlerChangeTab('date')}
            className={`${styles.button} ${show === 'date' ? styles.activeButton : ''}`}
          >
            {buttonName.date}
          </button>
          <button
            onClick={() => this.handlerChangeTab('week')}
            className={`${styles.button} ${show === 'week' ? styles.activeButton : ''}`}
          >
            {buttonName.week}
          </button>
          <button
            onClick={() => this.handlerChangeTab('month')}
            className={`${styles.button} ${show === 'month' ? styles.activeButton : ''}`}
          >
            {buttonName.month}
          </button>
        </div>

        <div className={hold ? styles.content : ''}>
          <div>
            {show === 'date' && (
              <DatePickerComponent
                key={key}
                data={data}
                filters={filters}
                updateFilters={updateFilters}
                setDrillFilters={setDrillFilters}
              />
            )}
          </div>
          <div>
            {show === 'week' && (
              <DateWeeksComponent
                key={key}
                data={data}
                filters={filters}
                updateFilters={updateFilters}
                setDrillFilters={setDrillFilters}
              />
            )}
          </div>
          <div>
            {show === 'month' && (
              <DateMonthComponent
                key={key}
                data={data}
                filters={filters}
                updateFilters={updateFilters}
                setDrillFilters={setDrillFilters}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

DateComponent.propTypes = {
  language: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  key: PropTypes.string, // eslint-disable-line react/forbid-prop-types
  data: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  filters: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  updateFilters: PropTypes.func.isRequired,
  setDrillFilters: PropTypes.func.isRequired
};

export default connect(store => {
  return {
    language: store.language
  };
})(DateComponent);
