import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MDSpinner from 'react-md-spinner';

import styles from './Preload.css';

const Preload = ({ pageLoadingStatus }) => {
  const color = '#00A5E6';

  return (
    <div className={styles.container}>
      {pageLoadingStatus && (
        <div className={styles.preload}>
          <div className={styles.preloadInner}>
            <MDSpinner singleColor={color} size={40} />
          </div>
        </div>
      )}
    </div>
  );
};

Preload.defaultProps = {
  pageLoadingStatus: false
};

Preload.propTypes = {
  pageLoadingStatus: PropTypes.bool // eslint-disable-line
};

export default connect(store => {
  return {
    pageLoadingStatus: store.pageLoadingStatus
  };
})(Preload);
