export default class ContentUtil {
  /**
   * @param content
   * @param type
   * @param filename
   */
  static downloadContentAsFile(content, type, filename) {
    const blob = new Blob([content], { type: type });
    const link = document.createElement("a");
    const url = window.URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
    link.remove();
  }
}