import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import gtag from 'ga-gtag';
import configAxios from '../../config/axios';
import styles from './Auth.css';
import { addUserData, setPageLoadingStatus } from '../../store/actions/actions';
import Preload from '../../components/Preload';
import FAQ from '../../components/FAQ';
import { GA_ID } from '../../bootstrap/ga';

class Auth extends Component {
  /**
   *
   * @param props
   */
  constructor(props) {
    super(props);

    this.state = {
      errorResponseText: '',
      successResponseText: localStorage.successReset ? localStorage.successReset : '',
      login: '',
      password: '',
      email: '',
      formValid: true,
      formChanged: false,
      restorePass: false,
      loginValid: true,
      showErrorDialog: false,
      passwordValid: true,
      isLogin: false
    };

    localStorage.removeItem('successReset');
  }

  /**
   *
   * @param event
   * @param value
   */
  handleChangeLogin = (event, value) => {
    this.setState(
      {
        login: value
      },
      () => {
        return this.validateField('login', value);
      }
    );
  };

  /**
   *
   * @param event
   * @param value
   */
  handleChangePassword = (event, value) => {
    this.setState(
      {
        password: value
      },
      () => {
        return this.validateField('password', value);
      }
    );
  };

  /**
   *
   * @param event
   * @param value
   */
  handleChangeEmail = (event, value) => {
    this.setState({
      email: value
    });
  };

  /**
   *
   * @param fieldName
   * @param value
   */
  validateField(fieldName, value) {
    let loginValid = this.state.loginValid;
    let passwordValid = this.state.passwordValid;

    switch (fieldName) {
      case 'login':
        loginValid = value.length >= 1;
        break;
      case 'password':
        passwordValid = value.length >= 1;
        break;
      default:
        break;
    }

    this.setState({
      formChanged: true,
      formValid: loginValid && passwordValid,
      loginValid: loginValid,
      passwordValid: passwordValid
    });
  }

  /**
   *
   * @param e
   */
  handleSubmit = e => {
    e.preventDefault();
    const { dispatch } = this.props;
    dispatch(setPageLoadingStatus(true));

    if (!this.state.formValid) {
      return;
    }
    if (!this.state.isLogin) {
      const promise = configAxios({
        method: 'POST',
        url: '/user/sign-in?expand=company',
        data: {
          login: this.state.login,
          password: this.state.password
        }
      });

      promise.then(response => {
        if (process.env.REACT_APP_enable_google_analytics) {
          if (response.data.id !== undefined) {
            gtag('config', GA_ID, {
              user_id: response.data.id
            });
          }
          gtag('event', 'login');
        }
        localStorage.setItem('userHasImpersonation', response.headers.impersonalization === 'true');
        dispatch(setPageLoadingStatus(false));
        dispatch(addUserData(response.data));
        localStorage.userData = JSON.stringify(response.data);
        this.setState({ isLogin: true });
      });

      promise.catch(error => {
        if (error.response.status === 422) {
          dispatch(setPageLoadingStatus(false));
          this.setState({
            successResponseText: '',
            errorResponseText: error.response.data[0].message
          });
        }
      });
    }
  };

  /**
   *
   */
  changeStateWindow = () => {
    this.setState({
      restorePass: !this.state.restorePass,
      errorResponseText: '',
      successResponseText: ''
    });
  };

  /**
   *
   * @param e
   */
  handleRestorePassSubmit = e => {
    e.preventDefault();

    const { dispatch } = this.props;

    dispatch(setPageLoadingStatus(true));

    const promise = configAxios({
      method: 'POST',
      url: '/user/restore-pass',
      data: {
        email: this.state.email
      }
    });

    promise.then(response => {
      dispatch(setPageLoadingStatus(false));
      this.setState({
        errorResponseText: '',
        successResponseText: response.data.message
      });
    });

    promise.catch(error => {
      console.log(error);
      dispatch(setPageLoadingStatus(false));
      if (error.response.status === 422) {
        this.setState({
          errorResponseText: error.response.data[0].message
        });
      }
    });
  };

  render() {
    const { translation } = this.props.language;
    const loginForm = (
      <div className={styles.wrapperAuth}>
        <form onSubmit={this.handleSubmit} className={styles.authBlock}>
          <div className={styles.logo} />
          <div
            className={styles.formError + ' ' + (this.state.errorResponseText !== '' ? styles.formErrorVisible : '')}
          >
            {this.state.errorResponseText}
          </div>
          <div
            className={
              styles.formSuccess + ' ' + (this.state.successResponseText !== '' ? styles.formSuccessVisible : '')
            }
          >
            {this.state.successResponseText}
          </div>
          <div className={styles.inputContainer}>
            <label htmlFor="login" className={styles.inputWrap}>
              <Field
                onChange={this.handleChangeLogin}
                name="login"
                id="login"
                component="input"
                type="text"
                placeholder={translation.auth.login}
                className={
                  styles.input + ' ' + (!this.state.loginValid && this.state.formChanged ? styles.inputError : '')
                }
              />
            </label>
          </div>
          <div className={styles.inputContainer}>
            <label htmlFor="password" className={styles.inputWrap}>
              <Field
                onChange={this.handleChangePassword}
                name="password"
                id="password"
                component="input"
                type="password"
                placeholder={translation.auth.password}
                className={
                  styles.input + ' ' + (!this.state.passwordValid && this.state.formChanged ? styles.inputError : '')
                }
              />
            </label>
          </div>
          <div className={styles.containerButton}>
            <button type="submit" className={styles.btn}>
              {translation.auth.submit}
            </button>
            <Button className={styles.restorePass} onClick={this.changeStateWindow}>
              {translation.auth.restorePass}
            </Button>
          </div>
          <FAQ path="auth" />
        </form>
      </div>
    );

    const restorePassForm = (
      <div className={styles.wrapperAuth}>
        <form onSubmit={this.handleRestorePassSubmit} className={styles.authBlock}>
          <div className={styles.logo} />
          <div
            className={styles.formError + ' ' + (this.state.errorResponseText !== '' ? styles.formErrorVisible : '')}
          >
            {translation.restorePassForm.emailInvalid}
          </div>
          <div
            className={
              styles.formSuccess + ' ' + (this.state.successResponseText !== '' ? styles.formSuccessVisible : '')
            }
          >
            {translation.restorePassForm.success}
          </div>
          <div className={styles.topText}>
            <p>{translation.restorePassForm.info}</p>
          </div>
          <div className={styles.inputContainer}>
            <label htmlFor="login" className={styles.inputWrap}>
              <Field
                onChange={this.handleChangeEmail}
                name="email"
                id="email"
                component="input"
                type="email"
                placeholder={translation.restorePassForm.email}
                className={styles.input}
              />
            </label>
          </div>
          <div className={styles.containerButton}>
            <button type="submit" className={styles.btn}>
              {translation.restorePassForm.restore}
            </button>
            <Button className={styles.restorePass} onClick={() => this.changeStateWindow(this.state.restorePass)}>
              {translation.restorePassForm.cancel}
            </Button>
          </div>
          <FAQ path="auth" />
        </form>
      </div>
    );

    return (
      <div>
        {this.state.restorePass ? restorePassForm : loginForm}
        <Preload />
      </div>
    );
  }
}

Auth.propTypes = {
  dispatch: PropTypes.func.isRequired,
  language: PropTypes.object.isRequired
};

const AuthExport = connect(state => ({
  language: state.language
}))(Auth);

export default reduxForm({
  form: 'Auth' // a unique identifier for this form
})(AuthExport);
