import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import axios from 'axios';
import configAxios from '../../config/axios';
import Preload from '../../constructors/Preload';
import {
  setDatePickerAction,
  setDrillDownScenario,
  setPageLoadingStatus,
  setParentScenario,
  setOldParentScenario
} from '../../store/actions/actions';
import IsTimeTarget from '../TimeTarget';

class UiElement extends Component {
  constructor(props) {
    super(props);

    this.preload = new Preload();
    this.preload.subscribe(promises => {
      this.props.dispatch(setPageLoadingStatus(promises.length > 0));
    });
  }

  groupTransition = (filters = {}, target = null) => {
    const { updateFilters } = this.props;
    if (target) {
      updateFilters(filters, IsTimeTarget(target) ? 'date' : target, true, true);
    }
  };

  getSubScenarioData = (subScenarioId, filters = {}, target = null) => {
    const { dispatch, setDrillFilters, updateFilters, parentScenario } = this.props;

    dispatch(setOldParentScenario(parentScenario));

    const query = new URLSearchParams(location.search);
    const mid = query.has('mid') ? Number(query.get('mid')) : JSON.parse(localStorage.mission).id;

    const params = {
      mission: mid,
      parent: parentScenario,
      ...filters
    };
    const createCancelToken = () => axios.CancelToken.source();
    const promise = configAxios({
      method: 'GET',
      url: `/scenario/${subScenarioId}`,
      params,
      cancelToken: createCancelToken.token
    }).then(
      response => {
        response.id = subScenarioId;
        setDrillFilters(filters);
        dispatch(setDrillDownScenario(response));
        dispatch(setParentScenario(subScenarioId));
        if (target) {
          updateFilters(filters, IsTimeTarget(target) ? 'date' : target, false);
        }
        this.updateDatePicker(filters.date_start, filters.date_end);
      },
      error => {
        console.error(error);
      }
    );

    this.preload.add(promise);
  };

  updateDatePicker = (from, to) => {
    this.props.dispatch(
      setDatePickerAction({
        minDate: from,
        maxDate: to
      })
    );
  };

  prepareFilter = (target, resultSetItem, transition, isDateUpdate) => {
    const value = resultSetItem.id;
    const actualFilters = this.props.filters();
    const isTimeTarget = IsTimeTarget(target);
    let filter;
    if (transition) {
      const obj = Object.assign({}, actualFilters);
      if (!isDateUpdate.maxDate) {
        delete obj.date_start;
        delete obj.date_end;
      }
      filter = { ...obj, [target]: [value] };
    } else {
      filter = Object.assign(actualFilters, {
        [target]: [value]
      });
    }
    let dateStart, dateEnd;

    if (isTimeTarget) {
      const endTargetMarket = target === 'week' ? 'isoWeek' : target;
      const date = moment(value);
      // calculate range
      dateStart = date.format('YYYY-MM-DD');
      dateEnd = date.endOf(endTargetMarket).format('YYYY-MM-DD');

      dateStart = new Date(actualFilters.date_start) > new Date(dateStart) ? actualFilters.date_start : dateStart;

      if (!transition && target === 'month' && !actualFilters.date_end) {
        if (new Date(dateEnd) > moment()) {
          dateEnd =
            new Date(actualFilters.date_end) < moment() ? actualFilters.date_end : moment().format('YYYY-MM-DD');
        }
      } else {
        dateEnd = new Date(actualFilters.date_end) < new Date(dateEnd) ? actualFilters.date_end : dateEnd;
      }

      filter.date_start = dateStart;
      filter.date_end = dateEnd;
      this.updateDatePicker(dateStart, dateEnd);
    } else {
      dateStart = new Date(actualFilters.date_start);
      dateEnd = new Date(actualFilters.date_end);
    }

    return filter;
  };
}

UiElement.propTypes = {
  dispatch: PropTypes.func.isRequired,
  filters: PropTypes.func.isRequired,
  setDrillFilters: PropTypes.func.isRequired,
  updateFilters: PropTypes.func.isRequired,
  scenario: PropTypes.object.isRequired, // eslint-disable-line
  saveParenID: PropTypes.func.isRequired, // eslint-disable-line
  parentScenario: PropTypes.number
};

export default UiElement;
