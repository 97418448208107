import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'react-toolbox/lib/dialog';
import { connect } from 'react-redux';

import styles from './ErrorDialogSelect.css';

class ErrorDialog extends Component {
  constructor(props) {
    super(props);
    const { translation } = this.props.language;
    this.state = {
      active: false,
    };
    this.actions = [
      {
        label: translation.common.close,
        onClick: this.props.handleDialogState,
        className: styles.buttonOk,
      },
    ];
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.active !== this.state.active) {
      this.setState({
        active: nextProps.active
      });
    }
  }

  handleToggle = () => {
    this.props.handleDialogState();
  };

  evalHtml(html) {
    return { __html: html };
  }

  render() {
    const { translation } = this.props.language;
    const title = this.props.title || translation.errors.netErrorTitle;
    const errorText = this.props.errorText || translation.errors.causeError;
    return (
      <Dialog
        actions={this.actions}
        active={this.state.active}
        className={styles.errorDialog}
        onEscKeyDown={this.handleToggle}
        onOverlayClick={this.handleToggle}
        title={title}
        type="normal"
      >
        <p dangerouslySetInnerHTML={this.evalHtml(errorText)} />
      </Dialog>
    );
  }
}

ErrorDialog.defaultProps = {
  active: false,
  title: '',
  errorText: '',
  handleDialogState: '',
};

ErrorDialog.propTypes = {
  active: PropTypes.bool,
  title: PropTypes.string,
  errorText: PropTypes.string,
  handleDialogState: PropTypes.func,
  language: PropTypes.object.isRequired,
};

const mapStateToProps = store => ({
  language: store.language,
});

export default connect(mapStateToProps)(ErrorDialog);
