import React, { Component } from 'react';
import PropTypes from 'prop-types';
import gtag from 'ga-gtag';
import { connect } from 'react-redux';
import { GA_ID } from './index';

class GoogleAnalytics extends Component {

  componentDidMount() {
    this.logPageChange(this.props.location.pathname, this.props.location.search);
  }

  componentDidUpdate({ location: prevLocation }) {
    const {
      location: { pathname, search }
    } = this.props;

    const isDifferentPathname = pathname !== prevLocation.pathname;
    const isDifferentSearch = search !== prevLocation.search;

    if (isDifferentPathname || isDifferentSearch) {
      this.logPageChange(pathname, search);
    }
  }

  logPageChange(pathname, search = '') {
    const userData = localStorage.userData ? JSON.parse(localStorage.userData) : null;
    const page = pathname + search;
    const { location } = window;

    if (userData && userData.id !== undefined) {
      gtag('set', {
        user_id: userData.id
      });
    }

    gtag('config', GA_ID, {
      page_path: page,
      page_location: `${location.origin}${page}`
    });
  }

  render() {
    return null;
  }
}

GoogleAnalytics.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string
  }).isRequired
};

export default connect(store => store)(GoogleAnalytics);
