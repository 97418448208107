export const addUserData = userData => {
  return {
    type: 'ADD_USERDATA',
    userData
  };
};

export const initLocal = lang => {
  return {
    type: 'INIT_LOCAL',
    lang
  };
};

export const changePage = page => {
  return {
    type: 'CHANGE_PAGE',
    page
  };
};

export const loadTask = (initPage, hidePreload = false) => {
  return {
    type: 'LOAD_TASK',
    initPage,
    hidePreload
  };
};

export const chooseMission = mission => {
  localStorage.setItem('mission', JSON.stringify(mission));
  return {
    type: 'CHOOSE_MISSION',
    mission
  };
};

export const chooseScenario = scenario => {
  return {
    type: 'CHOOSE_SCENARIO',
    scenario
  };
};

export const loadScenarios = scenarios => {
  return {
    type: 'LOAD_SCENARIOS',
    scenarios
  };
};

export const showError = (showError, errorMsg) => {
  return {
    type: 'SHOW_ERROR',
    showError,
    errorMsg
  };
};

export const showKpiDebugAction = data => {
  return {
    type: 'KPI_DEBUG_SHOW',
    payload: data
  };
};

export const kpiDebugHandle = data => {
  return {
    type: 'KPI_DEBUG_HANDLE',
    payload: data
  };
};

export const showErrorNotAllowed = (showError, errorMsg) => {
  return {
    type: 'SHOW_ERROR_NOT_ALLOWED',
    showError,
    errorMsg
  };
};

export const showNetworkError = (showError, errorMsg) => {
  return {
    type: 'SHOW_NETWORK_ERROR',
    showError,
    errorMsg
  };
};

export const searchProduct = id => {
  return {
    type: 'SEARCH_PRODUCT',
    id
  };
};

export const setPageLoadingStatus = status => {
  return {
    type: 'SET_PAGE_LOADING_STATUS',
    status
  };
};

export const setDrillDownScenario = data => {
  return {
    type: 'CHOOSE_DRILLDOWN_SCENARIO',
    drillDownScenario: data
  };
};

export const setBlockingContent = status => {
  return {
    type: 'SET_BLOCKING_CONTENT',
    status
  };
};

export const setDatePickerAction = data => {
  return {
    type: 'SET_DATEPICKER_DATE',
    data
  };
};

export const setGridDatePickerAction = data => {
  return {
    type: 'SET_GRID_DATEPICKER_DATE',
    data
  };
};

export const clearAllFilters = data => {
  return {
    type: 'SET_CLEAR_FILTERS',
    data
  };
};

export const setToggleCasesAction = data => {
  return {
    type: 'SET_TOGGLE_CASES',
    data
  };
};

export const setGroupTabAction = data => {
  return {
    type: 'SET_GROUP_TAB',
    data
  };
};

export const setParentScenario = id => {
  return {
    type: 'SET_PARENT_SCENARIO',
    id
  };
};

export const setOldParentScenario = id => {
  return {
    type: 'SET_OLD_PARENT_SCENARIO',
    id
  };
};

export const toggleImpersonation = flag => {
  return {
    type: 'TOGGLE_IMPERSONATION',
    flag
  };
};
