import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import ListItem from '@material-ui/core/ListItem';
import { Checkbox } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DragIndicator from '@material-ui/icons/DragIndicator';
import styles from './DraggableListItem.css';

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const DraggableListItem = ({ item, index, disabled, checked, onChange }) => {
  const handleChange = e => {
    onChange(e.target.checked);
  };

  return (
    <Draggable draggableId={item.property} index={index}>
      {(provided, snapshot) => (
        <ListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={[styles.listItem, snapshot.isDragging ? styles.draggingListItem : '']}
        >
          <FormControlLabel
            classes={{ root: styles.itemControlRoot }}
            control={
              <Checkbox
                checked={checked}
                disabled={disabled}
                onChange={handleChange}
                classes={{ root: styles.itemCheckboxRoot }}
              />
            }
            label={item.name}
          />
          <DragIndicator className={styles.dragIndicator} />
        </ListItem>
      )}
    </Draggable>
  );
};

DraggableListItem.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default connect(
  store => {
    return {
      language: store.language
    };
  }
)(DraggableListItem);