import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import FilterPartBodyItem from './Item';

import styles from './Body.css';

class FilterPartBody extends Component {
  /**
   *
   * @type {number}
   */
  static ITEM_HEIGHT_ABOUT = 40;

  /**
   *
   * @type {number}
   */
  static BIG_LIST_CONTAINER_HEIGHT = 260;

  /**
   *
   * @type {number}
   */
  static BIG_LIST_INFINITE_BEGIN_OFFSET = 200;

  /**
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      isFilterOpen: ''
    };
  }

  /**
   *
   * @param nextProps
   * @returns {boolean}
   */
  componentWillReceiveProps(nextProps) {
    const nextValues = nextProps.contentOpened;

    this.setState({
      isFilterOpen: nextValues
    });
  }
  shouldComponentUpdate(nextProps) {
    const nextValues = nextProps.values;
    const { values, contentOpened } = this.props;

    if (contentOpened !== nextProps.contentOpened) {
      return true;
    }

    if (nextProps.kpi !== this.props.kpi || nextProps.items !== this.props.items) {
      return true;
    }

    if (nextValues.length !== values.length) {
      return true;
    }

    if (nextValues.length === 0 && values.length === 0) {
      return false;
    }

    return !nextValues.every(item => {
      return values.indexOf(item) !== -1;
    });
  }

  onInfiniteLoad = () => {
    const { source, loadMoreItems } = this.props;

    if (source !== 'category') {
      loadMoreItems();
    }
  };

  /**
   *
   * @param item
   */
  handleLoadLevel = item => {
    this.props.loadLevel(item);
  };

  /**
   *
   * @param item
   */
  handleChooseItem = item => {
    this.props.chooseItem(item);
  };

  /**
   *
   * @returns {XML}
   */
  render() {
    const { kpi, items, values, singleLevel, language, loading } = this.props;

    const { isFilterOpen } = this.state;
    const filterPartTranslation = language.translation.scenario.filterPart;

    const collection =
      items.length > 0
        ? items.map(item => {
          if (!item.name) {
            return;
          }
          return (
            <FilterPartBodyItem
              key={item.id}
              kpi={kpi}
              item={item}
              isActive={values.indexOf(item.id) !== -1}
              singleLevel={singleLevel}
              loadLevel={this.handleLoadLevel}
              chooseItem={this.handleChooseItem}
              contentIsOpened={isFilterOpen}
            />
          );
        })
        : [];

    return (
      <div className={styles.bodyWrap}>
        {!loading && items.length === 0 ? <div className={styles.empty}>{filterPartTranslation.notFound}</div> : ''}
        {items.length > 0 && items.length <= 10 && <div className={styles.body}>{collection}</div>}
        {items.length > 10 && (
          <InfiniteScroll pageStart={0} loadMore={this.onInfiniteLoad} hasMore={true || false} useWindow={false}>
            {collection}
          </InfiniteScroll>
        )}
      </div>
    );
  }
}

FilterPartBody.defaultProps = {
  kpi: [],
  items: [],
  values: {},
  contentOpened: false
};

FilterPartBody.propTypes = {
  kpi: PropTypes.array.isRequired, // eslint-disable-line
  items: PropTypes.array.isRequired, // eslint-disable-line
  values: PropTypes.array.isRequired, // eslint-disable-line
  singleLevel: PropTypes.bool.isRequired, // eslint-disable-line
  loadLevel: PropTypes.func.isRequired, // eslint-disable-line
  chooseItem: PropTypes.func.isRequired, // eslint-disable-line
  language: PropTypes.object.isRequired, // eslint-disable-line
  source: PropTypes.string.isRequired,
  loadMoreItems: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  contentOpened: PropTypes.bool.isRequired // eslint-disable-line
};

export default connect(store => {
  return {
    language: store.language
  };
})(FilterPartBody);
