import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Dialog, Button, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { IconButton } from 'react-toolbox/lib/button';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import styles from './ColumnEditor.css';
import DraggableListItem from './DraggableListItem/DraggableListItem';

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ColumnEditor = props => {
  const { language, headers, onChange } = props;

  const [active, setActive] = useState(false);
  const [map, setMap] = useState({});
  const [orderedHeaders, setOrderedHeaders] = useState([...headers]);

  const translation = useMemo(() => {
    return language.translation ? language.translation.virtualStock : {};
  }, [language]);

  const handleOpen = () => {
    setActive(true);
  };

  /**
   *
   * @param header
   * @param val
   */
  const handleHeaderChange = (header, val) => {
    const nextMap = { ...map };
    nextMap[header.property] = [header, val];
    setMap(nextMap);
  };

  const handleCancel = () => {
    setActive(false);
    setTimeout(() => {
      setMap({});
      setOrderedHeaders([...headers]);
    }, 1000);
  };

  const handleOk = () => {
    setActive(false);
    Object.keys(map).forEach(property => {
      const [header, val] = map[property];
      header.setVisible(val);
    });

    onChange(orderedHeaders);
  };

  const visibleLength = orderedHeaders.filter(header => header.visible).length;

  /**
   *
   * @param destination
   * @param source
   * @returns {unknown[]}
   */
  const handleDragEnd = ({ destination, source }) => {
    if (!destination) {
      return;
    }

    const nextOrderedHeaders = Array.from(orderedHeaders);
    const [removed] = nextOrderedHeaders.splice(source.index, 1);
    nextOrderedHeaders.splice(destination.index, 0, removed);

    setOrderedHeaders(nextOrderedHeaders);
  };

  return (
    <div className={styles.menuContainer}>
      <div className={styles.menuBeforeText}>
        {translation.columns} <span>{visibleLength}</span> {translation.from.toLowerCase()} <span>{orderedHeaders.length}</span>
      </div>
      <IconButton icon="edit" flat style={{ color: '#356F97' }} onClick={handleOpen} />
      <Dialog
        maxWidth="xs"
        aria-labelledby="column-filter-dialog"
        open={active}
      >
        <DialogTitle id="column-filter-dialog-title">{translation.columns}</DialogTitle>
        <DialogContent dividers>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable-list">
              {provided => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {orderedHeaders.map((header, index) => (
                    <DraggableListItem
                      key={header.property}
                      item={header}
                      index={index}
                      disabled={header.visible && visibleLength === 1}
                      checked={map.hasOwnProperty(header.property) ? !!map[header.property][1] : header.visible}
                      onChange={val => handleHeaderChange(header, val)}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel} color="primary">
            {translation.cancel}
          </Button>
          <Button onClick={handleOk} color="primary" variant="outlined">
            {translation.ok}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ColumnEditor.propTypes = {
  headers: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  language: PropTypes.object.isRequired
};

export default connect(
  store => {
    return {
      language: store.language
    };
  }
)(ColumnEditor);