import React, { Component } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Fab from '@material-ui/core/Fab';
import CircularProgress from '@material-ui/core/CircularProgress';
import Popover from '@material-ui/core/Popover';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import configAxios from '../../../config/axios';
import { updatePriceFormat } from '../../../utils/utils';

import styles from './Info.css';

class Info extends Component {
  state = {
    infoLoading: false,
    openPopover: false,
    infoData: {},
    anchorEl: null
  };

  popoverOpenHandle = (e, itemId) => {
    if (this.state.infoLoading) return;

    this.setState(
      {
        anchorEl: e.currentTarget,
        infoLoading: true
      },
      () => {
        this.loadInfoFromServer(itemId).then(() => {
          this.setState({
            openPopover: true,
            infoLoading: false
          });
        });
      }
    );
  };

  popoverCloseHandle = () => {
    this.setState({
      openPopover: false,
      anchorEl: null
    });
  };

  loadInfoFromServer = itemId => {
    const query = new URLSearchParams(location.search);
    const mid = query.has('mid') ? Number(query.get('mid')) : JSON.parse(localStorage.mission).id;

    const promise = configAxios({
      method: 'POST',
      url: `/${this.props.urlApi}/info`,
      data: {
        missionId: mid,
        itemId
      }
    }).then(response => {
      this.setState({
        infoData: updatePriceFormat(response.data.data)
      });
    });
    return promise;
  };

  render() {
    const { infoData, anchorEl, infoLoading, openPopover } = this.state;
    const { itemId } = this.props;
    return (
      <Fab
        color="primary"
        size="small"
        class={styles.disabledFab}
        disabled={openPopover}
        onClick={event => this.popoverOpenHandle(event, itemId)}
      >
        {infoLoading ? (
          <CircularProgress size={24} thickness={2} className={styles.progressIcon} />
        ) : (
          <InfoOutlinedIcon />
        )}
        <Popover
          open={openPopover}
          anchorEl={anchorEl}
          className={styles.popover}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right'
          }}
          onClose={this.popoverCloseHandle}
        >
          <List>
            {Object.keys(infoData).map(key => (
              <ListItem key={key}>
                <ListItemText primary={infoData[key].toString()} secondary={key} style={{ cursor: 'default' }} />
              </ListItem>
            ))}
          </List>
        </Popover>
      </Fab>
    );
  }
}

Info.propTypes = {
  urlApi: PropTypes.string.isRequired,
  itemId: PropTypes.number.isRequired
};

export default Info;
