import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import { searchProduct, changePage } from '../../../store/actions/actions';
import configAxios from '../../../config/axios';
import langs from '../../../locales';

import styles from './Search.css';

const style = () => ({
  root: {
    width: '100%',
  },
  OnFocus: {
    width: '100%',
  },
  hidden: {
    display: 'none',
  },
  visible: {
    display: 'flex',
  },
  adornment: {
    display: 'none',
  },
  inputRoot: {
    paddingRight: '25px',
    fontSize: '12px',
  },
});

class Search extends Component {
  myRef = React.createRef();
  state = {
    open: null,
    options: [],
    inputValue: '',
    iconSearch: true,
  };

  componentDidMount() {
    window.addEventListener('focus', this.outOfFocus);
  }

  componentWilUnmount() {
    window.removeEventListener('focus', this.outOfFocus);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.openOnlyCases !== nextProps.openOnlyCases) {
      this.setState({
        inputValue: ""
      });
    } 
    
    if (!this.state.inputValue.length) {
      this.outOfFocus();
    }
  }

  outOfFocus = () => {
    this.myRef.current.blur();
    this.setState({
      iconSearch: true,
    });
  };

  suggest = (event, params) => {
    const { language, urlApi, dispatch, shopId, openOnlyCases } = this.props;
    const { lang } = language;

    const query = new URLSearchParams(location.search);
    const mid = query.has('mid') ? Number(query.get('mid')) : JSON.parse(localStorage.mission).id;
    const sid = query.has('sid') ? Number(query.get('sid')) : JSON.parse(localStorage.scenario).id;

    if (params.length > 3 && event.type !== 'blur') {
      configAxios({
        method: 'GET',
        url: `/${urlApi}`,
        params: {
          shopId: shopId,
          openOnly: openOnlyCases ? 1 : 0,
          missionId: mid,
          scenarioId: sid,
          suggest: params,
        },
      }).then(response => {
        let suggestResponse;
        const suggestResponseCopy = response.data.data;
        if (suggestResponseCopy && suggestResponseCopy.length) {
          suggestResponseCopy.forEach((val, key) => {
            suggestResponseCopy[key].product_name = `${val.plu_original} - ${val.product_name}`;
          });
          suggestResponse = suggestResponseCopy;
        } else {
          suggestResponse = [
            {
              product_name: langs[lang].search.notFound,
              id: 0,
            },
          ];
        }
        this.setState({
          options: suggestResponse,
        });
      });
    } else {
      this.setState({
        options: [],
      });
      dispatch(searchProduct(null));
    }
  };

  setOpen = flag => {
    this.setState({
      open: flag,
      options: [],
    });
  };

  setInput = (event, value) => {
    const { dispatch } = this.props;

    this.setState(
      {
        inputValue: value ? value.product_name : '',
      },
      () => {
        this.myRef.current.blur();
      }
    );

    if (value && value.item_id && event.type !== 'blur') {
      dispatch(searchProduct(value.item_id));
      dispatch(changePage(1));
    }
  };

  handleBlur = () => {    
    this.setState({
      options: [],
      iconSearch: true,
    });

    if (this.state.options.length) {
      this.setState({
        inputValue: '',
      });
    }
  };

  handleFocus = () => {
    const { inputValue, open } = this.state;
    const { dispatch } = this.props;

    if (inputValue.length && typeof inputValue === 'string' && open) {
      dispatch(searchProduct(null));
      this.setState({
        inputValue: '',
        open: false,
      });
    }

    this.setState({
      iconSearch: false,
    });
  };

  handleChange = event => {
    this.setState({
      inputValue: event.target.value,
    });
  };

  render() {
    const { open, options, inputValue, iconSearch } = this.state;
    const {
      classes,
      language: { translation },
    } = this.props;
    const hiddeIcon = !inputValue.length && iconSearch;
    const fieldSearch = translation.scenario.filterPart.search;
    const found = translation.search.notFound;
    const isVisible = inputValue.length > 3 && options.length;

    return (
      <div className={styles.searchWrap}>
        <Autocomplete
          open={open}
          options={options}
          filterOptions={options => options}
          inputValue={inputValue}
          classes={{
            root: classes.root,
            input: classes.OnFocus,
            popper: isVisible ? classes.visible : classes.hidden,
            endAdornment: classes.adornment,
            inputRoot: classes.inputRoot,
          }}
          autoSelect={false}
          onOpen={e => this.setOpen(true, e)}
          onClose={e => this.setOpen(false, e)}
          onChange={(e, value) => this.setInput(e, value)}
          getOptionLabel={option => option.product_name}
          onInputChange={(event, value) => this.suggest(event, value)}
          noOptionsText={found}
          renderInput={params => {
            if (options) {
              return (
                <TextField
                  {...params}
                  inputRef={this.myRef}
                  onBlur={this.handleBlur}
                  onChange={this.handleChange}
                  onFocus={this.handleFocus}
                  label={fieldSearch}
                  fullWidth
                />
              );
            }
          }}
        />
        {hiddeIcon && <SearchIcon class={styles.searchIcon} />}
      </div>
    );
  }
}
Search.defaultProps = {
  urlApi: 'task',
};

Search.propTypes = {
  urlApi: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  shopId: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  openOnlyCases: PropTypes.bool,
  textFieldStyle: PropTypes.object,
  language: PropTypes.object.isRequired,
  setToggleCases: PropTypes.bool,
  searchProduct: PropTypes.bool
};

export default connect(store => {
  return {
    language: store.language,
    searchProduct: store.searchProduct,
    setToggleCases: store.setToggleCases,
  };
})(withStyles(style)(Search));
