import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import IsTimeTarget from '../TimeTarget';
import XLS from './exportToXLS';
import styles from './ExportDataComponent.css';

class ExportDataComponent extends Component {
  state = {
    anchorEl: null,
    row: []
  };

  componentDidMount() {
    const {
      data: { type }
    } = this.props;
    this.prepareDate();
    type === 'table' ? this.headDataTable(this.props.data) : this.headData();
  }

  componentWillReceiveProps({ data }) {
    const {
      data: { type }
    } = this.props;
    type === 'table' ? this.headDataTable(data) : this.headData(data);
  }

  prepareDate = () => {
    const dateObj = new Date();
    const month = dateObj.getUTCMonth() + 1;
    const day = dateObj.getUTCDate();
    const year = dateObj.getUTCFullYear();
    const dateNow = day + '_' + month + '_' + year;

    this.setState({
      date: dateNow
    });
  };

  dateNamesTransformHandle = (target, data) => {
    const { translation } = this.props;

    if (!data.length) {
      return data;
    }

    if (!data.length || typeof data === 'string') {
      return data;
    }

    if (data[0].indexOf(translation.chart[target] || '.') !== -1) {
      return data;
    }

    switch (target) {
      case 'day':
        return data.map(item =>
          item
            .split('-')
            .reverse()
            .join('.')
        );
      case 'week':
        return data.map(item => {
          var year = moment(item)
            .endOf('isoweek')
            .format('YYYY');
          var week = moment(item).isoWeek();
          return `${week} ${translation.chart.week} ${year}`;
        });
      case 'month':
        return data.map(item => {
          const newItem = item.split('-').reverse();
          newItem.shift();
          return newItem.join('.');
        });
      case 'quarter':
        return data.map(item => {
          const newItem = item.split('-').reverse();
          const quarter = Math.ceil(+newItem[1] / 3);
          return `${quarter} ${translation.chart.quarter} ${newItem[2]}`;
        });
      case 'year':
        return data.map(item => item.split('-')[0]);
      default:
        return data;
    }
  };

  headData = info => {
    const { translation } = this.props;
    const propsData = this.props.data;
    const data = info ? info : propsData;
    let firstName = null;

    if (data.data.target) {
      firstName = translation.targets[data.data.target]
        ? translation.targets[data.data.target]
        : translation.targets.nothing;
    } else {
      firstName = translation.targets[data.data.xNames.source]
        ? translation.targets[data.data.xNames.source]
        : translation.targets.nothing;
    }
    const names = [firstName, ...data.data.source.map(item => item.name)];

    this.setState(
      {
        headers: names
      },
      () => {
        this.bodyData(data);
      }
    );
  };

  headDataTable = ({ data }) => {
    const {
      translation: { targets }
    } = this.props;

    const header = [];
    data.header.map((item, key) => {
      if (key) {
        header.push(item.title);
      } else {
        const firstName = targets[data.target] ? targets[data.target] : targets.nothing;
        header.push(firstName);
      }
    });

    this.setState(
      {
        headers: header
      },
      () => {
        this.bodyDataTable(data);
      }
    );
  };

  bodyData = info => {
    const propsData = this.props.data;
    const data = info ? info : propsData;

    const names = data.data.targetNames ? data.data.targetNames : data.data.xNames.data;
    const { headers } = this.state;

    const { source } = data.data;
    const datesSourceOptions = ['day', 'week', 'month', 'quarter', 'year'];
    const csvRows = [];
    names &&
      names.map((item, key) => {
        const csvRow = [];
        if (datesSourceOptions.indexOf(data.data.target) !== -1) {
          const value = datesSourceOptions[datesSourceOptions.indexOf(data.data.target)];
          const res = this.dateNamesTransformHandle(value, [item]);
          csvRow.push(res[0]);
        } else {
          csvRow.push(item);
        }

        source &&
          source.map(element => {
            if (element.data[key]) {
              let val = +element.data[key];
              if (typeof val === 'number' && !isNaN(val)) {
                const formatted = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
                csvRow.push(formatted);
              } else if (element.data[key].y) {
                const formatted = element.data[key].y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
                csvRow.push(formatted);
              } else {
                csvRow.push('0');
              }
            }
          });

        const allowedSources = source.filter(item => {
          const name = item.kpi.name ? item.kpi.name : item.kpi;

          return name.indexOf('inc') !== -1;
        });
        const rowObj = {};
        csvRow.map((val, key) => {
          rowObj.colorful = allowedSources;
          rowObj[headers[key]] = val;
        });
        csvRows.push(csvRow);
      });

    this.setState({
      rows: csvRows
    });
  };

  bodyDataTable = info => {
    const { rows, header, target } = info;
    const body = [];

    rows.map(item => {
      let buff = [];
      header.map(({ id, title }) => {
        if (typeof item[id] === 'object' && item[id]) {
          buff.push(item[id].title ? item[id].title : item[id].name);
        } else if (typeof item[title] === 'object' && item[title]) {
          buff.push(item[title].title ? item[title].title : item[title].name);
        } else if (item[title] || item[id]) {
          buff.push(item[title] ? item[title].toString() : item[id].toString());
        } else {
          buff.push('0');
        }
      });
      body.push(buff);
    });

    body.map(item => {
      if (IsTimeTarget(target)) {
        item[0] = this.dateNamesTransformHandle(target, [item[0]]).toString();
      }
    });

    this.setState({
      rows: body
    });
  };

  typeChart = () => {
    const { rows } = this.state;
    const { data } = this.props;
    const beforeTransform = [];
    let transformDate;
    rows.map(itemA => {
      itemA.map((itemB, j) => {
        if (j === 0) {
          beforeTransform.push(itemB);
        }
      });
    });
    if(typeof rows[0][0] === "number") {
      transformDate = beforeTransform;
    } else {
      transformDate = this.dateNamesTransformHandle(data.data.xNames.source, beforeTransform);    
    }
    
    console.log(transformDate);

    const afterTransform = [];
    rows.map((item, i) => {
      let buff = [];
      item.map((itm, j) => {
        if (j === 0) {
          buff.push(transformDate[i]);
        } else {
          buff.push(item[j]);
        }
      });
      afterTransform.push(buff);
      buff = [];
    });
    
    return afterTransform;
  };

  typePie = () => {
    const { rows } = this.state;
    const { data } = this.props;
    const keys = data.data.xNames.data.map(item => item);
    const isDate = rows.map((item, i) => {
      item[0] = keys[i];
      return item;
    });
    return isDate;
  };

  isReiteration = () => {
    const { headers } = this.state;
    let iteration = 0;
    let isReiteration = [];
    headers.map(item => {
      if (isReiteration.indexOf(item) >= 0) {
        iteration++;
        isReiteration.push(item + ' '.repeat(iteration));
      } else {
        isReiteration.push(item);
      }
    });
    return isReiteration;
  };

  prepareForExcel = item => {
    const { rows, date } = this.state;
    const { data } = this.props;
    let { name } = data.data;
    let rowsCase;
    const isReiteration = this.isReiteration();

    if (data.type === 'chart') {
      if (data.data.source[0].type === 'pie') {
        rowsCase = this.typePie();
      } else {
        rowsCase = this.typeChart();
      }
    } else if (data.type === 'table') {
      rowsCase = rows;
    } else {
      rowsCase = this.typePie();
    }
    const excel = [];
    rowsCase.map(item => {
      const obj = {};
      item.map((elem, key) => {
        obj[isReiteration[key]] = elem;
      });
      excel.push(obj);
    });
    if (name[name.length - 1] === '.') {
      name = name.slice(0, [name.length - 1]);
    }
    this.setState({
      row: excel
    });

    const title = `${date}_${name}.xls`;
    const xls = new XLS(excel, title);
    !item ? xls.exportToXLS(title) : '';

    this.setState({
      anchorEl: null
    });
  };

  downloadHandle = event => {
    this.setState({
      anchorEl: event.currentTarget
    });
  };

  handleMenuClose = () => {
    this.setState({
      anchorEl: null
    });
  };

  render() {
    const { translation } = this.props;
    const { anchorEl, headers, date, row } = this.state;
    const { data } = this.props;
    const { name } = data.data;

    return (
      <div className={styles.exportMenu}>
        <IconButton
          aria-owns={anchorEl ? 'simple-menu' : undefined}
          aria-haspopup="true"
          onClick={this.downloadHandle}
          className={styles.topButtonsButton}
        >
          <DownloadIcon />
        </IconButton>
        <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleMenuClose}>
          <MenuItem
            onClick={() => {
              this.prepareForExcel(true);
              this.handleMenuClose();
            }}
            className={styles.menuItem}
          >
            <CSVLink headers={headers} data={row} separator={';'} filename={`${date}_${name}.csv`}>
              <div className={styles.exportListItem}>{translation.download.CSV}</div>
            </CSVLink>{' '}
          </MenuItem>{' '}
          <MenuItem
            onClick={() => {
              this.prepareForExcel(false);
            }}
            className={styles.menuItem}
          >
            <div className={styles.exportListItem}>{translation.download.XLS}</div>
          </MenuItem>{' '}
        </Menu>
      </div>
    );
  }
}

ExportDataComponent.propTypes = {
  data: PropTypes.object.isRequired, //eslint-disable-line
  translation: PropTypes.object.isRequired //eslint-disable-line
};

export default connect(store => {
  return {
    local: store.language.lang
  };
})(ExportDataComponent);
