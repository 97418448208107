import configAxios from '../../../config/axios';
import ShopFactory from './ShopFactory';

class ShopRepository {

  constructor() {
    this.map = {};
  }

  /**
   *
   * @param missionId
   * @returns {Promise<array>}
   */
  async getAll({ missionId }) {
    const request = configAxios({
      method: 'GET',
      url: '/user/get-shops',
      params: {
        missionId
      }
    });

    return request.then(response => {
      const items = response.data.all;
      if (!Array.isArray(items) || !items.length) {
        return [];
      }

      return items.map(item => {
        return ShopFactory.createFromServerData(item);
      });
    });
  }
}

export const shopRepository = new ShopRepository();