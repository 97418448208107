import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cls from 'classnames';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import { Table as TableToolBox, TableHead, TableRow, TableCell } from 'react-toolbox/lib/table';
import { loadTask } from '../../store/actions/actions';
import configAxios from '../../config/axios';
import Comment from '../../components/tableComponents/Comment/Comment';
import Search from '../../components/tableComponents/Search/Search';
import TaskDropdown from '../../components/tableComponents/TaskDropdown/TaskDropdown';
import CarouselPhoto from '../../components/tableComponents/CarouselPhoto/CarouselPhoto';
import Info from '../../components/tableComponents/Info/Info';

import styles from './Table.css';

const Table = props => {
  const {
    shopId,
    items = [],
    headers = {},
    suggest = '',
    hasSearch = true,
    manualCases,
    language,
    openOnlyCases,
    urlApi,
  } = props;

  function deleteCase(itemId, caseType) {
    const aestTime = new Date().toLocaleString('en-US', { timeZone: 'Atlantic/Reykjavik' });
    const timeForUpdate = new Date(aestTime).toISOString();
    const { dispatch, shopId } = props;
    const query = new URLSearchParams(location.search);
    const mid = query.has('mid') ? Number(query.get('mid')) : JSON.parse(localStorage.mission).id;

    const promise = configAxios({
      method: 'DELETE',
      url: `/task/delete`,
      params: {
        itemId,
        missionId: mid,
        shopId,
        caseType,
        actionDateTime: moment(timeForUpdate).format('YYYY-MM-DD HH:mm:ss'),
      },
    }).then(() => {
      dispatch(loadTask(true));
    });
    return promise;
  }

  const lang = language.translation ? language.translation.directTask : {};
  const autoOrder = urlApi === 'auto-order';

  return (
    <div>
      <TableToolBox selectable={false}>
        <TableHead class={styles.tableHeadRow}>
          {Object.keys(headers).map(key => {
            if (key === 'product_name' && hasSearch && !autoOrder) {
              return (
                <TableCell key={key} class={styles.tableCell} style={{ minWidth: 150 }}>
                  <Search urlApi={urlApi} suggest={suggest} shopId={shopId} openOnlyCases={openOnlyCases} />
                </TableCell>
              );
            }
            return (
              <TableCell key={key} class={styles.tableCell}>
                {headers[key]}
              </TableCell>
            );
          })}
        </TableHead>
        {items.map((item, index) => {
          const keyCode = index;
          return (
            <TableRow
              key={keyCode}
              class={cls(
                styles.tableRow,
                autoOrder ? 
                  {
                    [styles.tableRowStatus]: item.tree_status_id !== 0 && !item.corrected_count_valid,
                    [styles.correctedCountValid]: !item.corrected_count_valid,
                    [styles.manualCasesStyle]: manualCases && item.type_id === 4,
                    [styles.finalCasesStyle]: item.is_final,
                  }
                  : {
                    [styles.tableRowStatus]: item.tree_status_id !== 0,
                    [styles.manualCasesStyle]: manualCases && item.type_id === 4,
                    [styles.finalCasesStyle]: item.is_final,
                  }
              )}
            >
              {Object.keys(headers).map(key => {
                switch (key) {
                  case 'tree': {
                    if (!item[key].length && !Object.keys(item[key]).length) {
                      return (
                        <TableCell key={key} class={styles.tableCell}>
                          {lang.treeEmpty}
                        </TableCell>
                      );
                    }
                    if (manualCases && item.type_id === 4) {
                      return (
                        <TableCell key={key} class={styles.tableCell}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => deleteCase(item.item_id, item.type_id)}
                          >
                            {lang.delete}
                          </Button>
                        </TableCell>
                      );
                    }
                    if (Object.keys(item[key]).length && autoOrder) {
                      return (
                        <TableCell key={key} class={styles.tableCell}>
                          <TaskDropdown
                            urlApi={urlApi}
                            data={[item[key]]}
                            itemId={item.id}
                            caseType={item.id}
                            shopId={shopId}
                          />
                        </TableCell>
                      );
                    }
                    return (
                      <TableCell key={key} class={styles.tableCell}>
                        <TaskDropdown
                          urlApi={urlApi}
                          data={item[key]}
                          itemId={item.item_id}
                          caseType={item.type_id}
                          shopId={shopId}
                        />
                      </TableCell>
                    );
                  }
                  case 'image': {
                    return (
                      <TableCell key={key} class={cls(styles.tableCell, styles.textCenter)}>
                        <CarouselPhoto images={item[key] ? item[key] : []} />
                      </TableCell>
                    );
                  }
                  case 'info': {
                    return (
                      <TableCell key={key} class={cls(styles.tableCell, styles.textCenter)}>
                        <Info urlApi={urlApi} itemId={item.item_id} />
                      </TableCell>
                    );
                  }
                  case 'comment': {
                    return (
                      <TableCell key={key} class={cls(styles.tableCell, styles.textCenter)}>
                        <Comment
                          urlApi={urlApi}
                          shopId={shopId}
                          itemId={item.item_id}
                          text={item[key] ? item[key] : ''}
                          caseType={item.type_id}
                        />
                      </TableCell>
                    );
                  }
                  default:
                    return (
                      <TableCell key={key} class={styles.tableCell}>
                        {item[key]}
                      </TableCell>
                    );
                }
              })}
            </TableRow>
          );
        })}
      </TableToolBox>
    </div>
  );
};

Table.propTypes = {
  urlApi: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  items: PropTypes.array,
  headers: PropTypes.object,
  suggest: PropTypes.string,
  hasSearch: PropTypes.bool,
  shopId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  // shopId: PropTypes.number.isRequired,
  openOnlyCases: PropTypes.bool.isRequired,
  language: PropTypes.object.isRequired,
  manualCases: PropTypes.bool.isRequired,
};

export default connect(store => {
  return {
    language: store.language,
  };
})(Table);
