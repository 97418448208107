import React from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import styles from './Element.css';

const Element = ({ url, body }) => {
  return (
    <div className={styles.element}>
      <div className={styles.elementPreview}>
        <div>{url}</div>
        <CopyToClipboard text={body} className={styles.button}>
          <span>Copy only body</span>
        </CopyToClipboard>
      </div>
      <div className={styles.elementBody}>
        <pre>{body}</pre>
      </div>
    </div>
  );
};

Element.propTypes = {
  url: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired
};

export default Element;
