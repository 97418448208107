import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Media from 'react-media';
import { Dialog, DialogTitle } from '@material-ui/core';
import MissionsNavigation from '../../components/MissionsNavigation';
import configAxios from '../../config/axios';
import Table from '../../components/Table/Table';
import MobileTable from '../../components/MobileTable/MobileTable';
import Paginate from '../../components/Paginate';
import CategorySort from '../../components/CategorySort/CategorySort';
import Preload from '../../constructors/Preload';
import { loadTask, setPageLoadingStatus, changePage, searchProduct, setToggleCasesAction } from '../../store/actions/actions';
import Autocomplete from '../../components/GridAutocomplete';
import { isMobileFirefox } from '../../utils/browser';

import styles from './AutoOrder.css';

class AutoOrder extends Component {
  constructor(props) {
    super(props);

    this.urlApi = 'auto-order';
    this.state = {
      items: [],
      headers: {},
      pagination: {},
      shopItems: [],
      selectedCategories: [],
      selectShop: 1865,
      suggest: '',
      emptyPage: true,
      emptyShop: false,
      showCategorySort: false,
      blocking: '',
      removeManualCases: false,
      openOnlyCases: false,
      isMobileFirefox: isMobileFirefox(),
    };

    this.preload = new Preload();
    this.preload.subscribe(promises => {
      this.props.dispatch(setPageLoadingStatus(promises.length > 0));
    });
  }

  componentDidMount() {
    const _this = this;
    const promise = _this.loadShopsFromServer();

    promise.then(
      () => {
        _this.setState({
          emptyPage: false,
        });
      },
      () => {
        _this.setState({
          emptyPage: true,
        });
      }
    );

    _this.preload.add(promise);

    const { dispatch } = this.props;
    dispatch(changePage(1));
    dispatch(setToggleCasesAction(this.state.openOnlyCases));
  }

  /**
   *
   * @param nextProps
   */
  componentWillReceiveProps(nextProps) {
    const { emptyShop, items, pagination } = this.state;
    const { pageLoadingStatus, dispatch } = nextProps;

    if (nextProps.loadTask.initPage) {
      const { dispatch } = this.props;
      dispatch(loadTask(false));
      this.loadTaskFromServer(nextProps.changePage, nextProps.searchProduct, nextProps.loadTask.hidePreload);
    }

    if (nextProps.changePage !== this.props.changePage && !nextProps.searchProduct) {
      this.loadTaskFromServer(nextProps.changePage);
    }

    if (nextProps.searchProduct !== this.props.searchProduct) {
      this.loadTaskFromServer(1, nextProps.searchProduct);
    }

    if (!pageLoadingStatus && items.length === 0 && !emptyShop && !!pagination.before) {
      dispatch(changePage(pagination.before));
      this.setState({
        removeManualCases: false,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { mission } = prevProps;
    if (mission.id !== this.props.mission.id) {
      const _this = this;
      _this.setState({
        removeManualCases: false,
        showCategorySort: false,
        selectedCategories: [],
      });
      const promise = _this.loadShopsFromServer();
      promise.then(
        () => {
          _this.setState({
            emptyPage: false,
          });
        },
        () => {
          _this.setState({
            emptyPage: true,
          });
        }
      );
      _this.preload.add(promise);
    }
  }

  componentWillUnmount() {
    this.props.dispatch(setToggleCasesAction(false));
  }

  /**
   *
   * @returns {*}
   */
  loadShopsFromServer() {

    const query = new URLSearchParams(location.search);
    const mid = query.has('mid') ? Number(query.get('mid')) : JSON.parse(localStorage.mission).id;

    const promise = configAxios({
      method: 'GET',
      url: `/user/get-shops`,
      params: {
        missionId: mid,
      },
    });

    promise.then(response => {
      const shopItem = response.data.all;
      if (!shopItem.length) {
        this.setState({
          emptyShop: true,
        });
        return;
      }
      let pushShopID = +response.data.all[0].id;

      if (localStorage.shopID) {
        for (let i = 0, len = shopItem.length; i < len; i++) {
          if (shopItem[i].id === localStorage.shopID) {
            pushShopID = shopItem[i].id;
          }
        }
      }

      this.setState(
        {
          shopItems: shopItem,
          selectShop: pushShopID,
        },
        () => {
          const promise = this.loadTaskFromServer();
          this.preload.add(promise);
        }
      );
    });

    return promise;
  }

  /**
   *
   * @param event
   * @param index
   * @param value
   */
  handleChangeShop = (event, index, value) => {
    this.setState(
      {
        headers: {},
        showCategorySort: false,
        selectShop: value,
      },
      () => {
        const { dispatch } = this.props;
        dispatch(changePage(1));

        this.loadTaskFromServer();
        this.clearSelectedCategories();
        localStorage.shopID = value;
      }
    );
  };

  /**
   *
   * @param page
   * @returns {*}
   */
  loadTaskFromServer(page = 1, productId = null, hidePreload = false) {
    const { selectShop } = this.state;

    const query = new URLSearchParams(location.search);
    const mid = query.has('mid') ? Number(query.get('mid')) : JSON.parse(localStorage.mission).id;

    if (selectShop) {
      const promise = configAxios({
        method: 'GET',
        url: `/${this.urlApi}`,
        params: {
          limit: 30,
          page: page,
          shopId: selectShop,
          missionId: mid,
        },
      });

      promise.then(response => {
        const {
          data: { items, headers, pagination },
        } = response;

        this.setState({
          items,
          headers,
          pagination,
        });
      });

      if (!hidePreload) {
        this.preload.add(promise);
      }

      return promise;
    }
  }

  selectCategory = id => {
    const index = this.state.selectedCategories.indexOf(id);
    if (index < 0) {
      const arr = this.state.selectedCategories;
      arr.push(id);
      this.setState({
        selectedCategories: arr,
      });
    } else {
      const arr = this.state.selectedCategories;
      arr.splice(index, 1);
      this.setState({
        selectedCategories: arr,
      });
    }
  };

  clearSelectedCategories = () => {
    this.setState({
      selectedCategories: [],
    });
  };

  removeManualCase = () => {
    this.setState({
      removeManualCases: !this.state.removeManualCases,
    });
  };

  toggleOpenOnlyCases = () => {
    this.setState(
      {
        openOnlyCases: !this.state.openOnlyCases,
      },
      () => {
        if (this.props.searchProduct) {
          this.props.dispatch(searchProduct(null));
        } else {
          this.loadTaskFromServer();
        }
        this.props.dispatch(setToggleCasesAction(this.state.openOnlyCases));
      }
    );
  };

  render() {
    const { blockingContent, language, mission, pageLoadingStatus: pageLoading } = this.props;

    const {
      items,
      headers,
      suggest,
      emptyShop,
      emptyPage,
      pagination,
      selectShop,
      openOnlyCases,
      removeManualCases,
      selectedCategories,
      showCategorySort,
      shopItems: stateShops,
      isMobileFirefox,
    } = this.state;

    const { translation } = language;


    const taskNoFound = !pageLoading && !emptyShop && !Object.keys(headers).length;
    

    const shopsNoFound = !pageLoading && emptyShop && !Object.keys(headers).length;

    return (
      <div>
        <MissionsNavigation />

        {!blockingContent && (
          <div>
            {!emptyPage && !emptyShop && (
              <div>
                <div className={styles.actionWrap}>
                  <div className={styles.actionWrapSelect}>
                    <Autocomplete
                      mission={mission}
                      shopsData={stateShops}
                      currentShop={+selectShop}
                      handleChangeShop={this.handleChangeShop}
                      translation={language.translation}
                      loadShopsFromServer={this.loadShopsFromServer}
                    />
                  </div>
                </div>

                {showCategorySort && (
                  <CategorySort
                    urlApi={this.urlApi}
                    shopId={selectShop}
                    selectedCategories={selectedCategories}
                    selectCategory={this.selectCategory}
                    clear={this.clearSelectedCategories}
                  />
                )}

                {items && items.length > 0 && !showCategorySort && !taskNoFound && (
                  <div className={styles.tableContainer}>
                    <div className={styles.tableWrap}>
                      <Media
                        query="(min-width: 768px)"
                        render={() => (
                          <Table
                            urlApi={this.urlApi}
                            items={items}
                            headers={headers}
                            suggest={suggest}
                            shopId={selectShop}
                            openOnlyCases={openOnlyCases}
                            manualCases={removeManualCases}
                          />
                        )}
                      />
                      <Media
                        query="(max-width: 767px)"
                        render={() => (
                          <MobileTable
                            urlApi={this.urlApi}
                            items={items}
                            headers={headers}
                            suggest={suggest}
                            shopId={selectShop}
                            openOnlyCases={openOnlyCases}
                            manualCases={removeManualCases}
                            tableType={'direct'}
                          />
                        )}
                      />
                    </div>
                    <div className={styles.bottomBlock}>
                      <Paginate data={pagination} />
                    </div>
                  </div>
                )}
                {taskNoFound && <div className={styles.tableWrapEmpty}>{translation.errors.noAutoOrder}</div>}
              </div>
            )}
            {shopsNoFound && <div className={styles.empty}>{translation.errors.noShops}</div>}
          </div>
        )}
        <Dialog open={isMobileFirefox} onClose={() => {}}>
          <DialogTitle>{translation.mobileFirefoxAlert}</DialogTitle>
        </Dialog>
      </div>
    );
  }
}

AutoOrder.defaultProps = {
  searchProduct: null,
};

AutoOrder.propTypes = {
  mission: PropTypes.object.isRequired,
  searchProduct: PropTypes.number,
  pageLoadingStatus: PropTypes.bool.isRequired,
  language: PropTypes.object.isRequired,
  blockingContent: PropTypes.bool.isRequired,
  changePage: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  loadTask: PropTypes.object.isRequired,
};

export default connect(store => {
  return {
    changePage: store.changePage,
    loadTask: store.loadTask,
    searchProduct: store.searchProduct,
    pageLoadingStatus: store.pageLoadingStatus,
    language: store.language,
    blockingContent: store.blockingContent,
    mission: store.mission,
  };
})(AutoOrder);
