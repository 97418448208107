import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cls from 'classnames';
import Button from '@material-ui/core/Button';
import configAxios from '../../config/axios';

import styles from './CategorySort.css';

class CategorySort extends Component {
  state = {
    categoriesList: this.props.selectedCategories,
    selectedCategories: []
  };

  componentDidMount() {
    this.getCategory();
    this.props.clear();
  }

  getCategory = () => {
    const _this = this;

    const query = new URLSearchParams(location.search);
    const mid = query.has('mid') ? Number(query.get('mid')) : JSON.parse(localStorage.mission).id;
    const sid = query.has('sid') ? Number(query.get('sid')) : JSON.parse(localStorage.scenario).id;

    configAxios({
      method: 'GET',
      url: `/${this.props.urlApi}/get-categories`,
      params: {
        shopId: this.props.shopId,
        missionId: mid,
        scenarioId: sid
      }
    }).then(function(response) {
      _this.setState({
        categoriesList: response.data.data || [],
        selectedCategories: _this.props.selectedCategories
      });
    });
  };

  selectCategory = id => {
    this.props.selectCategory(id);
    this.setState({
      trigger: !this.state.trigger
    });
  };

  render() {
    const lang = this.props.language.translation.directTask;
    const { selectedCategories, categoriesList } = this.state;

    return (
      <div className={styles.categoriesListWrap}>
        {categoriesList.map(item => {
          const categoryIndex = selectedCategories.indexOf(item.id);
          const index = item.id || item;
          return (
            <div key={index} className={styles.categoryWrap}>
              <div className={styles.categoryItem}>
                <Button
                  variant="contained"
                  color="primary"
                  class={cls(styles.category, {
                    [styles.categoryActive]: categoryIndex > -1
                  })}
                  onClick={() => this.selectCategory(item.id)}
                >
                  <div className={styles.categoryName}>{item.name}</div>
                  {categoryIndex > -1 && (
                    <div className={styles.categoryStep}>
                      {lang.step} {categoryIndex + 1}
                    </div>
                  )}
                </Button>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
CategorySort.defaultProps = {
  urlApi: 'task'
};

CategorySort.propTypes = {
  urlApi: PropTypes.string,
  shopId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  language: PropTypes.object.isRequired,
  selectedCategories: PropTypes.array.isRequired,
  selectCategory: PropTypes.func.isRequired,
  clear: PropTypes.func.isRequired
};

export default connect(store => {
  return {
    language: store.language
  };
})(CategorySort);
