import { isString } from 'lodash';

export class Header {
  static ORDER_NONE = 0;
  static ORDER_ASC = 1;
  static ORDER_DESC = 2;

  /**
   *
   * @param property
   * @param name
   * @param visible
   */
  constructor(property, name, visible = true) {
    this.property = property;
    this.name = name;
    this.visible = visible;
    this.filter = BaseFilter.create(property);
    this.order = Header.ORDER_NONE;
    this.formatter = BaseFormatter.create(property);
  }

  /**
   *
   * @param val
   */
  setVisible(val) {
    this.visible = !!val;
  }

  toggleOrder() {
    this.order = (this.order === Header.ORDER_DESC) ? Header.ORDER_NONE : this.order + 1;
  }

  resetOrder() {
    this.order = Header.ORDER_NONE;
  }

  resetFilter() {
    this.filter.reset();
  }

  /**
   *
   * @returns {boolean}
   */
  isNotOrdered() {
    return this.order === Header.ORDER_NONE;
  }

  /**
   *
   * @param value
   * @returns {*|number|string|string}
   */
  format(value) {
    return this.formatter.format(value);
  }

  hasNotEmptyFilter() {
    return !this.filter.isEmpty();
  }

  hasFilter() {
    return this.filter.active;
  }
}

export class BaseFormatter {
  static create(property) {
    switch (property) {
      case 'is_promo':
        return new BoolFormatter();
      case 'promo_period':
        return new DateRangeFormatter();
      case 'stock_sum':
      case 'product_turnover':
        return new NumberFormatter();
      case 'last_sales_date':
      case 'last_shipment_date':
        return new DateFormatter();
      default:
        return new BaseFormatter();
    }
  }

  format(value) {
    return value;
  }
}

export class NumberFormatter extends BaseFormatter {
  format(value) {
    if (isString(value)) {
      value = Number(value);
    }
    return value !== null ? value.toFixed(2) : 0;
  }
}

export class BoolFormatter extends BaseFormatter {
  format(value) {
    return value ? '✔' : '-';
  }
}

export class DateFormatter extends BaseFormatter {
  format(value) {
    return value ? (new Date(value)).toLocaleDateString() : '';
  }
}

export class DateRangeFormatter extends BaseFormatter {
  format(value) {
    const [from, to] = value;
    if (!from && !to) {
      return '';
    }

    const fromStr = from ? (new DateFormatter()).format(from) : '?';
    const toStr = to ? (new DateFormatter()).format(to) : '?';

    return `${fromStr} - ${toStr}`;
  }
}

export class BaseFilter {
  constructor(active = false) {
    this.value = null;
    this.active = active;
  }
  static create(property) {
    switch (property) {
      case 'case_id':
      case 'plu_original':
      case 'title':
        return new SearchFilter();

      case 'ass_type':
      case 'category_name3':
      case 'category_name4':
        return new SelectFilter();

      case 'is_promo':
        return new SwitchFilter();

      case 'last_shipment_date':
      case 'last_sales_date':
        return new DateRangeFilter();

      case 'stock':
      case 'stock_sum':
      case 'product_turnover':
      case 'days_without_sales':
      case 'last_sales_volume':
      case 'last_shipment_volume':
        return new NumRangeFilter();

      default:
        return new BaseFilter();
    }
  }

  isEmpty() {
    return true;
  }

  filter() {
    return true;
  }

  reset() {
    this.value = null;
  }
}

export class SearchFilter extends BaseFilter {
  constructor() {
    super(true);
    this.value = null;
  }

  isEmpty() {
    return this.value === null;
  }

  filter(value) {
    if (this.isEmpty()) {
      return true;
    }

    if (value === null) {
      return false;
    }

    return value.toString().toLowerCase().includes(this.value.toString().toLowerCase());
  }

  reset() {
    this.value = null;
  }
}

export class SwitchFilter extends BaseFilter {
  constructor() {
    super(true);
    this.value = null;
  }

  isEmpty() {
    return this.value === null;
  }

  filter(value) {
    if (this.isEmpty()) {
      return true;
    }

    if (value === null) {
      return false;
    }

    return value === this.value;
  }

  reset() {
    this.value = null;
  }
}

export class SelectFilter extends BaseFilter {
  constructor() {
    super(true);
    this.value = null;
  }

  isEmpty() {
    return this.value === null;
  }

  filter(value) {
    if (this.isEmpty()) {
      return true;
    }

    if (value === null) {
      return false;
    }

    return value.toString().includes(this.value);
  }

  reset() {
    this.value = null;
  }
}

export class NumRangeFilter extends BaseFilter {
  constructor() {
    super(true);
    this.value = [null, null];
  }

  isEmpty() {
    return this.value[0] === null && this.value[1] === null;
  }

  filter(value) {
    if (this.isEmpty()) {
      return true;
    }

    if (value === null) {
      return false;
    }

    const [from, to] = this.value;

    if (from !== null && value < from) {
      return false;
    }

    if (to !== null && value > to) {
      return false;
    }

    return true;
  }

  reset() {
    this.value = [null, null];
  }
}

export class DateRangeFilter extends BaseFilter {
  constructor() {
    super(true);
    this.value = [null, null];
  }

  isEmpty() {
    return this.value[0] === null && this.value[1] === null;
  }

  filter(value) {
    if (this.isEmpty()) {
      return true;
    }

    if (value === null) {
      return false;
    }

    const [from, to] = this.value;

    if (from !== null && value < from) {
      return false;
    }

    if (to !== null && value > to) {
      return false;
    }

    return true;
  }

  reset() {
    this.value = [null, null];
  }
}