import { isObject } from 'lodash';
import configAxios from '../../../config/axios';

class TaskRepository {

  constructor() {
    this.map = {};
  }

  /**
   *
   * @param tasks
   * @returns {Promise<array>}
   */
  async addAll(tasks) {
    const request = configAxios({
      method: 'POST',
      url: '/task/create',
      headers: {
        'Multi-Request': true
      },
      data: tasks
    });

    return request.then(response => {
      return Array.isArray(response.data) ? response.data : Object.values(response.data);
    }, () => {
      return [];
    });
  }

  async updateAll(tasks) {
    const request = configAxios({
      method: 'POST',
      url: '/task/update',
      headers: { 'Multi-Request': true },
      data: tasks
    });

    return request.then(response => {
      return response;
    }, () => {
      return [];
    });
  }
}

export const taskRepository = new TaskRepository();