import React, { Component } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import PropTypes from 'prop-types';
import Element from './Element/Element';
import styles from './KpiElement.css';

class KpiElement extends Component {
  state = {
    active: false,
    copied: false
  };
  handleClick = () => {
    this.setState({
      active: !this.state.active
    });
  };

  copyHandle = () => {
    this.setState({
      copied: true
    });
  };

  copyHandle = () => {
    this.setState({
      copied: true
    });
  };

  render() {
    const { date, url, debug, status, timestamp, i } = this.props.item;
    const { active } = this.state;
    let y = 0;
    return (
      <div className={styles.item}>
        <div role="presentation" className={styles.itemPreview}>
          <span>{i}.</span>
          <span className={styles.date}>{date}</span>
          <span className={[styles.status, status !== 200 ? styles.error : styles.success].join(' ')}>{status}</span>
          <span className={styles.linkSpan}>{url}</span>
          <div className={styles.buttons}>
            <CopyToClipboard className={styles.button} onCopy={this.copyHandle} text={JSON.stringify(debug, null, 4)}>
              <span>Copy whole item</span>
            </CopyToClipboard>
            <div role="presentation" className={styles.button} onClick={this.handleClick}>
              Expand
            </div>
          </div>
        </div>
        {active && (
          <div className={styles.items}>
            {debug.map(element => {
              let body = JSON.stringify(element.body, null, 4);
              y++;
              return (
                <div key={timestamp + y}>
                  <Element url={element.url} body={body} />
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

KpiElement.propTypes = {
  item: PropTypes.object.isRequired //eslint-disable-line
};

export default KpiElement;
