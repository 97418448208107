import React, { Component } from 'react';

import PropTypes from 'prop-types';

import styles from './FiltersSvg.css';

const ActiveFilterSvg = () => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 437 385.3"
      className={styles.svgIcon}
    >
      <path
        d="M437,103.1V56.6c0-0.4-0.1-0.8-0.2-1.2C432.8,19.4,321.9,0,218.5,0C115.2,0,4.3,19.4,0.2,55.3C0.1,55.7,0,56.1,0,56.6v46.5
                c0,1.6,0.8,3.1,2.2,3.9L160.9,207v172.6c0,1.5,0.7,2.9,1.9,3.7c0.8,0.6,1.8,0.9,2.8,0.9c0.5,0,0.9-0.1,1.4-0.2l105.9-32.5
                c1.9-0.6,3.3-2.4,3.3-4.4V207L434.8,107C436.2,106.1,437,104.6,437,103.1z M388.2,57.3c-18.5,6-35.5,10.5-51.8,13.6
                c-7.9,1.5-15.9,2.7-23.8,3.9c-28.9,3.3-62,5-95.8,5C100,79.8,39,61.1,39,51.1S100,22.5,216.8,22.5c116.7,0,177.8,18.7,177.8,28.7
                C394.6,52.3,392.7,54.8,388.2,57.3z"
      />
    </svg>
  );
};

class IconFilters extends Component {
  renderIcon = () => {
    const { icon } = this.props;
    switch (true) {
      case icon.includes('filter'):
        return <ActiveFilterSvg />;
    }
  };

  render() {
    return <div>{this.renderIcon()}</div>;
  }
}

export default IconFilters;

IconFilters.propTypes = {
  icon: PropTypes.string
};
