import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-flexbox-grid';
import axios from 'axios';
import MDSpinner from 'react-md-spinner';
import { withRouter } from 'react-router-dom';
import c from 'classnames';
import get from 'lodash/get';
import set from 'lodash/set';
import has from 'lodash/has';
import findIndex from 'lodash/findIndex';
import configAxios from '../../config/axios';
import FilterPart from '../../components/FilterPart/FilterPart';
import ScenarioContent from '../../components/ScenarioContent/ScenarioContent';
import ActiveFilters from '../../components/ActiveFilters';
import MissionsNavigation from '../../components/MissionsNavigation';
import DateComponent from '../../components/DateComponent/DateComponent';
import Preload from '../../constructors/Preload';
import { setDrillDownScenario, setPageLoadingStatus, setParentScenario } from '../../store/actions/actions';
import history from '../../config/history';
import styles from './Scenario.css';

class Scenario extends Component {
  static updateScenarioFiltersToStateFilters = (scenario, state) => {
    const datePickerIndex = findIndex(scenario.data.template.filters, s => s.type === 'datepicker');
    const datePickerData = get(scenario, `data.template.filters[${datePickerIndex}].data`);
    scenario.data.template.filters = state.reportTemplate.filters;
    return datePickerIndex !== -1
      ? set(scenario, `data.template.filters[${datePickerIndex}].data`, datePickerData)
      : scenario;
  };
  /**
   *
   * @type {number}
   */
  static TEMPLATE_UPDATE_DELAY = 1000;

  /**
   *
   * @param props
   */
  constructor(props) {
    super(props);

    this.disposables = [];

    this.state = {
      scenario: {},
      filters: {},
      drillFilters: {},
      filtersSequence: [],
      filtersSequenceSkip: null,
      filtersLoading: {},
      filtersUpdating: {},
      reportResponse: [],
      reportTemplate: {},
      templateLoading: false,
      blocking: [],
      blocked: true,
      emptyPage: false,
      reportTemplatePreloader: false,
      actualFilters: [],
      clearFilter: '',
      defaultFilters: [],
      isDrilled: false,
      drillScenario: null
    };

    this.preload = new Preload();
    this.preload.subscribe(promises => {
      this.props.dispatch(setPageLoadingStatus(promises.length > 0));
    });

    this.templateTimeout = null;
  }

  /**
   *
   */
  componentDidMount() {
    const {
      match: {
        params: { missionId = null, scenarioId = null }
      }
    } = this.props;

    const localMissions = localStorage.missions && JSON.parse(localStorage.missions);
    const missions = localMissions.find(({ id }) => id === +missionId);
    const scenarios = missions ? missions.scenarios : null;
    const scenario = scenarios && scenarios.find(({ id }) => id === +scenarioId);

    if (!scenario || !scenarios) {
      return history.push('/');
    }

    this.setState({ scenario }, () => {
      const promise = this.loadTemplate();

      promise.then(
        () => {
          this.setState({
            emptyPage: false
          });
        },
        () => {
          this.setState({
            emptyPage: true
          });
        }
      );

      this.preload.add(promise);
    });
  }

  /**
   * @param nextProps
   */
  componentWillReceiveProps(nextProps) {
    const {
      drillDownScenario,
      scenarios,
      mission: nextMission,
      match: {
        params: { scenarioId: nextScenarioId = null, missionId: nextMissionId = null }
      }
    } = nextProps;

    const {
      match: {
        params: { scenarioId = null, missionId = null }
      },
      mission,
      dispatch
    } = this.props;

    if (missionId !== nextMissionId) {
      dispatch(setParentScenario(scenarioId));
    }

    if (nextProps.parentScenario !== this.props.parentScenario) {
      this.drillDownToScenario(drillDownScenario);
    }

    if (missionId !== nextMissionId) {
      this.updateDisplay(false, false);
    }

    if (!nextScenarioId || nextScenarioId === scenarioId) {
      return;
    }

    let nextScenario = scenarios.find(item => {
      return item.id === +nextScenarioId;
    });

    if (!nextScenario) {
      history.push('/');
      return;
    }

    this.nextScenario(nextScenario, nextMission, mission);
  }

  /**
   *
   */
  componentWillUnmount() {
    this.disposables.forEach(fn => fn());
    this.props.dispatch(setDrillDownScenario({}));
  }

  nextScenario = (nextScenario, nextMission, mission) => {
    this.setState(
      {
        scenario: nextScenario,
        drillScenario: null,
        isDrilled: false
      },
      () => {
        if (mission.id !== nextMission.id) {
          this.setState(
            {
              reportTemplatePreloader: true,
              reportTemplate: {},
              filters: {},
              drillScenario: null,
              isDrilled: false
            },
            () => {
              const promise = this.loadTemplate();
              promise.then(
                () => {
                  this.setState({
                    emptyPage: false
                  });
                },
                () => {
                  this.setState({
                    emptyPage: true
                  });
                }
              );
              this.preload.add(promise);
            }
          );
        } else {
          const promise = this.loadTemplate();
          promise.then(
            () => {
              this.setState({
                emptyPage: false
              });
            },
            () => {
              this.setState({
                emptyPage: true
              });
            }
          );
          this.preload.add(promise);
        }
      }
    );
  };

  drillDownToScenario = scenario => {
    if (has(scenario, 'data')) {
      scenario = Scenario.updateScenarioFiltersToStateFilters(scenario, this.state);
      this.setState({
        reportTemplate: scenario.data.template,
        filters: this.state.drillFilters,
        isDrilled: true,
        drillScenario: {
          name: scenario.data.name,
          id: scenario.id,
          filters: scenario.data.template.filters
        }
      });
    } else {
      this.setState({
        isDrilled: false,
        drillScenario: null
      });
    }
  };

  purgeState = () => {
    this.setState({
      reportTemplatePreloader: true,
      reportTemplate: {}
    });
  };

  updateDisplay = (value, item, src, clearAll) => {
    const { defaultFilters } = this.state;

    if (!value) {
      Object.keys(defaultFilters).map(item => {
        this.setState({
          defaultFilters: {
            [item]: {}
          }
        });
      });
    }
    if (src) {
      const startValue = value.options.entity.source;

      if ((startValue && defaultFilters.length === 0) || clearAll) {
        this.setState(prevState => ({
          defaultFilters: {
            ...prevState.defaultFilters,
            [startValue]: {}
          }
        }));
      }
    }
    if (item === true) {
      const key = Object.keys(value)[0];
      this.setState(prevState => ({
        ...prevState,
        defaultFilters: {
          ...prevState.defaultFilters,
          [key]: value[key]
        }
      }));
    }
  };

  /**
   *
   * @returns {*}
   */
  loadTemplate = () => {
    const {
      dispatch,
      oldParentScenario,
      parentScenario,
      match: {
        params: { missionId = null }
      }
    } = this.props;

    const scenario = this.state.scenario;
    const filters = this.state.filters;
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    //prettier-ignore
    let scenarioId = this.state.drillScenario
      ? this.state.drillScenario.id
      	: this.state.scenario
      	? this.state.scenario.id
      	: null;

    const params = {
      mission: missionId,
      ...filters
    };

    if (this.state.drillScenario) {
      params.parent = oldParentScenario;
      scenarioId = parentScenario;
    } else {
      dispatch(setParentScenario(scenarioId));
    }

    if (scenario) {
      const promise = configAxios({
        method: 'GET',
        url: `/scenario/${scenarioId}?fields=template`,
        params,
        cancelToken: source.token
      });
      promise.then(
        response => {
          if (this.state.drillScenario) {
            response.data.template.filters = this.state.drillScenario.filters;
          }

          this.setState(
            {
              templateLoading: false,
              reportTemplate: response.data.template
            },
            () => {
              this.setState({
                reportTemplatePreloader: false
              });
            }
          );
        },
        () => {
          this.setState({
            templateLoading: false
          });
        }
      );

      this.disposables.push(() => source.cancel());

      return promise;
    } else {
      history.push('/');
    }
  };

  /**
   *
   * @param state
   * @param source
   */

  handleUpdateFiltersState = (state, source) => {
    const name = 'filtersLoading';
    const data = {
      ...this.state[name]
    };

    if (!state && data.hasOwnProperty(source)) {
      delete data[source];
    } else if (state) {
      data[source] = true;
    }

    this.setState({
      [name]: data
    });
  };

  setDrillFilters = filters => {
    this.setState({
      drillFilters: filters
    });
  };

  getFiltersForDrill = () => {
    const { filters, drillFilters } = this.state;

    const dataFilters = () => {
      // if (filters.date_start) {
      // 	delete drillFilters.date_start;
      // 	delete drillFilters.date_end;
      // }
      return { ...drillFilters, ...filters };
    };

    return Object.keys(filters).length ? dataFilters() : drillFilters;
  };

  /**
   *
   * @param filters
   * @param source
   * @param contentReload
   * @param transition
   */
  handleUpdateScenarioFilters = (filters, source, contentReload = true, transition) => {
    this.disposables.forEach(fn => fn());
    const { drillFilters } = this.state;
    let nextState;

    const clearDrill = {
      date_end: drillFilters.date_end,
      date_start: drillFilters.date_start
    };

    if (source === 'clearFilters') {
      nextState = {
        filters: filters,
        drillFilters: clearDrill
      };
    } else if (transition) {
      nextState = {
        filters: filters,
        drillFilters: { ...filters, ...drillFilters }
      };
    } else {
      nextState = {
        filters: filters,
        drillFilters: { ...filters, ...drillFilters }
      };
    }

    if (source === 'clearFilters') {
      nextState.filtersSequence = [];
      nextState.filtersSequenceSkip = '';
    } else {
      const data = this.createFiltersSequence(filters, source);
      nextState.filtersSequence = data.sequence;
      nextState.filtersSequenceSkip = data.skip;
    }

    if (source === 'purge') {
      nextState.filters = {};
      nextState.filtersSequence = [];
      nextState.filtersSequenceSkip = '';
      return true;
    } else if (source === 'purgeWithFetch') {
      this.setState(
        {
          reportTemplatePreloader: true,
          reportTemplate: {}
        },
        () => {
          nextState.filters = {};
          nextState.filtersSequence = [];
          nextState.filtersSequenceSkip = '';
        }
      );
    }

    this.setState(nextState, () => {
      if (this.templateTimeout) {
        clearTimeout(this.templateTimeout);
      }

      if (contentReload) {
        this.templateTimeout = setTimeout(() => {
          this.setState(
            {
              templateLoading: true
            },
            () => {
              this.loadTemplate();
            }
          );
        }, Scenario.TEMPLATE_UPDATE_DELAY);
      }
    });
  };

  /**
   *
   * @param nextFilters
   * @param source
   */
  createFiltersSequence(nextFilters, source) {
    const filtersSequence = this.state.filtersSequence;
    const filters = this.state.filters;

    const sequence = filtersSequence.slice();

    let skip = source;

    if (sequence.length === 0) {
      sequence.push(source);
    } else {
      const index = sequence.indexOf(source);

      if (index === -1) {
        sequence.push(source);
      } else {
        // const pluralSource = Scenario.getPluralSource(source);
        const pluralSource = source;
        const sourceValues = filters.hasOwnProperty(pluralSource) ? filters[pluralSource] : [];
        const nextSourceValues = nextFilters.hasOwnProperty(pluralSource) ? nextFilters[pluralSource] : [];

        if (nextSourceValues.length === 0) {
          sequence.splice(index, 1);
          skip = null;
        } else {
          const intersection = sourceValues.filter(function(n) {
            return nextSourceValues.indexOf(n) !== -1;
          });

          if (intersection.length < sourceValues.length) {
            const deleted = sequence.splice(index, 1);
            sequence.push(deleted[0]);
          }
        }
      }
    }

    return {
      sequence: sequence,
      skip: skip
    };
  }

  renderMissionNavigation = () => {
    const { drillDownScenario } = this.props;

    if (Object.keys(drillDownScenario).length && drillDownScenario.data) {
      return (
        <MissionsNavigation
          purgeFilters={this.handleUpdateScenarioFilters}
          drillDownScenario={drillDownScenario.data}
        />
      );
    }

    return <MissionsNavigation purgeFilters={this.handleUpdateScenarioFilters} />;
  };

  evalHtml = html => {
    return { __html: html };
  };

  render() {
    const {
      filters,
      filtersSequence,
      filtersSequenceSkip,
      templateLoading,
      blocking,
      emptyPage,
      reportTemplatePreloader,
      reportTemplate,
      isDrilled,
      drillFilters
    } = this.state;



    const { category } = this.state.defaultFilters;
    const drillToFilters = category ? { name: category.items, id: category.id } : ' ';
    const date = reportTemplate.filters ? reportTemplate.filters[0] : {};

    const color = '#00A5E6';
    const { pageLoadingStatus, language, mission, clearAllFilters } = this.props;

    const filtersLoading = Object.keys(this.state.filtersLoading).length > 0;

    const scenarioTranslation = {
      noDataReceived: language.translation ? language.translation.scenario.noDataReceived : null
    };

    if (blocking.length) {
      return (
        <div>
          <MissionsNavigation purgeFilters={this.handleUpdateScenarioFilters} />
          <div className={styles.empty} dangerouslySetInnerHTML={this.evalHtml(blocking[0].text)} />{' '}
          {/*eslint-disable-line*/}
        </div>
      );
    }
    let preLastCrumb;
    if (isDrilled) {
      preLastCrumb = <a href={window.location.pathname}>{this.state.scenario.name}</a>;
    } else {
      preLastCrumb = this.state.scenario.name;
    }
    const filterBlock = this.state.reportTemplate.filters;
    const widenValue = filterBlock && filterBlock.length > 0 ? 9 : 12;
    const missionLocalisation = language.translation.navigation.missions;
    const isRedash = this.state.scenario.link === "redash" || this.state.scenario.scenario_type === "redash";

    return (
      <div>
        <Grid fluid className={styles.containerGrid}>
          {!pageLoadingStatus && !emptyPage && (
            <Row className={isRedash ? "" : styles.flexRowWrap}>
              {reportTemplatePreloader && (
                <div className={styles.contentPreloadReport}>
                  <MDSpinner singleColor={color} size={40} />
                </div>
              )}
              {filterBlock && filterBlock.length > 0 && (
                <Col md={3} className={styles.filtersWrap}>
                  {this.state.reportTemplate.filters &&
                    this.state.reportTemplate.filters.map((item, indexItem) => {
                      const key = '' + indexItem;
                      switch (item.type) {
                        case 'datepicker':
                          return (
                            <DateComponent
                              key={key}
                              data={item}
                              filters={filters}
                              setDrillFilters={this.setDrillFilters}
                              updateFilters={this.handleUpdateScenarioFilters}
                            />
                          );
                        case 'filter':
                          return (
                            <FilterPart
                              key={key}
                              mission={mission}
                              data={item}
                              filters={filters}
                              filtersSequence={filtersSequence}
                              filtersSequenceSkip={filtersSequenceSkip}
                              filtersLoading={filtersLoading}
                              updateFilters={this.handleUpdateScenarioFilters}
                              updateFiltersState={this.handleUpdateFiltersState}
                              updateDisplay={this.updateDisplay}
                              setDrillFilters={this.setDrillFilters}
                              drillFilters={drillFilters}
                              drillToFilters={drillToFilters}
                              isDrilled={isDrilled}
                              clearAllFilters={clearAllFilters}
                            />
                          );
                        default:
                          return <div>default</div>;
                      }
                    })}
                </Col>
              )}
              <Col md={widenValue} className={isRedash ? styles.contentWrapRedash : styles.contentWrap}>
                <ol className={styles.breadcrumb}>
                  <li className={styles['breadcrumb-item']}>
                    <a href="/">{missionLocalisation}</a>
                  </li>
                  <li className={c(styles['breadcrumb-item'], { active: this.state.isDrilled })}>{preLastCrumb}</li>
                  {this.state.isDrilled && (
                    <li className={styles['breadcrumb-item']} aria-current="page">
                      {this.state.drillScenario.name}
                    </li>
                  )}
                </ol>
                {this.renderMissionNavigation()}
                <ScenarioContent
                  content={this.state.reportTemplate.content}
                  filters={this.getFiltersForDrill}
                  setDrillFilters={this.setDrillFilters}
                  updateFilters={this.handleUpdateScenarioFilters}
                  link={this.state.reportTemplate}
                />
              </Col>
              <ActiveFilters defaultDate={date} defaultFilters={this.state.defaultFilters} filters={filters} isRedash={isRedash} />
              {!reportTemplatePreloader && templateLoading && (
                <div className={styles.contentPreload}>
                  <MDSpinner singleColor={color} size={40} />
                </div>
              )}
            </Row>
          )}
          {!pageLoadingStatus && emptyPage && (
            <div className={styles.empty}>
              {this.renderMissionNavigation()}
              {scenarioTranslation.noDataReceived}
            </div>
          )}
        </Grid>
      </div>
    );
  }
}

Scenario.propTypes = {
  match: PropTypes.object.isRequired, // eslint-disable-line
  scenarios: PropTypes.array.isRequired, // eslint-disable-line
  dispatch: PropTypes.func.isRequired, // eslint-disable-line
  pageLoadingStatus: PropTypes.bool.isRequired, // eslint-disable-line
  language: PropTypes.object.isRequired, // eslint-disable-line
  mission: PropTypes.object.isRequired, // eslint-disable-line
  drillDownScenario: PropTypes.object, // eslint-disable-line
  oldParentScenario: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  parentScenario: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  clearAllFilters: PropTypes.bool.isRequired // eslint-disable-line
};

export default withRouter(
  connect(store => {
    return {
      userData: store.userData,
      scenarioProps: store.scenario,
      scenarios: store.scenarios,
      drillDownScenario: store.drillDownScenario,
      pageLoadingStatus: store.pageLoadingStatus,
      language: store.language,
      mission: store.mission,
      datePickerDate: store.datePickerDate,
      clearAllFilters: store.setClearAllFilters,
      oldParentScenario: store.oldParentScenario,
      parentScenario: store.parentScenario
    };
  })(Scenario)
);
