/**
 *
 * @type {boolean}
 */
export const isMobileBrowser =
  !!(
    navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)
  );

/**
 *
 * @type {boolean}
 */
export const isSafariBrowser = navigator.userAgent.toLowerCase().indexOf('safari/') > -1;

/**
 *
 * @type {boolean}
 */
export const isFirefoxBrowser = navigator.userAgent.toLowerCase().indexOf('firefox') > -1
  || navigator.userAgent.toLowerCase().indexOf('fxios') > -1;

/**
 *
 * @returns {boolean}
 */
export const isMobileFirefox = () => {
  return isFirefoxBrowser && window.innerWidth <= 767;
};

/**
 *
 * @returns {string}
 */
export const getBrowserPreferredSupportedLanguage = (val = null) => {
  const language = val ? val : navigator.language.split('-')[0];

  if (!language) {
    return 'ru';
  }

  switch (language.toLowerCase()) {
    case 'kk':
    case 'kz':
      return 'kk';
    case 'ru':
    case 'en':
    case 'af':
    case 'az':
    case 'be':
    case 'bg':
    case 'cs':
    case 'da':
    case 'de':
    case 'el':
    case 'es':
    case 'et':
    case 'fi':
    case 'fr':
    case 'ga':
    case 'hr':
    case 'hu':
    case 'hy':
    case 'id':
    case 'is':
    case 'it':
    case 'ka':
    case 'lt':
    case 'lv':
    case 'nb':
    case 'nl':
    case 'pl':
    case 'pt':
    case 'ro':
    case 'sk':
    case 'sl':
    case 'sq':
    case 'sr':
    case 'sv':
    case 'sw':
    case 'tr':
    case 'uk':
      return language.toLowerCase();
    default:
      return 'en';
  }
};