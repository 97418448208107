export default {
  mobileFirefoxAlert: 'Saor in aisce!',
  datePicker: {
    from: 'Ó',
    to: 'Go',
    cancel: 'Cealaigh',
    apply: 'Seol',
    month: [
      'Eanáir',
      'Feabhra',
      'Márta',
      'Aibreán',
      'Bealtaine',
      'Meitheamh',
      'Iúil',
      'Lúnasa',
      'Meán Fómhair',
      'Deireadh Fómhair',
      'Samhain',
      'Mí na Nollag'
    ],
    week: 'seachtain',
    weekShot: 's',
    buttonName: {
      date: 'Dáta',
      week: 'Seachtain',
      month: 'Mí'
    },
    modal: {
      header: 'Rabhadh',
      text: 'Roghnaíodh sraith dátaí neamhbhailí',
      action: 'Glac'
    }
  },
  filters: {
    name: 'Ainm'
  },
  scenario: {
    noDataReceived: 'Gan sonraí faighte',
    filterPart: {
      name: 'Ainm',
      clear: 'Glain',
      notFound: 'Gan aimsiú',
      search: 'Cuardaigh...'
    }
  },
  header: {
    logout: 'Logáil Amach',
    changePassword: 'Athraigh Pasfhocal',
    settings: 'Socruithe',
    title: 'Amharc ar an Ghráis-Aipe',
    searchTitle: 'Cuardaigh Úsáideoir',
    input: 'Tosaigh ag scriobh ainm nó logáil isteach...',
    cancel: 'Cealaigh',
    view: 'Amharc',
    id: 'Aitheantas',
    login: 'Logáil',
    name: 'Ainm',
    phone: 'Fón',
    email: 'Ríomhphost',
    stopSettings: 'Stop an Féachaint'
  },
  directTask: {
    addCase: 'Cás a chur leis',
    commentCase: 'Nóta maidir leis an gCás',
    actions: 'Gníomhaíochtaí',
    searchProduct: 'PLU/Bacód',
    searchProductShort: 'PLU/Bacód',
    photo: 'Grianghraf',
    back: 'Siar',
    treeEmpty: 'NÍL GNIOMHAÍOCHTAÍ AR FÁIL',
    step: 'Céim',
    sortingButton: 'Sórtáil',
    sortButton: 'Sórtáil',
    add: 'Cuir',
    delete: 'Scrios',
    deleteManualCases: 'Scrios cásanna lámhleabhair',
    show: 'Taispeáin',
    hide: 'Folaigh',
    comment: 'Nóta',
    showDoneCases: 'Taispeáin tascanna críochnaithe',
    hideDoneCases: 'Folaigh tascanna críochnaithe'
  },
  gridTable: {
    pluList: 'Liosta PLU'
  },
  casesForm: {
    product: 'Táirge',
    loadFoto: 'Íoslódáil Grianghraif',
    plu: 'Ainm/PLU',
    success: 'Tá cás cruthaithe',
    delete: 'Scrios',
    add: 'Cruthaigh',
    new: 'Nua',
    cancel: 'Cealaigh',
    comment: 'Nóta'
  },
  pagination: {
    apply: 'Téigh',
    placeholder: 'Leathanach'
  },
  restorePass: {
    noLength: 'Ní mór go mbeadh 12 ghréasán ar an pasfhocal',
    noLowercase: 'Ní mór go mbeadh litir bheag amháin sa phasfhocal',
    noUppercase: 'Ní mór go mbeadh litir mhór amháin sa phasfhocal',
    noNumber: 'Ní mór go mbeadh uimhir amháin ar a laghad sa phasfhocal',
    noMatch: 'Níl na pasfhocail comhoiriúnach',
    password: 'Pasfhocal',
    repeatPassword: 'Athdhéan Pasfhocal',
    ok: 'Glac',
    cancel: 'Cealaigh'
  },
  navigation: {
    missions: 'Miseananna',
    scenarios: 'Réimsí'
  },
  changePassword: {
    previousPassword: 'Pasfhocal Reatha',
    newPassword: 'Pasfhocal Nua',
    confirmPassword: 'Athdhéan Pasfhocal',
    apply: 'Seol',
    title: 'Athraigh Pasfhocal',
    success: 'Athraíodh an Pasfhocal go rathúil',
    wrongPassword: 'Pasfhocal mícheart',
    weakPassword: 'Níl an Pasfhocal oiriúnach',
    passwordsNotMatch: 'Níl na pasfhocail comhoiriúnach',
    requirements: 
      'Ní mór go mbeadh 12 ghréasán ar a laghad, 1 litir mhór agus litir bheag agus 1 uimhir'
  },
  auth: {
    login: 'Logáil',
    password: 'Pasfhocal',
    restorePass: 'Athshocraigh Pasfhocal',
    submit: 'Cuir isteach',
    loginError: 'Tá login agus/nó pasfhocal neamhbhailí'
  },
  restorePassForm: {
    restore: 'Athshocrú',
    cancel: 'Cealaigh',
    info: 'Cuir isteach do sheoladh ríomhphoist, atá cláraithe ar an gPlátform Híbrid OSA',
    emailInvalid: 'Níor aimsíodh an ríomhphost a chuirtear isteach',
    email: 'Ríomhphost',
    success: 'Seoladh athshocrú chuig do ríomhphost'
  },
  search: {
    notFound: 'Gan aimsiú'
  },
  noMatchPage: {
    title: 'Níor aimsíodh an leathanach a iarradh',
    body: 'Fill ar an bPríomhleathanach'
  },
  warnings: {
    title: 'Rabhadh',
    isDesktop: 'Níl sé sin féidir ach sa leagan guthán ríomhaire. Bain úsáid as an gcarrchlár ar do ghuthán nó ar do táibléad, le do thoil.'
  },
  errors: {
    netErrorText: 'Earráid líonra. Athlódáil an leathanach, le do thoil',
    netErrorReload: 'Athlódáil',
    netErrorTitle: 'Earráid',
    netErrorClose: 'Dún',
    noShops: 'Ó! Tá an chuma ar an scéal nár sannadh aon siopa duit go fóill. Inis do do bhainisteoir faoi',
    noCases: 'Dealraíonn sé nach bhféadfadh ár seirbhís cás amháin a aimsiú sa siopa seo. Tosaigh iad a chruthú duit féin',
    noAutoOrder: 'Níl aon tascanna ann chun an t-ordú uathoibríoch a choigeartú.',
    causeError: 'Earráid',
    scenariosEmpty: 'Tá raon scéimeanna folamh'
  },
  officeUI: {
    resetZoom: 'Athshocrú Zúmáil'
  },
  gridAutocomplete: {
    placeholder: 'Tosaigh ag scríobh ainm an tsuímh',
    notFound: 'Ainm an Siopa nach bhfuil faighte'
  },
  targets: {
    nothing: '',
    shop: 'Siopa',
    category: 'Catagóir',
    supplier: 'Soláthraí',
    vendor: 'Díoltóir',
    brand: 'Marca',
    plu: 'PLU',
    day: 'Lá',
    week: 'Seachtain',
    month: 'Mí',
    quarter: 'Ráithe',
    year: 'Bliain'
  },
  bubbleChart: {
    qty: 'sá',
    currency: 'rúbal'
  },
  chart: {
    quarter: 'Ráithe',
    week: 'Seachtain'
  },
  download: {
    CSV: 'Íoslódáil CSV',
    XLS: 'Íoslódáil XLS'
  },
  counterTask: {
    task: 'Obair faoi lán seoil',
    noTask: 'Gan tascanna'
  },
  filtersShop: {
    shopName: 'Seoladh an Siopa',
    shopId: 'Ainm an Siopa'
  },
  chartCaption: {
    thousand: 'k',
    million: 'M',
    billion: 'G'
  },
  common: {
    close: 'Dún',
    userNotFound: 'Níor aimsíodh an t-úsáideoir',
    yes: 'Tá',
    no: 'Níl'
  },
  virtualStock: {
    headers: {
      case_id: 'ID',
      plu_original: 'PLU',
      plu: 'PLU Inmheánach',
      shop_id: 'ID an Siopa',
      ass_type: 'Cineál Asórtála',
      category_id3: 'ID na Rannáin',
      category_id4: 'ID an Chatagóra',
      stock: 'Tomhaltas Táirgthe',
      stock_sum: 'Meud Tomhaise',
      product_turnover: 'Tionscadal',
      days_without_sales: 'Lá gan díol',
      last_sales_date: 'Dáta díola deiridh',
      last_sales_volume: 'Méid díola deiridh',
      last_shipment_date: 'Dáta seachadta deiridh',
      last_shipment_volume: 'An méid seachadta deiridh',
      promo_start: 'Tús an Phróimó',
      promo_end: 'Deireadh an Phróimó',
      promo_period: 'Tréimhse Phróimó',
      is_promo: 'Promó',
      title: 'Ainm',
      category_name3: 'Rannáin',
      category_name4: 'Catagóir'
    },
    hideReadyTasks: 'Folaigh tascanna réidh',
    nothingFound: 'Gan rud ar bith Faighte',
    shopsNotFound: 'Níor aimsíodh siopaí',
    chooseShop: 'Roghnaigh siopa',
    createTasks: 'Cruthaigh tascanna',
    taskCreating: 'Tascanna á chruthú',
    next: 'Lean ar aghaidh',
    columns: 'Colúin',
    from: 'Ó',
    ok: 'OK',
    cancel: 'Cealaigh',
    filter: 'Scag',
    chooseValue: 'Roghnaigh luach',
    enterValue: 'Cuir luach isteach',
    rangeFrom: 'Ó',
    rangeTo: 'Go',
    selected: 'Roghnaigh',
    foundRows: 'Riadhanna faighte',
    createdTasksTotal: 'Iomlán na nascanna cruthaithe'
  }
};
