import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import styles from './ChangePassword.css';
import { addUserData, initLocal, setPageLoadingStatus } from '../../store/actions/actions';
import configAxios from '../../config/axios';
import history from '../../config/history';
import ButtonDefault from '../Buttons/ButtonDefault';

class ChangePassword extends Component {
  state = {
    prevPassword: '',
    newPassword: '',
    proof: '',
    weakPasswordError: false,
    passwordsNotMatchError: false,
    wrongPasswordError: false,
    successChange: false,
    successMessage: false
  };

  UNSAFE_componentWillMount() {
    const { dispatch, userData } = this.props;
    const user = JSON.parse(localStorage.getItem('userData'));
    if (user) {
      dispatch(initLocal(user.language));
    }
    if (userData.first_login) {
      dispatch(initLocal(userData.language));
      dispatch(setPageLoadingStatus(false));
    }
  }

  handleChange = name => e => {
    this.setState({ [name]: e.target.value });
  };

  submitHandle = () => {
    const { prevPassword, newPassword, proof } = this.state;
    const { dispatch } = this.props;
    const goodPassword = this.newRegExpFn(newPassword);
    dispatch(setPageLoadingStatus(true));
    this.setState({
      weakPasswordError: false,
      passwordsNotMatchError: false,
      wrongPasswordError: false
    });
    if (!goodPassword) {
      dispatch(setPageLoadingStatus(false));
      this.setState({
        weakPasswordError: true
      });
    } else if (newPassword !== proof) {
      dispatch(setPageLoadingStatus(false));
      this.setState({
        passwordsNotMatchError: true
      });
    } else if (prevPassword.length > 0) {
      const promise = configAxios({
        method: 'POST',
        url: '/user/change-pass',
        data: {
          old_password: prevPassword,
          new_password: newPassword
        }
      });

      promise.then(
        res => {
          dispatch(setPageLoadingStatus(false));
          this.setState({
            successChange: true,
            successMessage: res.data.message,
            weakPasswordError: false,
            passwordsNotMatchError: false,
            wrongPasswordError: false
          });
        },
        () => {
          dispatch(setPageLoadingStatus(false));
          this.setState({
            wrongPasswordError: true
          });
        }
      );
    } else {
      dispatch(setPageLoadingStatus(false));
      this.setState({
        wrongPasswordError: true
      });
    }
  };

  newRegExpFn = val => {
    const pattern = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{12,})');
    return pattern.test(val);
  };

  submitHandleResetFirstPassword = () => {
    const { newPassword, proof } = this.state;
    const { dispatch } = this.props;
    const goodPassword = this.newRegExpFn(newPassword);
    dispatch(setPageLoadingStatus(true));
    this.setState({
      weakPasswordError: false,
      passwordsNotMatchError: false,
      wrongPasswordError: false
    });

    if (!goodPassword) {
      dispatch(setPageLoadingStatus(false));
      this.setState({
        weakPasswordError: true
      });
    } else if (newPassword === proof) {
      const { userData } = this.props;
      if (configAxios.defaults.headers.Authorization === 'Bearer null') {
        configAxios.defaults.headers = {
          Authorization: 'Bearer ' + userData.access_token
        };
      }

      const promise = configAxios({
        method: 'POST',
        url: '/user/first-visit-pass',
        data: {
          password: newPassword
        }
      });

      promise.then(
        res => {
          dispatch(setPageLoadingStatus(false));
          this.setState({
            successChange: true,
            successMessage: res.data.message,
            weakPasswordError: false,
            passwordsNotMatchError: false,
            wrongPasswordError: false
          });
        },
        () => {
          dispatch(setPageLoadingStatus(false));
          this.setState({
            wrongPasswordError: true
          });
        }
      );
    } else {
      dispatch(setPageLoadingStatus(false));
      this.setState({
        wrongPasswordError: true
      });
    }
  };

  handleClose = () => {
    const { dispatch } = this.props;
    this.setState({
      successChange: false
    });
    localStorage.clear();
    dispatch(addUserData({}));
    history.push('/');
  };

  render() {
    const {
      prevPassword,
      newPassword,
      proof,
      wrongPasswordError,
      passwordsNotMatchError,
      weakPasswordError,
      successChange,
      successMessage
    } = this.state;
    const { translation } = this.props.language;
    const { first_login } = this.props.userData;
    return (
      <div className={styles.wrap}>
        <div className={styles.changePassword}>
          <h3 className={styles.title}>{translation.changePassword.title}</h3>
          <h4 className={styles.subtitle}>{translation.changePassword.requirements}</h4>
          {!first_login && (
            <div className={styles.inputWrap}>
              <TextField
                fullWidth
                name="prev"
                onChange={this.handleChange('prevPassword')}
                label={translation.changePassword.previousPassword}
                value={prevPassword}
                type="password"
                margin="normal"
                error={wrongPasswordError}
                helperText={wrongPasswordError ? translation.changePassword.wrongPassword : ''}
              />
            </div>
          )}
          <div className={styles.inputWrap}>
            <TextField
              fullWidth
              name="next"
              onChange={this.handleChange('newPassword')}
              value={newPassword}
              type="password"
              margin="normal"
              label={translation.changePassword.newPassword}
              error={weakPasswordError}
              helperText={weakPasswordError ? translation.changePassword.weakPassword : ''}
            />
          </div>
          <div className={styles.inputWrap}>
            <TextField
              fullWidth
              name="confirm"
              onChange={this.handleChange('proof')}
              value={proof}
              type="password"
              margin="normal"
              label={translation.changePassword.confirmPassword}
              error={passwordsNotMatchError}
              helperText={passwordsNotMatchError ? translation.changePassword.passwordsNotMatch : ''}
            />
          </div>
          <div className={styles.buttonWrap}>
            <ButtonDefault
              style={{ margin: '0 auto' }}
              onClick={first_login ? this.submitHandleResetFirstPassword : this.submitHandle}
            >
              {translation.changePassword.apply}
            </ButtonDefault>
          </div>
        </div>
        <Dialog open={successChange} maxWidth="sm" fullWidth onClose={this.handleClose}>
          <DialogContent>
            <DialogContentText>{successMessage}</DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

ChangePassword.defaultProps = {
  language: {}
};

ChangePassword.propTypes = {
  language: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired
};

const mapStateToProps = store => ({
  language: store.language,
  userData: store.userData
});

export default withRouter(connect(mapStateToProps)(ChangePassword));
