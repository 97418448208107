import VirtualStockFactory from './VirtualStockFactory';
import configAxios from '../../../config/axios';

class VirtualStockRepository {

  constructor() {
    this.map = {};
  }

  /**
   *
   * @param missionId
   * @param scenarioId
   * @param shopId
   * @returns {Promise<array>}
   */
  async getAll({ missionId, scenarioId, shopId }) {
    const request = configAxios({
      method: 'GET',
      url: '/virtual-stock',
      params: { missionId, scenarioId, shopId, page: 1, limit: 10000 }
    });

    return request.then(response => {
      const items = response.data.items;
      if (!Array.isArray(items) || !items.length) {
        return [];
      }

      return items.map(item => {
        return VirtualStockFactory.createFromServerData(item);
      });
    });
  }
}

export const virtualStockRepository = new VirtualStockRepository();