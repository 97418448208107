import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router-dom';
import history from '../../config/history';
import {
  addUserData,
  showError as showErrorFn,
  showErrorNotAllowed as showErrorNotAllowedFn
} from '../../store/actions/actions';

import Header from '../../routes/Header/Header';
import Home from '../../routes/Home';
import Scenario from '../../routes/Scenario/Scenario';
import Task from '../../routes/Task/Task';
import TaskAudit from '../../routes/TaskAudit/TaskAudit';
import AssortmentMatrix from '../../routes/AssortmentMatrix/AssortmentMatrix';
import AutoOrder from '../../routes/AutoOrder/AutoOrder';
import AssortmentMatrixAudit from '../../routes/AssortmentMatrixAudit/AssortmentMatrixAudit';
import Promo from '../../routes/Promo/Promo';
import PromoAudit from '../../routes/PromoAudit/PromoAudit';
import Equipment from '../../routes/Equipment/Equipment';
import EquipmentAudit from '../../routes/EquipmentAudit/EquipmentAudit';
import CasesForm from '../../routes/CasesForm/CasesForm';
import EquipmentForm from '../../routes/EquipmentForm/EquipmentForm';
import ErrorDialog from '../../components/ErrorDialog';
import ErrorDialogSelect from '../../components/ErrorDialogSelect';
import Preload from '../../components/Preload/Preload';
import KpiDebug from '../../components/KpiDebug/KpiDebug';
import ChangePassword from '../../components/ChangePassword/ChangePassword';
import Planogram from '../../routes/Planogram';
import PlanogramAudit from '../../routes/PlanogramAudit/PlanogramAudit';
import globalStyles from '../../assets/global.css';
import NetworkErrorDialog from '../../components/NetworkErrorDialog';
import AuthWithToken from '../../routes/Auth/AuthWithToken/AuthWithToken';
import VirtualStock from '../../routes/VirtualStock/VirtualStock';

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Guard = props => {
  const { children, location, dispatch, userData, errorData, errorDataNotAllowed } = props;
  const tokenLocal = localStorage.userData ? JSON.parse(localStorage.userData) : null;
  const tokenToken = Object.getOwnPropertyNames(userData).length !== 0 ? userData.access_token : null;
  const token = tokenLocal || tokenToken;

  useEffect(() => {
    const exp = localStorage.getItem('impersonation');
    if (exp === 'expired') {
      localStorage.removeItem('impersonation');
      history.push('/');
    }
  }, []);

  const handleDialogState = callBack => {
    const { errorMsg, errorType } = errorData;
    const exp = localStorage.getItem('impersonation');
    dispatch(callBack(false, errorMsg, errorType));
    if (exp === 'expired') {
      localStorage.removeItem('impersonation');
      history.push('/');
    }
  };

  if (token) {
    if (Object.getOwnPropertyNames(userData).length === 0) {
      dispatch(addUserData(tokenLocal));
    }

    if (userData.first_login) {
      return (
        <div>
          <Header />
          <div className={globalStyles.container}>
            <Route path="/" component={ChangePassword} />
          </div>
          <ErrorDialog
            active={errorData.showError}
            errorText={errorData.errorMsg}
            handleDialogState={() => handleDialogState(showErrorFn)}
          />
          <KpiDebug />
          <ErrorDialogSelect
            active={errorDataNotAllowed.showError}
            errorText={errorDataNotAllowed.errorMsg}
            handleDialogState={() => handleDialogState(showErrorNotAllowedFn)}
          />
          <NetworkErrorDialog />
          <Preload />
        </div>
      );
    }
    return (
      <div>
        <Header />
        <div className={globalStyles.container} id="anchor">
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/scenario/:missionId/:scenarioId" component={Scenario} />
            <Route path="/change-password" component={ChangePassword} />
            <Route path="/directTask" component={Task} />
            <Route path="/assort-matrix" component={AssortmentMatrix} />
            <Route path="/autoorder" component={AutoOrder} />
            <Route path="/promo" component={Promo} />
            <Route path="/equipment" component={Equipment} />
            <Route path="/planogram" component={Planogram} />
            <Route path="/audit" component={TaskAudit} />
            <Route path="/virtual-stock" component={VirtualStock} />
            <Route path="/audit-promo" component={PromoAudit} />
            <Route path="/audit-equipment" component={EquipmentAudit} />
            <Route path="/audit-assort-matrix" component={AssortmentMatrixAudit} />
            <Route path="/audit-planogram" component={PlanogramAudit} />
            <Route path="/CasesForm" component={CasesForm} />
            <Route path="/EquipmentForm" component={EquipmentForm} />
            <Route path="/auth" component={AuthWithToken} />
            <Route render={() => <Redirect to="/" />} />
          </Switch>
        </div>
        <ErrorDialog
          active={errorData.showError}
          errorText={errorData.errorMsg}
          handleDialogState={() => handleDialogState(showErrorFn, props)}
        />
        <KpiDebug />
        <ErrorDialogSelect
          active={errorDataNotAllowed.showError}
          errorText={errorDataNotAllowed.errorMsg}
          handleDialogState={() => handleDialogState(showErrorNotAllowedFn, props)}
        />
        <NetworkErrorDialog />
        <Preload />
      </div>
    );
  } else if (location.pathname !== '/site/reset-password' && location.pathname !== '/auth') {
    if (location.pathname !== '/') {
      history.push('/');
    }
  }

  return <div>{children}</div>;
};

Guard.defaultProps = {
  userData: {},
  scenarios: [],
  errorData: {},
  errorNetworkFailed: {},
  errorDataNotAllowed: {}
};

Guard.propTypes = {
  location: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  userData: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  errorData: PropTypes.object,
  errorDataNotAllowed: PropTypes.object
};

export default connect(
  store => {
    return {
      userData: store.userData,
      scenarios: store.scenarios,
      errorData: store.errorData,
      errorNetworkFailed: store.errorNetworkFailed,
      errorDataNotAllowed: store.errorDataNotAllowed
    };
  }
)(Guard);
