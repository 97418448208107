import React from 'react';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import cls from 'classnames';
import PropTypes from 'prop-types';

const styles = () => ({
  root: {
    // color: '#ada',
    backgroundColor: '#fff',
    textTransform: 'uppercase'
    // '&:hover': {
    //   backgroundColor: '#0069d9'
    // }
  }
});

const ButtonDefault = props => {
  const { className, classes, children } = props;
  return (
    <Button {...props} variant="contained" className={cls(className, classes.root)}>
      {children}
    </Button>
  );
};

ButtonDefault.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired
};

export default withStyles(styles)(ButtonDefault);
