import React from 'react';

import styles from './KeyboardReturnIcon.css';

const KeyboardReturnIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={styles.svgIcon}
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M19 7v4H5.83l3.58-3.59L8 6l-6 6 6 6 1.41-1.41L5.83 13H21V7z" />
    </svg>
  );
};
export default KeyboardReturnIcon;
