import { createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

const theme = createMuiTheme({
  typography: {
    fontFamily: ['MuseoSansRounded', 'sans-serif', '-apple-system'].join(','),
    fontWeight: 400,
    htmlFontSize: 16
  },
  palette: {
    default: {
      main: '#3f50b5'
    },
    primary: {
      main: '#183f5d'
    },
    secondary: {
      main: red.A200
    }
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
        borderRadius: '4px',
      },
      text: {
        paddingLeft: '16px',
        paddingRight: '16px'
      }
    },
    MuiDialog: {
      root: {
        // width: '400px',
      },
      paper: {
        borderRadius: '4px',
        width: '450px'
      }
    },
    MuiDialogActions: {
      root: {
        padding: '16px 24px'
      }
    }
  }
});

export default theme;