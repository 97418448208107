import ru from './ru';
import en from './en';
import kk from './kz';
import af from './af';
import az from './az';
import be from './be';
import bg from './bg';
import cs from './cs';
import da from './da';
import de from './de';
import el from './el';
import es from './es';
import et from './et';
import fi from './fi';
import fr from './fr';
import ga from './ga';
import hr from './hr';
import hu from './hu';
import hy from './hy';
import id from './id';
import is from './is';
import it from './it';
import ka from './ka';
import lt from './lt';
import lv from './lv';
import nb from './nb';
import nl from './nl';
import pl from './pl';
import pt from './pt';
import ro from './ro';
import sk from './sk';
import sl from './sl';
import sq from './sq';
import sr from './sr';
import sv from './sv';
import sw from './sw';
import tr from './tr';
import uk from './uk';

export default {
  ru,
  en,
  kk,
  af,
  az,
  be,
  bg,
  cs,
  da,
  de,
  el,
  es,
  et,
  fi,
  fr,
  ga,
  hr,
  hu,
  hy,
  id,
  is,
  it,
  ka,
  lt,
  lv,
  nb,
  nl,
  pl,
  pt,
  ro,
  sk,
  sl,
  sq,
  sr,
  sv,
  sw,
  tr,
  uk,
};