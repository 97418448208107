import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import Preload from '../../../constructors/Preload';
import { loadTask, setPageLoadingStatus, searchProduct } from '../../../store/actions/actions';
import { isMobileBrowser } from '../../../utils/browser';
import configAxios from '../../../config/axios';
import AlertTaskDropdownDialog from '../AlertTaskDropdownDialog';

import styles from './TaskDropdown.css';

const style = theme => ({
  rightIcon: {
    marginLeft: theme.spacing(1)
  }
});

class Dropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openMenu: false,
      item: {},
      imageFile: null,
      itemId: null,
      anchorEl: null,
      openDialog: false
    };

    this.preload = new Preload();
    this.preload.subscribe(promises => {
      this.props.dispatch(setPageLoadingStatus(promises.length > 0));
    });
  }

  handleCloseMenu = () => {
    this.setState({ openMenu: false, anchorEl: null });
  };

  handleOpenMenu = e => {
    this.setState({ openMenu: true, anchorEl: e.currentTarget });
  };

  handleAction = (e, item) => {
    if (item.form === 'img') {
      if (!isMobileBrowser) {
        this.setState({
          openDialog: true
        });
        return false;
      }
      const file = e.target.files;
      if (file && file.length) {
        this.handlePhoto(file, item);
      }
    } else {
      this.setState(
        {
          item,
          itemId: this.props.itemId,
          openMenu: false,
          anchorEl: null
        },
        () => {
          this.loadActionToServer();
        }
      );
    }
  };

  handlePhoto = (file, item) => {
    this.setState(
      {
        item,
        itemId: this.props.itemId,
        openMenu: false,
        anchorEl: null,
        imageFile: file
      },
      () => {
        this.loadActionToServer();
      }
    );
  };

  loadActionToServer() {
    const {
      dispatch,
      shopId,
      manual,
      newTree,
      casesForm,
      searchProductStore,
      setToggleCases,
      caseType,
      urlApi,
      isAuditTask,
    } = this.props;
    const { itemId, item, imageFile } = this.state;

    const aestTime = new Date().toLocaleString("en-US", { timeZone: 'Atlantic/Reykjavik' });
    const timeForUpdate = new Date(aestTime).toISOString();
    const isAutoOrder = urlApi === "auto-order";

    const query = new URLSearchParams(location.search);
    const mid = query.has('mid') ? Number(query.get('mid')) : JSON.parse(localStorage.mission).id;

    let formData = new FormData();
    let formDataAutoOreder = {
      missionId: parseInt(mid),
      shopId: shopId,
      status: item.actionTypeTo,
      actionDateTime: moment(timeForUpdate).format("YYYY-MM-DD HH:mm:ss")
    };
    formData.append('itemId', itemId);
    formData.append('manual', manual);
    formData.append('actionTypeTo', item.actionTypeTo);
    formData.append('actionTypeFrom', item.actionTypeFrom);
    formData.append('missionId', mid);
    formData.append('shopId', shopId);
    formData.append('caseType', caseType);

    if(!this.props.isAuditTask) {
      formData.append('actionDateTime', moment(timeForUpdate).format("YYYY-MM-DD HH:mm:ss"));
    }


    item.previous ? formData.append('previous', 1) : null;

    if (imageFile && imageFile.length) {
      for (let i = 0; i < imageFile.length; i++) {
        formData.append('imageFiles[' + i + ']', imageFile[i]);
      }
    }
   
    const url = isAuditTask ? `/${urlApi}/audit-update` : `/${urlApi}/update`;
    const promise = configAxios({
      method: isAutoOrder ? 'PUT' : 'POST',
      url: isAutoOrder ? `/${urlApi}/${itemId}` : url,
      data: isAutoOrder ? formDataAutoOreder : formData
    });

    this.preload.add(promise);

    promise
      .then(response => {
        if (response.status === 200) {
          this.setState(
            {
              imageFile: null,
              openMenu: false,
              anchorEl: null
            },
            () => {
              if (!casesForm) {
                dispatch(loadTask(true));
              }
            }
          );
          if (manual) {
            newTree(response.data);
          }
          if (searchProductStore && setToggleCases === true) {
            dispatch(searchProduct(null));
          }
        }
      })
      .catch(() => {
        this.setState({
          imageFile: null,
          openMenu: false,
          anchorEl: null
        });
      });
  }

  handleClose = () => {
    this.setState({
      openDialog: false
    });
  };

  render() {
    const { anchorEl, openMenu, openDialog } = this.state;
    const {
      classes,
      data = [],
      language: {
        translation: { directTask: lang, warnings }
      }
    } = this.props;

    return (
      <Fragment>
        <Button variant="outlined" class={styles.actionButton} onClick={this.handleOpenMenu}>
          {lang.actions} <KeyboardArrowDownIcon class={classes.rightIcon} />
        </Button>
        <Menu anchorEl={anchorEl} open={openMenu} onClose={this.handleCloseMenu}>
          {data.map(item => (
            <MenuItem key={item.name} class={styles.menuItem} onClick={e => this.handleAction(e, item)}>
              {item.name}
              {item.form === 'img' && (
                <input
                  type="file"
                  className={styles.imageInput}
                  onClick={e => {
                    if (!isMobileBrowser) {
                      e.preventDefault();
                    }
                  }}
                  onChange={e => this.handleAction(e, item)}
                  multiple
                  accept="image/*"
                  capture="camera"
                />
              )}
            </MenuItem>
          ))}
        </Menu>
        <AlertTaskDropdownDialog open={openDialog} handleClose={this.handleClose} translations={warnings} />
      </Fragment>
    );
  }
}

Dropdown.defaultProps = {
  isAuditTask: false,
  itemId: null,
  newTree: null,
  manual: 0,
  casesForm: false,
  searchProductStore: null,
  setToggleCases: {}
};

Dropdown.propTypes = {
  isAuditTask: PropTypes.bool,
  urlApi: PropTypes.string.isRequired,
  data: PropTypes.array,
  dispatch: PropTypes.func.isRequired,
  itemId: PropTypes.number.isRequired,
  shopId: PropTypes.number.isRequired,
  newTree: PropTypes.func,
  manual: PropTypes.number,
  casesForm: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  language: PropTypes.object.isRequired,
  searchProductStore: PropTypes.number,
  setToggleCases: PropTypes.object.isRequired,
  caseType: PropTypes.number.isRequired
};

export default connect(store => {
  return {
    language: store.language,
    searchProductStore: store.searchProduct,
    setToggleCases: store.setToggleCases
  };
})(withStyles(style)(Dropdown));
