import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table as TableToolBox, TableHead, TableRow, TableCell } from 'react-toolbox/lib/table';
import cls from 'classnames';
import { IconButton } from 'react-toolbox/lib/button';
import styles from './Table.css';
import ColumnFilter from './ColumnFilter/ColumnFilter';

const Table = ({
  items = [],
  processedItems = [],
  headers = [],
  selected = [],
  onSelect = fn => fn,
  onOrderChange = fn => fn,
  onFilterChange = fn => fn
}) => {

  const [header, setHeader] = useState(null);
  const processedItemsLength = useMemo(() => {
    return processedItems.length;
  }, [processedItems]);

  /**
   *
   * @param header
   */
  const handleOrderChange = header => {
    header.toggleOrder();
    onOrderChange(header);
  };

  /**
   *
   * @param header
   * @param value
   */
  const handleFilterChange = (header, value) => {
    header.filter.value = value;
    onFilterChange(headers);
  };

  /**
   *
   * @param header
   */
  const handleHeaderFilterOpen = header => {
    setHeader(header);
  };

  /**
   *
   */
  const handleHeaderFilterClose = () => {
    setHeader(null);
  };

  const orderReplace = ['', '&#9660;', '&#9650;'];

  return (
    <div>
      <div className={styles.tableContainer}>
        <div className={styles.tableWrap}>
          <TableToolBox
            selectable
            multiSelectable
            onRowSelect={onSelect}
            className={processedItemsLength === 0 ? styles.emptyTable : ''}
          >
            <TableHead className={styles.tableHeadRow} theme={{ checkboxCell: styles.tableCellCheckbox }}>
              <TableCell key={0} className={styles.tableCell} />
              {headers.filter(header => header.visible).map(header => {
                return (
                  <TableCell key={header.property} className={styles.tableCell}>
                    <div className={styles.tableHeaderCellWrap}>
                      <button
                        className={cls(
                          styles.tableCellOrderButton,
                          (header.order) && styles.tableCellOrderButtonActive
                        )}
                        onClick={() => handleOrderChange(header)}
                      >
                        {header.name} <span dangerouslySetInnerHTML={{ __html: orderReplace[header.order] }} />
                      </button>
                      {header.hasFilter() && (
                        <IconButton
                          icon="filter_alt"
                          ripple={false}
                          flat
                          className={cls(
                            styles.filterAlt,
                            (header.hasNotEmptyFilter()) && styles.filterAltActive
                          )}
                          onClick={() => handleHeaderFilterOpen(header)}
                        />
                      )}
                    </div>
                  </TableCell>
                );
              })}
            </TableHead>
            {processedItemsLength > 0 && processedItems.map(item => {
              return (
                <TableRow
                  key={item.case_id}
                  idx={item.case_id}
                  theme={{ checkboxCell: styles.tableCellCheckbox }}
                  className={cls(
                    (item.hasChildCase() && styles.disabledRow), styles.tableRow
                  )}
                  selected={selected.includes(item.case_id)}
                >
                  <TableCell key={0} className={styles.tableCell} />
                  {headers.filter(header => header.visible).map(header => {
                    return (
                      <TableCell
                        key={header.property}
                        className={cls(
                          styles.tableCell,
                          (header.order || header.hasNotEmptyFilter()) && styles.tableCellColumnActive
                        )}
                      >
                        {header.format(item[header.property])}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableToolBox>
        </div>
      </div>
      {header !== null && (
        <ColumnFilter
          header={header}
          items={items}
          onChange={handleFilterChange}
          onClose={handleHeaderFilterClose}
        />
      )}
    </div>
  );
};

Table.propTypes = {
  items: PropTypes.array.isRequired,
  processedItems: PropTypes.array.isRequired,
  headers: PropTypes.array.isRequired,
  selected: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  onOrderChange: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired
};

export default connect(() => {
  return {
    //
  };
})(Table);
