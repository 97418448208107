import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import styles from './Head.css';

class FilterPartHead extends Component {
  /**
   *
   * @param props
   */
  constructor(props) {
    super(props);
  }

  /**
   *
   */
  handleLoadPrevLevel = () => {
    this.props.loadPrevLevel();
  };

  /**
   *
   * @returns {XML}
   */
  render() {
    const { parents, kpiNames, singleLevel, language, contentIsOpened } = this.props;

    const filterPartTranslation = language.translation.scenario.filterPart;
    return (
      <div className={styles.head}>
        {!singleLevel && parents.length > 0 && (
          <button
            className={[styles.headIcon, styles.headIconClickable].join(' ')}
            onClick={() => this.handleLoadPrevLevel()}
          >
            <span className={['material-icons', styles.headIconWrap].join(' ')}>{parents.length ? 'folder' : ''}</span>
            <span className={['material-icons', styles.headIconWrap, styles.arrowBack].join(' ')}>
              {parents.length ? 'arrow_back' : ''}
            </span>
          </button>
        )}
        <div className={contentIsOpened ? styles.headName : styles.headNameOpen}>
          {parents.length ? parents[parents.length - 1].name : filterPartTranslation.name}
        </div>

        {kpiNames.map((name, index) => {
          const key = index;
          return (
            <div key={key} className={styles.headColumn}>
              <div className={styles.headColumnText}>{name}</div>
            </div>
          );
        })}
      </div>
    );
  }
}

FilterPartHead.propTypes = {
  parents: PropTypes.array, // eslint-disable-line
  kpiNames: PropTypes.array, // eslint-disable-line
  singleLevel: PropTypes.bool, // eslint-disable-line
  loadPrevLevel: PropTypes.func, // eslint-disable-line
  language: PropTypes.object.isRequired, // eslint-disable-line
  contentIsOpened: PropTypes.bool // eslint-disable-line
};

export default connect(store => {
  return {
    language: store.language
  };
})(FilterPartHead);
