import React, { Component } from 'react';
import PropTypes from 'prop-types';
import YouTube from 'react-youtube';
import VideoIcon from '@material-ui/icons/OndemandVideo';
import ClearIcon from '@material-ui/icons/Clear';
import FAQIcon from '@material-ui/icons/HelpOutline';
import configAxios from '../../config/axios';
import styles from './FAQ.css';

class FAQ extends Component {
  state = {
    data: {},
    toggleFAQ: false,
    toggleVideo: false,
    link: ''
  };

  componentDidMount() {
    this.getFAQ();
    window.document.addEventListener('click', this.handleDocumentClick);
  }

  openFAQ = () => {
    const { toggleFAQ } = this.state;
    this.setState({
      toggleFAQ: !toggleFAQ
    });
  };

  handleDocumentClick = e => {
    const { toggleFAQ } = this.state;

    if (this.$refs.root !== null && !this.$refs.root.contains(e.target) && toggleFAQ) {
      this.setState({ toggleFAQ: false });
    }
  };

  getFAQ = () => {
    const { path, location: { pathname = '' } = {} } = this.props;

    configAxios({
      method: 'GET',
      url: `/faq`,
      params: {
        group: pathname ? pathname.split('/')[1] : path
      }
    }).then(({ data = '' }) => {
      this.setState({ data });
    });
  };

  openVideo = link => {
    const { toggleVideo } = this.state;
    if (!toggleVideo) {
      this.setState({
        toggleVideo: !toggleVideo,
        link: link
      });
    } else {
      this.setState({
        toggleVideo: !toggleVideo,
        link: ''
      });
    }
  };

  render() {
    const { toggleFAQ, toggleVideo, data, link } = this.state;
    let opts;
    if (screen.width > 813) {
      opts = {
        height: '400px',
        width: '100%',
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 0
        }
      };
    } else {
      opts = {
        height: '300px',
        width: '100%',
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 0
        }
      };
    }

    this.$refs = {};
    const dataFAQ = Object.values(data);
    const isAbleToWatch = dataFAQ.length && Object.keys(dataFAQ[0]).length;

    return (
      <div className={styles.FAQwrap} ref={el => (this.$refs.root = el)}>
        {isAbleToWatch ? (
          <button type="button" onClick={this.openFAQ} className={styles.questionMark}>
            <FAQIcon className={styles.iconFAQ} />
          </button>
        ) : (
          ''
        )}
        {toggleFAQ && (
          <div className={styles.FAQpopup}>
            <div className={styles.popupWrap}>
              {dataFAQ.map((item, index) => {
                if (Object.keys(item).length) {
                  const links = link.replace(/<[^>]+>/g, '');
                  return (
                    <button
                      key={parseInt(index.toString())}
                      className={styles.FAQitems}
                      onClick={() => this.openVideo(item.content)}
                      type="button"
                    >
                      <VideoIcon className={styles.iconVideo} />
                      <button type="button" className={styles.contentText}>
                        {item.name}
                      </button>
                      {toggleVideo && (
                        <div>
                          <div className={styles.videoWrap}>
                            <YouTube videoId={links.toString()} opts={opts} />
                            <ClearIcon className={styles.iconClearVideo} onClick={this.openVideo} />
                          </div>
                          <div className={styles.container} />
                        </div>
                      )}
                    </button>
                  );
                }
              })}
              <ClearIcon className={styles.iconClear} onClick={this.openFAQ} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

FAQ.propTypes = {
  path: PropTypes.string.isRequired,
  location: PropTypes.object // eslint-disable-line
};

export default FAQ;
