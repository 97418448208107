export const updatePriceFormat = res => {
  let result = res;
  Object.keys(res).map(key => {
    if (
      key.toLowerCase() === 'цена' ||
      key.toLowerCase() === 'price' ||
      key.toLowerCase() === 'бағасы' ||
      key.toLowerCase() === 'баға'
    ) {
      if (!isNaN(+res[key])) {
        const dec = (res[key] % 1).toFixed(2).slice(1);
        result[key] = Math.floor(res[key]) + dec;
      }
    }
  });
  return result;
};