import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cls from 'classnames';
import Button from '@material-ui/core/Button';
import { Table as TableToolBox, TableHead, TableRow, TableCell } from 'react-toolbox/lib/table';
import { loadTask } from '../../store/actions/actions';
import Info from './Info/Info';
import CarouselPhoto from '../../components/tableComponents/CarouselPhoto/CarouselPhoto';
import Comment from '../../components/tableComponents/Comment/Comment';
import TaskDropdown from '../../components/tableComponents/TaskDropdown/TaskDropdown';
import configAxios from '../../config/axios';

import styles from './TablePlanogram.css';

const TablePlanogram = props => {
  const { shopId, items = [], headers = {}, manualCases, language, urlApi } = props;
  const lang = language.translation ? language.translation.directTask : {};

  function deleteCase(itemId, caseType) {
    const { dispatch, shopId } = props;
    const query = new URLSearchParams(location.search);
    const mid = query.has('mid') ? Number(query.get('mid')) : JSON.parse(localStorage.mission).id;

    const promise = configAxios({
      method: 'DELETE',
      url: `/task/delete`,
      params: {
        itemId,
        missionId: mid,
        shopId,
        caseType
      }
    }).then(() => {
      dispatch(loadTask(true));
    });
    return promise;
  }
  return (
    <div>
      <TableToolBox selectable={false}>
        <TableHead class={styles.tableHeadRow}>
          {Object.keys(headers).map(key => {
            return (
              <TableCell key={key} class={styles.tableCell}>
                {headers[key]}
              </TableCell>
            );
          })}
        </TableHead>
        {items.map((item, index) => {
          const keyCode = index;
          return (
            <TableRow
              key={keyCode}
              class={cls(styles.tableRow, {
                [styles.tableRowStatus]: item.tree_status_id !== 0,
                [styles.manualCasesStyle]: manualCases && item.type_id === 4,
                [styles.finalCasesStyle]: item.is_final
              })}
            >
              {Object.keys(headers).map(key => {
                if (key === 'tree') {
                  if (!item[key].length) {
                    return (
                      <TableCell key={key} class={styles.tableCell}>
                        {lang.treeEmpty}
                      </TableCell>
                    );
                  }
                  if (manualCases && item.type_id === 4) {
                    return (
                      <TableCell key={key} class={styles.tableCell}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => deleteCase(item.item_id, item.type_id)}
                        >
                          {lang.delete}
                        </Button>
                      </TableCell>
                    );
                  }
                  return (
                    <TableCell key={key} class={styles.tableCell}>
                      <TaskDropdown
                        urlApi={urlApi}
                        data={item[key]}
                        itemId={item.item_id}
                        caseType={item.type_id}
                        shopId={shopId}
                      />
                    </TableCell>
                  );
                } else if (key === 'image') {
                  return (
                    <TableCell key={key} class={cls(styles.tableCell, styles.textCenter)}>
                      <CarouselPhoto images={item[key] ? item[key] : []} />
                    </TableCell>
                  );
                } else if (key === 'info') {
                  return (
                    <TableCell key={key} class={cls(styles.tableCell, styles.textCenter)}>
                      <Info urlApi={urlApi} itemId={item.item_id} />
                    </TableCell>
                  );
                } else if (key === 'comment') {
                  return (
                    <TableCell key={key} class={cls(styles.tableCell, styles.textCenter)}>
                      <Comment
                        urlApi={urlApi}
                        shopId={shopId}
                        itemId={item.item_id}
                        text={item[key] ? item[key] : ''}
                        caseType={item.type_id}
                      />
                    </TableCell>
                  );
                } else {
                  return (
                    <TableCell key={key} class={styles.tableCell}>
                      {item[key]}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          );
        })}
      </TableToolBox>
    </div>
  );
};

TablePlanogram.propTypes = {
  urlApi: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  items: PropTypes.array,
  headers: PropTypes.object,
  shopId: PropTypes.number.isRequired,
  language: PropTypes.object.isRequired,
  manualCases: PropTypes.bool.isRequired
};

export default connect(store => {
  return {
    language: store.language
  };
})(TablePlanogram);
