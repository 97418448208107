export default class Preload {
  promises = [];
  listeners = [];

  /**
   *
   */
  constructor() {}

  /**
   *
   * @param promise
   */
  add(promise) {
    promise.then(() => {
      this.remove(promise);
    });

    promise.catch(() => {
      this.remove(promise);
    });

    const promises = [...this.promises];
    promises.push(promise);

    this.promises = promises;
    this.listeners.forEach(fn => fn(this.promises));
  }

  /**
   *
   * @param promise
   */
  remove(promise) {
    const index = this.promises.indexOf(promise);

    if (index !== -1) {
      const promises = [...this.promises];
      promises.splice(index, 1);

      this.promises = promises;
      this.listeners.forEach(fn => fn(this.promises));
    }
  }

  /**
   *
   * @param func
   * @returns {function()} remove subscribe
   */
  subscribe(func) {
    if (typeof func === 'function') {
      this.listeners.push(func);
    }

    return () => {
      this.unsubscribe(func);
    };
  }

  /**
   *
   * @param func
   */
  unsubscribe(func) {
    const index = this.listeners.indexOf(func);

    if (index !== -1) {
      this.listeners.splice(index, 1);
    }
  }
}
