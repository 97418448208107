import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import Popover from '@material-ui/core/Popover';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { Grid, Row, Col } from 'react-flexbox-grid';

import styles from './DateMonthComponent.css';
import { setDatePickerAction } from '../../store/actions/actions';

class FilterPart extends Component {
  state = {
    open: false,
    dateTrigger: '',
    minDate: this.props.datePickerDate.minDate
      ? moment(this.props.datePickerDate.minDate).format('YYYY-MM-DD')
      : moment(this.props.data.data.startDate).format('YYYY-MM-DD'),
    maxDate: this.props.datePickerDate.maxDate
      ? moment(this.props.datePickerDate.maxDate).format('YYYY-MM-DD')
      : moment(this.props.data.data.endDate).format('YYYY-MM-DD'),
    selectedYear: '',
    selectedMonth: {},
    show: 'week',
    changeDate: false,
    month: {},
    years: []
  };

  componentDidMount() {
    this.calcWeeks();
  }

  componentWillReceiveProps(nextProps) {
    const { datePickerDate } = nextProps;

    if (datePickerDate.maxDate) {
      this.setState({
        minDate: datePickerDate.minDate,
        maxDate: datePickerDate.maxDate
      });
    }
  }

  calcWeeks = () => {
    const {
      data: {
        data: { historicDate }
      }
    } = this.props;

    const calcHistoricDate = historicDate || '2016-01-01';

    let month = {};

    const historicMonth = moment(calcHistoricDate).get('month');
    const historicYear = moment(calcHistoricDate).year();
    const nowYear = moment().year();
    const nowMonth = moment().get('month');

    for (let year = historicYear; year <= nowYear; year++) {
      if (nowYear === historicYear) {
        month[year] = this.getWeek(historicMonth, nowMonth, year, calcHistoricDate, 1);
      } else if (year === historicYear) {
        month[year] = this.getWeek(historicMonth, 11, year, calcHistoricDate);
      } else if (year === nowYear) {
        month[year] = this.getWeek(0, nowMonth, year, 0, 1);
      } else {
        month[year] = this.getWeek(0, 11, year);
      }
    }

    const years = Object.keys(month);

    this.setState({
      selectedYear: years[years.length - 1],
      years,
      month
    });
  };

  getWeek = (start, end, year, historicDate, last) => {
    let data = [];
    let i = start;
    while (i <= end) {
      let startDate, endDate;

      if (historicDate && i === start) {
        startDate = historicDate;
        endDate = moment()
          .year(year)
          .month(i)
          .endOf('month')
          .format('YYYY-MM-DD');
      } else if (last && i === end) {
        startDate = moment()
          .year(year)
          .month(i)
          .startOf('month')
          .format('YYYY-MM-DD');
        endDate = moment().format('YYYY-MM-DD');
      } else {
        startDate = moment()
          .year(year)
          .month(i)
          .startOf('month')
          .format('YYYY-MM-DD');
        endDate = moment()
          .year(year)
          .month(i)
          .endOf('month')
          .format('YYYY-MM-DD');
      }

      data.push({
        name: i,
        startDate,
        endDate
      });

      i++;
    }
    return data;
  };

  setDate = update => {
    if (this.state.minDate && this.state.maxDate) {
      const scenarioFilters = this.props.filters;
      const startDate = this.state.minDate;
      const endDate = this.state.maxDate;
      let filters = {
        ...scenarioFilters
      };

      filters.date_start = startDate;
      filters.date_end = endDate;
      this.props.setDrillFilters(filters);

      if (update) {
        this.props.updateFilters(filters, 'date');
        this.setState({
          changeDate: false
        });
      }
    }
  };

  handleChangeDate = date => {
    const { dispatch } = this.props;
    const { dateTrigger, minDate, maxDate } = this.state;

    const { startDate, endDate } = date;

    dispatch(
      setDatePickerAction({
        minDate: dateTrigger === 'from' ? startDate : minDate,
        maxDate: dateTrigger === 'to' ? endDate : maxDate
      })
    );

    this.setState(
      {
        minDate: dateTrigger === 'from' ? startDate : minDate,
        maxDate: dateTrigger === 'to' ? endDate : maxDate,
        selectedWeek: date,
        changeDate: dateTrigger === 'from'
      },
      () => {
        this.setDate(dateTrigger === 'to');
        if (dateTrigger === 'from') {
          this.handleOpen(this.node, 'to');
        } else {
          this.handleRequestClose();
        }
      }
    );
  };

  selectYear = year => {
    this.handleShow('week');

    this.setState({
      selectedYear: year
    });
  };

  slideYear = key => {
    const { selectedYear, years } = this.state;

    const selectedYearsIndex = years.indexOf(selectedYear);

    if (key === 'prev' && selectedYearsIndex > 0) {
      this.selectYear(years[selectedYearsIndex - 1]);
    } else if (key === 'next' && selectedYearsIndex < years.length - 1) {
      this.selectYear(years[selectedYearsIndex + 1]);
    }
  };

  handleOpen = (event, dateTrigger) => {
    const { minDate, maxDate } = this.state;

    this.selectYear(dateTrigger === 'from' ? moment(minDate).format('YYYY') : moment(maxDate).format('YYYY'));

    this.setState({
      open: true,
      anchorEl: event.currentTarget ? event.currentTarget : event,
      dateTrigger
    });
  };

  handleRequestClose = () => {
    const { changeDate } = this.state;
    if (changeDate) {
      this.setDate(true);
    }

    this.setState({
      open: false,
      dateTrigger: ''
    });
  };

  disabledButton = item => {
    const { dateTrigger, minDate, maxDate } = this.state;

    if (dateTrigger === 'from' && item.startDate > maxDate) {
      return true;
    }
    if (dateTrigger === 'to' && item.endDate < minDate) {
      return true;
    }
  };

  handleShow = name => {
    this.setState({
      show: name
    });
  };

  handleActiveElement = item => {
    const { minDate, maxDate, dateTrigger, selectedYear } = this.state;

    if (
      (dateTrigger === 'from' && moment(minDate).format('M.YYYY') === `${item.name + 1}.${selectedYear}`) ||
      (dateTrigger === 'to' && moment(maxDate).format('M.YYYY') === `${item.name + 1}.${selectedYear}`)
    ) {
      return true;
    }
    return '';
  };

  handleNowElement = item => {
    const { selectedYear } = this.state;

    if (moment().format('M.YYYY') === `${item.name + 1}.${selectedYear}`) {
      return true;
    }
    return '';
  };

  /**
   *
   * @returns {XML}
   */
  render() {
    const {
      language: {
        translation: { datePicker }
      }
    } = this.props;

    const { open, anchorEl, minDate, maxDate, month, selectedYear, selectedMonth, years, show } = this.state;

    const selectedYearsIndex = years.indexOf(selectedYear);
    const widthScreen = window.screen.width < 813;
    const styleDatePicker = widthScreen ? styles.popoverWeekWrapMob : styles.popoverWeekWrap;
    const styleDatePickerLeft = widthScreen ? styles.popoverWeekLeftMob : styles.popoverWeekLeft;
    const styleDatePickerRight = widthScreen ? styles.popoverWeekRightMob : styles.popoverWeekRight;
    const widthStyle = widthScreen ? window.screen.width * 0.85 + 'px' : '';

    const yearList = years.map(year => {
      return (
        <button key={year} onClick={() => this.selectYear(year)} className={styles.weekItem}>
          {year}
        </button>
      );
    });

    const popoverWeek = (
      <div className={styleDatePicker} style={{ width: widthStyle }}>
        <div className={styleDatePickerLeft}>
          <p>{selectedYear}</p>
          <p>{datePicker.month[selectedMonth.name]}</p>
        </div>
        <div className={styleDatePickerRight}>
          <div className={styles.slideWrap}>
            <button
              className={`${styles.slideButton} ${selectedYearsIndex === 0 ? styles.slideButtonDisabled : ''}`}
              onClick={() => this.slideYear('prev')}
            >
              {'<'}
            </button>

            <button onClick={() => this.handleShow('year')}>{selectedYear}</button>

            <button
              className={`${styles.slideButton} ${
                selectedYearsIndex === years.length - 1 ? styles.slideButtonDisabled : ''
              }`}
              onClick={() => this.slideYear('next')}
            >
              {'>'}
            </button>
          </div>
          {show === 'year' ? yearList : ''}
          {show === 'week' ? (
            <div>
              {selectedYear &&
                month[selectedYear].map(item => {
                  return (
                    <button
                      key={item.startDate}
                      className={`
											${styles.weekItem}
											${this.handleActiveElement(item) ? styles.weekItemActive : ''}
											${this.handleActiveElement(item) ? styles.weekItemActive : ''}
											${this.handleNowElement(item) ? styles.weekItemNow : ''}
											${this.handleNowElement(item) ? styles.weekItemNow : ''}
											${this.disabledButton(item) ? styles.disabledButton : ''}
										`}
                      onClick={() => this.handleChangeDate(item)}
                      disabled={this.disabledButton(item)}
                    >
                      {datePicker.month[item.name]} {selectedYear}
                    </button>
                  );
                })}
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    );

    const popoverContent = (
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={this.handleRequestClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        {popoverWeek}
      </Popover>
    );

    return (
      <div>
        <div className={styles.container}>
          <Grid fluid className={styles.datePickerWrap}>
            <Row middle="md" className={styles.datePickerRow}>
              <Col md={3} className={styles.iconToWrap}>
                <DateRangeIcon style={{ color: '#fff', fontSize: 42 }} />
              </Col>
              <Col md={9} className={styles.fromToWrap}>
                <Row middle="md" className={styles.datePickerRow}>
                  <Col md={6}>{datePicker.from}</Col>
                  <Col md={6}>{datePicker.to}</Col>
                </Row>
                <Row middle="md" className={styles.datePickerRow}>
                  <Col md={6}>
                    <button onClick={e => this.handleOpen(e, 'from')} className={styles.dateButton}>
                      {moment(minDate).format('MM.YYYY')}
                    </button>
                    {popoverContent}
                  </Col>
                  <Col md={6}>
                    <button
                      onClick={e => this.handleOpen(e, 'to')}
                      className={styles.dateButton}
                      ref={node => (this.node = node)}
                    >
                      {moment(maxDate).format('MM.YYYY')}
                    </button>
                    {popoverContent}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Grid>
        </div>
      </div>
    );
  }
}

FilterPart.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired, // eslint-disable-line
  filters: PropTypes.object.isRequired, // eslint-disable-line
  updateFilters: PropTypes.func.isRequired, // eslint-disable-line
  language: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  datePickerDate: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  setDrillFilters: PropTypes.func.isRequired // eslint-disable-line react/forbid-prop-types
};

export default connect(store => {
  return {
    language: store.language,
    datePickerDate: store.datePickerDate
  };
})(FilterPart);
